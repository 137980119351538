import { Tabs } from "@mantine/core";
import React, { FC } from "react";
import { AccountMainInfoPage } from "/@/pages/settings/AccountMainInfoPage";
import { DentistProductPreferences } from "./DentistProductPreferences";

export const SettingsPage: FC = () => {
  //   const [shownSettingsComponent, setShownSettingsComponent] =
  //     useState<SettingsPageComponent>(SettingsPageComponent.MainInfo);

  return (
    <Tabs defaultValue="MainInfo">
      <Tabs.List>
        <Tabs.Tab
          style={{ height: 50 }}
          value="MainInfo"
        >
          Informations générales
        </Tabs.Tab>
        <Tabs.Tab value="ProductPreferences">Préférences prothèses</Tabs.Tab>
      </Tabs.List>
      <Tabs.Panel value="MainInfo">
        <AccountMainInfoPage />
      </Tabs.Panel>
      <Tabs.Panel value="ProductPreferences">
        <DentistProductPreferences />
      </Tabs.Panel>
    </Tabs>
  );
};
