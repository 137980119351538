import React, { FC } from "react";
import {
  GetOrderByUniqueAttributeQuery,
  GetRemovableItemChoicesQuery,
  GetUserProductPreferencesQuery,
} from "/@/generated/graphql";
import { CreateRemovableItemPartialDentureStep } from "../../../../shared/types/orders/createOrder.types";
import { CreateRemovableItemPartialDentureInfo } from "./CreateRemovableItemPartialDentureInfo";
import { Grid, Space, Stepper } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import { CreateRemovableItemPartialDentureTeethToManufacture } from "/@/pages/products/create/removable-item/CreateRemovableItemPartialDentureTeethToManufacture";
import { CreateRemovableItemPartialDentureClasps } from "/@/pages/products/create/removable-item/CreateRemovableItemPartialDentureClasps";
import { CreateRemovableItemPartialDentureWorkflow } from "/@/pages/products/create/removable-item/CreateRemovableItemPartialDentureWorkflow";
import { CreateProductSummarySideBar } from "/@/pages/products/create/CreateProductSummarySideBar";
import { useCreateOrderStore } from "/@/shared/stores/orders/create-store.store";

type CreateRemovableItemPartialDentureProps = {
  product: NonNullable<
    GetOrderByUniqueAttributeQuery["getOrderByUniqueAttribute"]["products"]
  >[0];
  patient: GetOrderByUniqueAttributeQuery["getOrderByUniqueAttribute"]["patient"];
  userProductPreferences: GetUserProductPreferencesQuery;
  removableItemChoicesData: GetRemovableItemChoicesQuery;
  order: GetOrderByUniqueAttributeQuery["getOrderByUniqueAttribute"];
  setIsLoading: (isLoading: boolean) => void;
};

export const CreateRemovableItemPartialDenture: FC<
  CreateRemovableItemPartialDentureProps
> = ({ patient, product, removableItemChoicesData, order, setIsLoading }) => {
  const createProductStep = useCreateOrderStore(
    state => state.createProductStep
  );
  const { classes } = useStyles();
  const dataContent = () => {
    switch (createProductStep) {
      case CreateRemovableItemPartialDentureStep.TeethToManufacture:
        return (
          <CreateRemovableItemPartialDentureTeethToManufacture
            product={product}
            setIsLoading={setIsLoading}
          />
        );
      case CreateRemovableItemPartialDentureStep.Item:
        return (
          <CreateRemovableItemPartialDentureInfo
            product={product}
            removableItemChoicesData={removableItemChoicesData}
            order={order}
            setIsLoading={setIsLoading}
          />
        );
      case CreateRemovableItemPartialDentureStep.Clasps:
        return (
          <CreateRemovableItemPartialDentureClasps
            product={product}
            setIsLoading={setIsLoading}
          />
        );
      case CreateRemovableItemPartialDentureStep.Workflow:
        return (
          <CreateRemovableItemPartialDentureWorkflow
            product={product}
            setIsLoading={setIsLoading}
          />
        );
      default:
        return <div>ERROR create removable partial denture</div>;
    }
  };

  return (
    <Grid
      columns={36}
      gutter={0}
      style={{ height: "100%" }}
    >
      <Grid.Col
        span={
          createProductStep < CreateRemovableItemPartialDentureStep.Review
            ? 26
            : 36
        }
        style={theme => ({
          padding: theme.spacing.lg,
        })}
      >
        <Stepper
          active={createProductStep}
          classNames={{
            step: classes.stepper,
            stepBody: classes.stepBody,
          }}
        >
          <Stepper.Step label="Informations" />
          <Stepper.Step label="Prothèse" />
          <Stepper.Step label="Crochets" />
          <Stepper.Step label="Flux de travail" />
          <Stepper.Step label="Récap" />
        </Stepper>
        <Space h="xl" />
        {dataContent()}
      </Grid.Col>
      {createProductStep !== CreateRemovableItemPartialDentureStep.Review && (
        <Grid.Col
          span={10}
          style={theme => ({
            padding: theme.spacing.sm,
            backgroundColor: theme.colors.primaryLight[9],
          })}
        >
          <CreateProductSummarySideBar
            product={product}
            patient={patient}
          />
        </Grid.Col>
      )}
    </Grid>
  );
};

const useStyles = createStyles(theme => ({
  stepper: {
    flexDirection: "column",
  },
  stepBody: {
    marginLeft: 0,
    marginTop: theme.spacing.xs,
    alignItems: "center",
  },
}));
