export enum ProximalContactTightness {
  HEAVY = "HEAVY",
  MEDIUM = "MEDIUM",
  LIGHT = "LIGHT",
  NONE = "NONE",
}

export enum OcclusalContactTightness {
  HEAVY = "HEAVY",
  MEDIUM = "MEDIUM",
  LIGHT = "LIGHT",
  NONE = "NONE",
}

export enum NoOcclusalClearance {
  KEEP_MANUFACTURING = "KEEP_MANUFACTURING",
  ASK_DENTIST = "ASK_DENTIST",
}

export enum OcclusalStaining {
  DARK = "DARK",
  MEDIUM = "MEDIUM",
  LIGHT = "LIGHT",
  NONE = "NONE",
}

export enum InterproximalContactDesign {
  NORMAL_CONTACT_POINT = "NORMAL_CONTACT_POINT",
  WIDE_CONTACT_SURFACE = "WIDE_CONTACT_SURFACE",
}

export enum AnteriorPosteriorPonticDesign {
  MODIFIED_RIDGE_LAP = "MODIFIED_RIDGE_LAP",
  FULL_RIDGE_LAP = "FULL_RIDGE_LAP",
}

export const ProximalContactTightnessLabels = [
  {
    value: ProximalContactTightness.HEAVY,
    label: "Fort",
  },
  {
    value: ProximalContactTightness.MEDIUM,
    label: "Moyen - par défaut",
  },
  {
    value: ProximalContactTightness.LIGHT,
    label: "Léger",
  },
  {
    value: ProximalContactTightness.NONE,
    label: "Aucun",
  },
];

export const OcclusalContactTightnessLabels = [
  {
    value: OcclusalContactTightness.HEAVY,
    label: "Forte",
  },
  {
    value: OcclusalContactTightness.MEDIUM,
    label: "Moyenne - par défaut",
  },
  {
    value: OcclusalContactTightness.LIGHT,
    label: "Légère",
  },
];

export const NoOcclusalClearanceLabels = [
  {
    value: NoOcclusalClearance.ASK_DENTIST,
    label: "Consulter le praticien",
  },
  {
    value: NoOcclusalClearance.KEEP_MANUFACTURING,
    label: "Poursuivre la fabrication",
  },
];

export const OcclusalStainingLabels = [
  {
    value: OcclusalStaining.DARK,
    label: "Foncé",
  },
  {
    value: OcclusalStaining.MEDIUM,
    label: "Moyen",
  },
  {
    value: OcclusalStaining.LIGHT,
    label: "Clair",
  },
  {
    value: OcclusalStaining.NONE,
    label: "Aucun",
  },
];

export const InterproximalContactDesignLabels = [
  {
    value: InterproximalContactDesign.NORMAL_CONTACT_POINT,
    label: "Point de contact",
  },
  {
    value: InterproximalContactDesign.WIDE_CONTACT_SURFACE,
    label: "Surface de contact",
  },
];

export const AnteriorPosteriorPonticDesignLabels = [
  {
    value: AnteriorPosteriorPonticDesign.MODIFIED_RIDGE_LAP,
    label: "Pontique crestal modifié",
  },
  {
    value: AnteriorPosteriorPonticDesign.FULL_RIDGE_LAP,
    label: "Complet",
  },
];

export enum ItemShadeTypeEnum {
  VITA_3D_MASTER = "VITA_3D_MASTER",
  VITA_CLASSIC = "VITA_CLASSIC",
  SR_VIVODENT_SPE = "SR_VIVODENT_SPE",
}

export const ItemShadeTypeLabel = [
  {
    value: ItemShadeTypeEnum.VITA_CLASSIC,
    label: "Vita Classic",
  },
  {
    value: ItemShadeTypeEnum.VITA_3D_MASTER,
    label: "Vita 3D Master",
  },
  {
    value: ItemShadeTypeEnum.SR_VIVODENT_SPE,
    label: "SR Vivodent SPE",
  },
];
