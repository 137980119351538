import { useEffect, useState } from "react";
import {
  GetAnatomyItemChoicesDocument,
  GetImplantItemChoicesDocument,
  GetOrderByUniqueAttributeQuery,
  GetRemovableItemChoicesDocument,
  ItemType,
} from "/@/generated/graphql";
import { getUserProductPreferences } from "/@/shared/graphql/product-preferences/product-preferences.graphql";
import { useLazyQuery, useQuery } from "@apollo/client";

import {
  isAnatomyItemType,
  isImplantItemType,
  isRemovableItemType,
} from "@jasper/shared";

export const useCreateProduct = (
  product: NonNullable<
    GetOrderByUniqueAttributeQuery["getOrderByUniqueAttribute"]["products"]
  >[0],
  dentist: NonNullable<GetOrderByUniqueAttributeQuery>["getOrderByUniqueAttribute"]["user"]
) => {
  const [createProductStep, setCreateProductStep] = useState(0);
  const {
    data: userProductPreferences,
    loading: isUserProductPreferencesLoading,
  } = useQuery(getUserProductPreferences, {
    variables: {
      where: {
        dentistId: {
          equals: dentist.id,
        },
      },
    },
  });
  const [
    getAnatomyItemChoices,
    { data: anatomyItemChoicesData, loading: anatomyItemChoicesLoading },
  ] = useLazyQuery(GetAnatomyItemChoicesDocument, {
    fetchPolicy: "network-only",
  });
  const [
    getImplantItemChoices,
    { data: implantItemChoicesData, loading: implantItemChoicesLoading },
  ] = useLazyQuery(GetImplantItemChoicesDocument, {
    fetchPolicy: "network-only",
  });
  const [
    getRemovableItemChoices,
    { data: removableItemChoicesData, loading: removableItemChoicesLoading },
  ] = useLazyQuery(GetRemovableItemChoicesDocument, {
    fetchPolicy: "network-only",
  });
  //console.log(userProductPreferences);

  useEffect(() => {
    if (product) {
      if (isAnatomyItemType(product.productType)) {
        getAnatomyItemChoices({
          variables: {
            where: {
              OR: [
                {
                  crown: {
                    equals:
                      product.productType === ItemType.Crown ||
                      product.productType === ItemType.InlayCore ||
                      product.productType === ItemType.PostAndCoreRetainedCrown
                        ? true
                        : undefined,
                  },
                },
                {
                  inlayCore: {
                    equals:
                      product.productType === ItemType.InlayCore ||
                      product.productType === ItemType.PostAndCoreRetainedCrown
                        ? true
                        : undefined,
                  },
                },
                {
                  lay: {
                    equals:
                      product.productType === ItemType.Inlay ||
                      product.productType === ItemType.Onlay ||
                      product.productType === ItemType.Lay
                        ? true
                        : undefined,
                  },
                },
                {
                  veneer: {
                    equals:
                      product.productType === ItemType.Veneer
                        ? true
                        : undefined,
                  },
                },
                {
                  bridge: {
                    equals:
                      product.productType === ItemType.Bridge
                        ? true
                        : undefined,
                  },
                },
              ],
            },
            where2: {
              OR: [
                {
                  crown: {
                    equals:
                      product.productType === ItemType.Crown ||
                      product.productType === ItemType.InlayCore ||
                      product.productType === ItemType.PostAndCoreRetainedCrown
                        ? true
                        : undefined,
                  },
                },
                {
                  lay: {
                    equals:
                      product.productType === ItemType.Inlay ||
                      product.productType === ItemType.Onlay ||
                      product.productType === ItemType.Lay
                        ? true
                        : undefined,
                  },
                },
                {
                  veneer: {
                    equals:
                      product.productType === ItemType.Veneer
                        ? true
                        : undefined,
                  },
                },
                {
                  bridge: {
                    equals:
                      product.productType === ItemType.Bridge
                        ? true
                        : undefined,
                  },
                },
              ],
            },
          },
        });
      } else if (isImplantItemType(product.productType)) {
        getImplantItemChoices({
          variables: {
            where: {
              OR: [
                {
                  implantCrown: {
                    equals: true,
                  },
                },
                {
                  abutment: {
                    equals: true,
                  },
                },
              ],
            },
            where2: {
              crown: {
                equals: true,
              },
            },
          },
        });
      } else if (isRemovableItemType(product.productType)) {
        getRemovableItemChoices({
          variables: {
            where: {
              OR: [
                {
                  partialDenture: {
                    equals:
                      product.productType === ItemType.PartialDenture
                        ? true
                        : undefined,
                  },
                },
                {
                  fullDenture: {
                    equals:
                      product.productType === ItemType.FullDenture
                        ? true
                        : undefined,
                  },
                },
              ],
            },
            where2: {
              OR: [
                {
                  fullDentureTeeth: {
                    equals:
                      product.productType === ItemType.FullDenture
                        ? true
                        : undefined,
                  },
                },
                {
                  partialDentureTeeth: {
                    equals:
                      product.productType === ItemType.PartialDenture
                        ? true
                        : undefined,
                  },
                },
                {
                  gingiva: {
                    equals: true,
                  },
                },
              ],
            },
          },
        });
      }
    }
  }, [product]);

  // useEffect(() => {
  //   console.log("removableItemChoicesData", removableItemChoicesData);
  // }, [getRemovableItemChoices]);

  return {
    createProductStep,
    setCreateProductStep,
    userProductPreferences,
    isUserProductPreferencesLoading,
    anatomyItemChoicesData,
    implantItemChoicesData,
    removableItemChoicesData,
    anatomyItemChoicesLoading,
    implantItemChoicesLoading,
    removableItemChoicesLoading,
  };
};
