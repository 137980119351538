import React, { FC, useEffect, useRef, useState } from "react";
import { useFiles, FileList } from "@jasper/shared";
import {
  Box,
  Container,
  Flex,
  Space,
  Text,
  Title,
  useMantineTheme,
} from "@mantine/core";
import {
  FileType,
  GetManyOrdersOfUserQuery,
  GetOrderByUniqueAttributeQuery,
} from "/@/generated/graphql";
import { IconCloudUpload, IconX } from "@tabler/icons-react";
import { Dropzone } from "@mantine/dropzone";
import QRCode from "qrcode";
import { useAuthContext } from "/@/shared/contexts/AuthContext";
import { io } from "socket.io-client";
import { getApiUrl } from "/@/services/api/api";
import { refreshNotifications } from "/@/shared/graphql/notifications/notifications.graphql";
import { useQuery } from "@apollo/client";

type OrderFilesManagementProps = {
  order:
    | GetOrderByUniqueAttributeQuery["getOrderByUniqueAttribute"]
    | GetManyOrdersOfUserQuery["getManyOrdersOfUser"][0];
  closeModal?: () => void;
};

export const OrderFilesManagement: FC<OrderFilesManagementProps> = ({
  order,
  closeModal = () => null,
}) => {
  const [enabled, setEnabled] = useState(false);
  const [hasNewFiles, setHasNewFiles] = useState(false);
  const { user } = useAuthContext();
  const canvas = useRef(null);
  const {
    getPresignedDownloadUrl,
    uploadFileToS3,
    filesOfOrder,
    refetchFilesOfOrder,
    deleteOneFile,
  } = useFiles(order.id);

  const theme = useMantineTheme();

  const fileFilter = files => {
    return files.filter(file => {
      if (
        file.fileType !== FileType.BillingDentistTraceability &&
        file.fileType !== FileType.BillingPatientTraceability &&
        file.fileType !== FileType.ClientFile &&
        file.fileType !== FileType.OrderAddonFile
      ) {
        return false;
      }
      return true;
    });
  };

  useEffect(() => {
    if (canvas.current) {
      const qrLink = `${window.location.origin}/#/order-upload-file/${order.id}/${order.patient?.lastName}/${user?.accessToken}/${FileType.OrderAddonFile}`;
      console.log(qrLink);
      QRCode.toCanvas(
        canvas.current,
        qrLink.replace(/ /g, "%20"),
        function (error) {
          if (error) console.error("QR Code generation error:", error);
        }
      );
    }
  }, [canvas]);

  useQuery(refreshNotifications, {
    skip: !enabled || !hasNewFiles,
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    const socket = io(getApiUrl(), {
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      randomizationFactor: 0.5,
      query: {
        type: "orderFile",
        order_id: order.id,
      },
    });
    socket.connect();
    socket.on("clientOrderFile", () => {
      setHasNewFiles(true);
      refetchFilesOfOrder();
    });

    socket.on("connect_error", error => {
      console.log(
        `Connection failed OrderFilesManagement.tsx (webapp) #${error.message}`
      );
    });

    socket.io.on("reconnect_attempt", attempt => {
      console.log(
        `Reconnection attempt in OrderFilesManagement.tsx (webapp) #${attempt}`
      );
    });

    socket.io.on("reconnect", attempt => {
      console.log(
        `Successfully reconnected in OrderFilesManagement.tsx (webapp) after ${attempt} attempt(s)`
      );
    });

    socket.on("disconnect", reason => {
      console.info(
        "disconnect in OrderFilesManagement.tsx (webapp)",
        reason,
        user?.id
      );
      socket.connect();
    });

    return () => {
      socket.disconnect();
      socket.off("connect");
      socket.off("disconnect");
      socket.off("clientOrderFile");
    };
  }, []);

  return (
    <Box
      style={() => ({
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      })}
    >
      <Flex
        w="100%"
        justify="right"
      >
        <IconX
          onClick={() => {
            setEnabled(true);
            closeModal();
          }}
          style={{ cursor: "pointer" }}
          color={theme.colors.red[5]}
        />
      </Flex>
      <Title
        order={2}
        style={theme => ({
          color: theme.colors.primary[5],
        })}
      >
        Fichiers de la commande {order.orderReference}
      </Title>
      <Space h="sm" />
      <p
        style={{
          textAlign: "center",
          margin: "0",
        }}
      >
        Ajoutez des photos
        <br />
        via ce QR Code
      </p>
      <canvas
        ref={canvas}
        style={{
          margin: "auto",
          maxWidth: "200px",
          maxHeight: "200px",
        }}
      />
      <Flex
        direction="row"
        justify="center"
        align="center"
        w="30%"
        // style={theme => ({backgroundColor: theme.colors.primaryLight[8]})}
      >
        <Dropzone
          onDrop={files => uploadFileToS3(files)}
          onReject={files => console.log("rejected files", files)}
          maxSize={3 * 1024 ** 2}
          style={() => ({
            height: "fit-content",
            width: "fit-content",
            border: 0,
          })}
        >
          <Flex
            justify="center"
            align="center"
          >
            <IconCloudUpload />
            <Box ml="sm">
              <Text
                size="sm"
                style={{
                  fontWeight: "bold",
                }}
              >
                {" "}
                {"Ajoutez des fichiers à votre commande"}
              </Text>
            </Box>
          </Flex>
        </Dropzone>
      </Flex>
      <Space h="md" />
      <Container
        p="md"
        style={theme => ({
          backgroundColor: theme.colors.primaryLight[9],
          minHeight: "30%",
          minWidth: "50%",
          maxWidth: "fit-content",
          borderRadius: theme.radius.md,
        })}
      >
        {!filesOfOrder?.getFilesOfOrder ||
        fileFilter(filesOfOrder.getFilesOfOrder).length === 0 ? (
          <Title
            order={3}
            style={{
              fontWeight: "normal",
              textAlign: "center",
            }}
          >
            Aucun fichier pour cette commande
          </Title>
        ) : (
          <FileList
            files={fileFilter(filesOfOrder.getFilesOfOrder)}
            deleteOneFile={deleteOneFile}
            downloadOneFile={getPresignedDownloadUrl}
            deleteFilter={[FileType.OrderAddonFile]}
          />
        )}
      </Container>
      <Space h="md" />
    </Box>
  );
};
