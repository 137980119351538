import { useEffect, useState } from "react";
import {
  GetOrderByUniqueAttributeDocument,
  GetOrderByUniqueAttributeQuery,
} from "/@/generated/graphql";
import { useAuthContext } from "/@/shared/contexts/AuthContext";
import { useLazyQuery } from "@apollo/client";

type UseCreateOrderProps = {
  newOrderId?: string | undefined;
};

export const useCreateOrder = ({
  newOrderId,
}: Partial<UseCreateOrderProps> = {}) => {
  const { user } = useAuthContext();

  const [
    getExistingDraftOrder,
    {
      data: existingDraftOrderData,
      loading: existingDraftOrderLoading,
      refetch: refetchOrder,
    },
  ] = useLazyQuery(GetOrderByUniqueAttributeDocument, {
    fetchPolicy: "network-only",
  });

  const [order, setOrder] = useState<
    GetOrderByUniqueAttributeQuery["getOrderByUniqueAttribute"] | null
  >(null);

  useEffect(() => {
    getExistingDraftOrder({
      variables: {
        where: {
          id: newOrderId,
        },
      },
    });
  }, [newOrderId]);

  useEffect(() => {
    if (newOrderId && existingDraftOrderData) {
      const orderDup = JSON.parse(
        JSON.stringify(existingDraftOrderData.getOrderByUniqueAttribute)
      );
      setOrder(orderDup);
    }
  }, [existingDraftOrderData, existingDraftOrderLoading]);

  return {
    user,
    existingDraftOrderLoading,
    order,
    refetchOrder,
  };
};
