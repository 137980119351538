import React, { FC, useState } from "react";
import {
  Text,
  Box,
  Title,
  Select,
  TextInput,
  Space,
  LoadingOverlay,
  Loader,
} from "@mantine/core";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  CreatePatientDocument,
  GetManagedUserGroupOfUserDocument,
  GetOrderByUniqueAttributeQuery,
  Sex,
  UpdateOrderDocument,
  UpdatePatientDocument,
  UserRole,
  GetManyUsersDocument,
  UserType,
} from "/@/generated/graphql";
import { DateInput } from "@mantine/dates";
import { StepsButtons } from "/@/components/StepsButtons";
import { patientSexChoices } from "/@/shared/types/patients/patients.types";
import "dayjs/locale/fr";
import customParseFormat from "dayjs/plugin/customParseFormat";
import dayjs from "dayjs";
import { useMutation, useQuery } from "@apollo/client";
import client from "/@/apolloClient";
import { useCreateOrderStore } from "/@/shared/stores/orders/create-store.store";
import { getProductsOrderedCountLabel } from "/@/shared/utils/products/products.utils";
import { useAuthContext } from "../../../shared/contexts/AuthContext";
dayjs.extend(customParseFormat);

type OrderCreationPatientData = {
  firstName?: string | null;
  lastName?: string | null;
  birthdate?: Date | null;
  sex?: Sex | null;
  dentist?: string;
};

type CreateOrderPatientInfoProps = {
  order: GetOrderByUniqueAttributeQuery["getOrderByUniqueAttribute"];
};
export const CreateOrderPatientInfo: FC<CreateOrderPatientInfoProps> = ({
  order,
}) => {
  const { user } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const birthdateObject = order.patient
    ? new Date(order?.patient.birthdate as string)
    : null;
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<OrderCreationPatientData>({
    defaultValues: {
      firstName: order?.patient ? order.patient.firstName : null,
      lastName: order?.patient ? order.patient.lastName : null,
      birthdate: birthdateObject ?? null,
      sex: order?.patient ? order.patient.sex : null,
      dentist: undefined,
    },
  });

  const { data: getManagedUserGroup, loading: getManagedUserGroupLoading } =
    useQuery(GetManagedUserGroupOfUserDocument, {
      fetchPolicy: "network-only",
    });
  const { data: getAllDentist, loading: getAllDentistLoading } = useQuery(
    GetManyUsersDocument,
    {
      variables: {
        where: {
          role: {
            in: [UserRole.User, UserRole.Admin, UserRole.Staff],
          },
          type: {
            in: [UserType.Dentist, UserType.Jasper],
          },
        },
      },
      fetchPolicy: "network-only",
    }
  );
  const [updatePatient] = useMutation(UpdatePatientDocument);

  const [createPatient] = useMutation(CreatePatientDocument);
  const [updateOrder] = useMutation(UpdateOrderDocument, {
    refetchQueries: ["getOrderByUniqueAttribute"],
    awaitRefetchQueries: true,
  });
  const [createOrderStep, updateCreateOrderStep] = useCreateOrderStore(
    state => [state.createOrderStep, state.updateCreateOrderStep]
  );

  const onSubmit: SubmitHandler<OrderCreationPatientData> = async data => {
    setIsLoading(true);
    if (!order.patient && data.firstName && data.lastName) {
      await createPatient({
        variables: {
          args: {
            firstName: data.firstName,
            lastName: data.lastName,
            birthdate: data.birthdate,
            sex: data.sex ?? undefined,
            dentist: {
              connect: {
                id: order.user.id,
              },
            },
          },
        },
      }).then(res => {
        updateOrder({
          variables: {
            where: {
              id: order.id,
            },
            data: {
              patient: {
                connect: {
                  id: res.data?.createPatient.id,
                },
              },
            },
          },
        });
      });
    } else if (
      order.patient &&
      (data.firstName != order.patient.firstName ||
        data.lastName != order.patient.lastName ||
        data.birthdate != birthdateObject ||
        data.sex != order.patient.sex)
    ) {
      await updatePatient({
        variables: {
          where: {
            id: order.patient.id,
          },
          data: {
            firstName: {
              set: data.firstName ?? undefined,
            },
            lastName: {
              set: data.lastName ?? undefined,
            },
            birthdate: {
              set: data.birthdate ?? undefined,
            },
            sex: data.sex
              ? {
                  set: data.sex ?? undefined,
                }
              : undefined,
          },
        },
      });
    }
    if (data.dentist) {
      await updateOrder({
        variables: {
          where: {
            id: order.id,
          },
          data: {
            user: {
              connect: {
                id: data.dentist,
              },
            },
          },
        },
      });
    }
    if (order.user.id !== data.dentist) {
      await client.refetchQueries({
        include: ["getOrderByUniqueAttribute"],
      });
    }
    updateCreateOrderStep(createOrderStep + 1);
    // setCreateOrderStep(prevState => prevState + 1);
    setIsLoading(false);
  };

  return (
    <Box p="lg">
      <LoadingOverlay
        visible={
          getManagedUserGroupLoading || getAllDentistLoading || isLoading
        }
        zIndex={1000}
        overlayProps={{
          radius: "sm",
          blur: 2,
        }}
      >
        <Loader />
      </LoadingOverlay>
      <Title
        style={theme => ({
          color: theme.colors.primary[4],
        })}
        order={4}
      >
        {order.products && order.products.length > 1
          ? "Produits commandés"
          : "Produit commandé"}
      </Title>
      <Text size="sm">{`${getProductsOrderedCountLabel(order)}`}</Text>
      <Space h="md" />
      {((getManagedUserGroup &&
        getManagedUserGroup.getManagedUserGroupOfUser.groupMembers &&
        getManagedUserGroup.getManagedUserGroupOfUser.groupMembers.length >
          1) ||
        user?.role === UserRole.Admin) && (
        <Box>
          <Title
            style={theme => ({
              color: theme.colors.primary[5],
            })}
            order={4}
          >
            Cette commande est réalisée par :
          </Title>
          <Space h="xs" />
          <Controller
            name="dentist"
            rules={{ required: true }}
            control={control}
            render={({ field }) => (
              <Select
                data-testid="select-praticien"
                data={
                  user?.role === UserRole.Admin
                    ? (getAllDentist?.getManyUsers ?? [])
                        .filter(usr =>
                          (usr?.userGroup?.groupMembers ?? []).length > 1
                            ? !usr?.isManager
                            : true
                        )
                        .map(usr => ({
                          label: `Dr. ${usr.firstName} ${usr.lastName} - ${usr.userGroup?.clinicNumber}`,
                          value: usr.id,
                        }))
                    : ((
                        getManagedUserGroup?.getManagedUserGroupOfUser
                          ?.groupMembers ?? []
                      )
                        ?.filter(groupMember => !groupMember.isManager)
                        ?.map(groupMember => ({
                          label: `Dr. ${groupMember.firstName} ${groupMember.lastName}`,
                          value: groupMember.id,
                        })) ?? [])
                }
                value={field.value}
                onChange={value => field.onChange(value)}
                searchable
                clearable
                placeholder="Selectionnez un docteur"
              />
            )}
          />
          <Space h="xs" />
          {errors?.dentist?.type === "required" && (
            <Text
              size="sm"
              style={{ color: "red" }}
            >
              Veuillez choisir un praticien pour cette commande
            </Text>
          )}
          <Space h="md" />
        </Box>
      )}
      <Title order={3}>Patient :</Title>
      <Space h="sm" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          {...register("firstName", {
            required: true,
          })}
          label="Prénom"
          placeholder="Prénom"
          size="sm"
          data-testid="input-patient-firstname"
          // defaultValue={order?.patient.firstName}
        />
        {errors.firstName?.type === "required" && (
          <Text
            size="sm"
            style={{ color: "red" }}
          >
            Ce champ est requis
          </Text>
        )}
        <TextInput
          {...register("lastName", {
            required: true,
          })}
          label="Nom"
          placeholder="Nom"
          size="sm"
          data-testid="input-patient-lastname"
          // defaultValue={order?.patient.lastName}
        />
        {errors.lastName?.type === "required" && (
          <Text
            size="sm"
            style={{ color: "red" }}
          >
            Ce champ est requis
          </Text>
        )}
        <Controller
          name="birthdate"
          control={control}
          render={({ field }) => (
            <DateInput
              {...field}
              placeholder="Date de naissance du patient"
              valueFormat="DD/MM/YYYY"
              label="Date de naissance"
              locale="fr"
              data-testid="input-patient-birthdate"
            />
          )}
        />
        <Controller
          name="sex"
          rules={{ required: false }}
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              data={patientSexChoices}
              label="Sexe"
              placeholder="Sexe du patient"
            />
          )}
        />
        {errors.sex?.type === "required" && (
          <Text
            size="sm"
            style={{ color: "red" }}
          >
            Ce champ est requis
          </Text>
        )}
        <Space h="xl" />
        <StepsButtons
          handleSubmit={handleSubmit(onSubmit)}
          setPreviousAction={updateCreateOrderStep}
          isFirst={true}
        />
      </form>
    </Box>
  );
};
