import React from "react";
import { Select, Space, Text } from "@mantine/core";
import { Controller } from "react-hook-form";
import {
  RetentionTypeChoices,
  yesNoChoices,
} from "../../shared/types/products/products.types";
import { ButtonsSelect } from "../ButtonsSelect";
import { FixedProsthesisShadeType, MultiShadeForm } from "@jasper/shared";

const SelectorComponent = (props: {
  title: string;
  name: string;
  errorType: string;
  control: any;
  data: any;
  placeholder?: string;
  errorText?: string;
  required?: boolean;
}) => {
  return (
    <>
      <Text
        size="sm"
        style={{ fontWeight: "bold" }}
      >
        {props.title}
      </Text>
      <Controller
        name={props.name}
        rules={{
          required: props.required,
        }}
        control={props.control}
        render={({ field }) => (
          <Select
            {...field}
            data={props.data}
            placeholder={props.placeholder ?? ""}
          />
        )}
      />
      {props.errorType === "required" && (
        <Text
          size="sm"
          style={{ color: "red" }}
        >
          {props.errorText}
        </Text>
      )}
      <Space h="md" />
    </>
  );
};

export const BridgeComponent = (props: {
  materialFormChoices: any;
  shadeFormChoices: any;
  watchIsMultiShade: any;
  watchGingivalShade: any;
  watchBaseShade: any;
  watchIncisalShade: any;
  errors: any;
  control: any;
  isMultiShadeEnabled: any;
}) => {
  return (
    <>
      <Text
        size="sm"
        style={{ fontWeight: "bold" }}
      >
        {"Système de rétention"}
      </Text>
      <Controller
        name="retentionType"
        rules={{ required: true }}
        control={props.control}
        render={({ field }) => (
          <ButtonsSelect
            data={RetentionTypeChoices}
            value={field.value}
            onChange={value => field.onChange(value)}
          />
        )}
      />
      {props.errors?.retentionType?.type === "required" && (
        <Text
          size="sm"
          style={{ color: "red" }}
        >
          Veuillez choisir un système de rétention
        </Text>
      )}
      <Space h="md" />
      <SelectorComponent
        title="Matériau"
        name="material"
        placeholder="Choisissez un matériau"
        errorText="Veuillez choisir un matériau"
        required={true}
        data={props.materialFormChoices}
        errorType={props.errors?.material?.type}
        control={props.control}
      />
      {props.isMultiShadeEnabled && (
        <>
          <Text
            size="sm"
            style={{
              fontWeight: "bold",
            }}
          >
            {"Affiner l'esthétique avec un dégradé de teinte ?"}
          </Text>
          <Controller
            name="isMultiShade"
            rules={{
              validate: value => value !== undefined,
            }}
            control={props.control}
            render={({ field }) => (
              <ButtonsSelect
                data={yesNoChoices}
                value={field.value}
                onChange={value => field.onChange(value)}
              />
            )}
          />
          <Space h="md" />
        </>
      )}
      <Text
        size="sm"
        style={{ fontWeight: "bold" }}
      >
        {"Type de teintier"}
      </Text>
      <Controller
        name="shadeType"
        rules={{ required: true }}
        control={props.control}
        render={({ field }) => (
          <ButtonsSelect
            data={Object.keys(FixedProsthesisShadeType).map(shadeType => ({
              value: shadeType,
              label: shadeType.replaceAll("_", " "),
            }))}
            value={field.value}
            onChange={value => field.onChange(value)}
          />
        )}
      />
      {props.errors?.shadeType?.type === "required" && (
        <Text
          size="sm"
          style={{ color: "red" }}
        >
          Veuillez choisir un type de teintier
        </Text>
      )}
      <Space h="md" />
      {props.watchIsMultiShade ? (
        <MultiShadeForm
          control={props.control}
          shadeFormChoices={props.shadeFormChoices}
          errors={props.errors}
          watchIsMultiShade={props.watchIsMultiShade}
          watchGingivalShade={props.watchGingivalShade}
          watchBaseShade={props.watchBaseShade}
          watchIncisalShade={props.watchIncisalShade}
        />
      ) : (
        <SelectorComponent
          title="Teinte"
          name="shade"
          placeholder="Choisissez une teinte"
          errorText="Veuillez choisir une teinte"
          required={!props.watchIsMultiShade}
          data={props.shadeFormChoices}
          errorType={props.errors?.shade?.type}
          control={props.control}
        />
      )}
      <Space h="xl" />
    </>
  );
};
