import { Loader, LoadingOverlay } from "@mantine/core";
import React, { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CreateOrderAnatomyItems } from "../../products/create/anatomy-item/CreateOrderAnatomyItems";
import { CreateOrderImplantItems } from "./implant-item/CreateOrderImplantItems";
import { GetOrderByUniqueAttributeQuery, ItemType } from "/@/generated/graphql";
import { CreateRemovableItemSplint } from "/@/pages/products/create/removable-item/CreateRemovableItemSplint";
import { CreateRemovableItemPartialDenture } from "/@/pages/products/create/removable-item/CreateRemovableItemPartialDenture";
import { CreateRemovableItemFullDenture } from "/@/pages/products/create/removable-item/CreateRemovableItemFullDenture";
import { useCreateProduct } from "/@/pages/products/create/useCreateProduct";
import { CreateOrderAddProduct } from "/@/pages/orders/create/CreateOrderAddProduct";
import { Modal } from "@mantine/core";

import {
  isAnatomyItemType,
  isImplantItemType,
  isRemovableItemType,
  ScanOnlyWorkflow,
} from "@jasper/shared";

type CreateOrderPageProps = {
  dentist: NonNullable<GetOrderByUniqueAttributeQuery>["getOrderByUniqueAttribute"]["user"];
  product: NonNullable<
    GetOrderByUniqueAttributeQuery["getOrderByUniqueAttribute"]["products"]
  >[0];
  allProducts: NonNullable<
    GetOrderByUniqueAttributeQuery["getOrderByUniqueAttribute"]["products"]
  >;
  orderCreationProductOrder: number;
  patient: GetOrderByUniqueAttributeQuery["getOrderByUniqueAttribute"]["patient"];
  order: GetOrderByUniqueAttributeQuery["getOrderByUniqueAttribute"];
  setIsLoading: (isLoading: boolean) => void;
};

export const CreateProductPage: FC<CreateOrderPageProps> = ({
  dentist,
  product,
  patient,
  order,
  allProducts,
  refetchOrder,
  updateOrder,
  onSubmit,
  getDeliveryDate,
  user,
  saveOrderComment,
  updateDeliveryDate,
  setIsLoading,
}: CreateOrderPageProps) => {
  const navigate = useNavigate();
  const productType = product.productType;
  const {
    userProductPreferences,
    isUserProductPreferencesLoading,
    anatomyItemChoicesData,
    implantItemChoicesData,
    removableItemChoicesData,
    anatomyItemChoicesLoading,
    implantItemChoicesLoading,
    removableItemChoicesLoading,
  } = useCreateProduct(product, dentist);
  const [isShowAddProductModal, setIsShowAddProductModal] = useState(false);

  if (
    (isAnatomyItemType(productType) && anatomyItemChoicesLoading) ||
    (isImplantItemType(productType) && implantItemChoicesLoading) ||
    (isRemovableItemType(productType) && removableItemChoicesLoading) ||
    isUserProductPreferencesLoading
  ) {
    return (
      <LoadingOverlay
        visible={
          anatomyItemChoicesLoading ||
          implantItemChoicesLoading ||
          removableItemChoicesLoading
        }
        zIndex={1000}
        overlayProps={{
          radius: "sm",
          blur: 2,
        }}
      >
        <Loader />
      </LoadingOverlay>
    );
  }
  if (!userProductPreferences) {
    return <div>Erreur sur les préférences.</div>;
  }
  if (isAnatomyItemType(productType) && anatomyItemChoicesData) {
    return (
      <CreateOrderAnatomyItems
        product={product}
        allProducts={allProducts}
        patient={patient}
        userProductPreferences={userProductPreferences}
        anatomyItemChoicesData={anatomyItemChoicesData}
        order={order}
        setIsLoading={setIsLoading}
      />
    );
  } else if (isImplantItemType(productType) && implantItemChoicesData) {
    return (
      <CreateOrderImplantItems
        product={product}
        patient={patient}
        order={order}
        userProductPreferences={userProductPreferences}
        implantItemChoicesData={implantItemChoicesData}
        setIsLoading={setIsLoading}
      />
    );
  } else if (productType === ItemType.Splint && removableItemChoicesData) {
    return (
      <CreateRemovableItemSplint
        product={product}
        patient={patient}
        userProductPreferences={userProductPreferences}
        removableItemChoicesData={removableItemChoicesData}
        setIsLoading={setIsLoading}
      />
    );
  } else if (
    productType === ItemType.PartialDenture &&
    removableItemChoicesData
  ) {
    return (
      <CreateRemovableItemPartialDenture
        product={product}
        patient={patient}
        userProductPreferences={userProductPreferences}
        removableItemChoicesData={removableItemChoicesData}
        order={order}
        setIsLoading={setIsLoading}
      />
    );
  } else if (productType === ItemType.FullDenture && removableItemChoicesData) {
    return (
      <CreateRemovableItemFullDenture
        product={product}
        patient={patient}
        userProductPreferences={userProductPreferences}
        removableItemChoicesData={removableItemChoicesData}
        order={order}
        setIsLoading={setIsLoading}
      />
    );
  } else if (productType === ItemType.ScanOnly) {
    return (
      <>
        <Modal
          size="100%"
          opened={isShowAddProductModal}
          onClose={() => {
            setIsShowAddProductModal(false);
          }}
        >
          <CreateOrderAddProduct
            closeModal={() => setIsShowAddProductModal(false)}
          />
        </Modal>
        <ScanOnlyWorkflow
          order={order}
          refetchOrder={refetchOrder}
          updateOrder={updateOrder}
          navigate={url => navigate(url)}
          onSubmit={onSubmit}
          getDeliveryDate={getDeliveryDate}
          user={user}
          saveOrderComment={saveOrderComment}
          updateDeliveryDate={updateDeliveryDate}
          setIsShowAddProductModal={value => setIsShowAddProductModal(value)}
        />
      </>
    );
  } else {
    return (
      <div>ERREUR: Ce produit est inconnu. Merci de contacter le support</div>
    );
  }
};
