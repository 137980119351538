import React, { FC, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Container,
  Flex,
  Grid,
  Loader,
  LoadingOverlay,
  Modal,
  Space,
  Text,
  Textarea,
  Title,
} from "@mantine/core";
import {
  GetOrderByUniqueAttributeQuery,
  GetOrderEstimatedDeliveryDateDocument,
  NotifyPlacedOrderDocument,
  OrderStatus,
  UpdateOrderDocument,
} from "/@/generated/graphql";
import { useNavigate } from "react-router-dom";
import { StepsButtons } from "/@/components/StepsButtons";
import { useMutation, useQuery } from "@apollo/client";
import { IconCloudUpload } from "@tabler/icons-react";
import { ItemsDescription } from "/@/components/order/ItemsDescription";
import { useCreateOrderStore } from "/@/shared/stores/orders/create-store.store";
import { CreateOrderStep } from "/@/shared/types/orders/createOrder.types";
import { CreateOrderAddProduct } from "/@/pages/orders/create/CreateOrderAddProduct";
import * as QRCode from "qrcode";
import {
  UPDATE_DELIVERY_DATE,
  useFiles,
  CustomDateSelection,
  checkHasGenericImplant,
  FileType,
  STYLE,
} from "@jasper/shared";

import {
  getPatientSexLabel,
  CommentType,
  getLastShippingDateFromDeliveryDate,
} from "@jasper/shared";
import { useAuthContext } from "/@/shared/contexts/AuthContext";
import { io } from "socket.io-client";
import { getApiUrl } from "/@/services/api/api";

type CreateOrderReviewProps = {
  order: NonNullable<
    GetOrderByUniqueAttributeQuery["getOrderByUniqueAttribute"]
  >;
  refetchOrder: () => void;
};
import { File } from "@jasper/shared/types/interfaces";

import { notifications } from "@mantine/notifications";
import { FileList } from "@jasper/shared/stories/FileList/FileList";
import { createStyles } from "@mantine/emotion";
import { Dropzone } from "@mantine/dropzone";

const useStyles = createStyles(theme => ({
  button: {
    "&:hover": {
      backgroundColor: theme.colors.primaryLight[8],
    },
  },
}));
export const CreateOrderReview: FC<CreateOrderReviewProps> = ({
  order,
  refetchOrder,
}) => {
  const { user } = useAuthContext();
  const getOrderComment = () => {
    const existingOrderComment = (order?.orderComment ?? []).find(
      comment => (comment.type as unknown as CommentType) === CommentType.ORDER
    );
    return existingOrderComment?.comment ?? "";
  };

  const [orderComment, setOrderComment] = useState("");
  const [isModalCustomDeliveryDateOpened, setIsModalCustomDeliveryDateOpened] =
    useState(false);

  const canvas = useRef(null);
  const { classes } = useStyles();
  const [isPageLoading, setIsPageLoading] = useState(false);

  const {
    data: getOrderEstimatedDeliveryDate,
    loading: getOrderEstimatedDeliveryDateLoading,
    refetch,
  } = useQuery(GetOrderEstimatedDeliveryDateDocument, {
    variables: {
      orderId: order.id,
    },
    fetchPolicy: "network-only",
    pollInterval: 1000 * 60 * 60 * 6, // update every 6 hours
  });

  const [updateOrder] = useMutation(UpdateOrderDocument);
  const [notifyPlacedOrder] = useMutation(NotifyPlacedOrderDocument);
  const [updateDeliveryDate] = useMutation(UPDATE_DELIVERY_DATE);
  const [isShowConfirmModal, setIsShowConfirmModal] = useState(false);
  const [imageList, setImageList] = useState<File[]>([]);
  const [isShowAddProductModal, setIsShowAddProductModal] = useState(false);
  const [updateCreateOrderStep] = useCreateOrderStore(state => [
    state.updateCreateOrderStep,
  ]);
  const [estimatedDeliveryDateString, setEstimatedDeliveryDateString] =
    useState<string | null | undefined>(undefined);
  const [newDeliveryDateSelected, setNewDeliveryDateSelected] =
    useState<Date | null>(null);
  const {
    getPresignedDownloadUrl,
    deleteOneFile,
    uploadFileToS3,
    filesOfOrder,
    refetchFilesOfOrder,
    getFileUrl,
  } = useFiles(order.id);
  const navigate = useNavigate();
  if (canvas.current) {
    const qrLink = `${window.location.origin}/#/order-upload-file/${order.id}/${order.patient?.lastName}/${user?.accessToken}/${FileType.CLIENT_FILE}`;
    QRCode.toCanvas(canvas.current, qrLink.replace(" ", "%20"), error => {
      if (error) console.error(error);
    });
  }

  const refreshImageList = async (data: any) => {
    return await Promise.all(
      data.map(async (obj: any) => {
        const path = (await getFileUrl(obj.key)) ?? null;
        return {
          ...obj,
          path: path,
        };
      })
    );
  };

  useEffect(() => {
    const socket = io(getApiUrl(), {
      query: {
        type: "orderFile",
        order_id: order.id,
      },
    });
    socket.connect();
    socket.on("clientOrderFile", () => {
      refetchFilesOfOrder();
    });
    socket.on("disconnect", reason => {
      console.info("disconnect", reason, user?.id);
      socket.connect();
    });

    const handleVisibilityChange = () => {
      if (!document.hidden) {
        refetch();
      }
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      socket.disconnect();
      socket.off("connect");
      socket.off("disconnect");
      socket.off("clientOrderFile");
    };
  }, []);

  useEffect(() => {
    setOrderComment(getOrderComment());
  }, [order]);

  useEffect(() => {
    if (filesOfOrder?.getFilesOfOrder) {
      refreshImageList(filesOfOrder?.getFilesOfOrder).then(a =>
        setImageList(a)
      );
    }
  }, [filesOfOrder?.getFilesOfOrder]);

  useEffect(() => {
    if (getOrderEstimatedDeliveryDateLoading) {
      return;
    }
    if (
      getOrderEstimatedDeliveryDate?.getOrderEstimatedDeliveryDate?.deliveryDate
    ) {
      const estimatedDeliveryDate = new Date(
        getOrderEstimatedDeliveryDate.getOrderEstimatedDeliveryDate?.deliveryDate
      );
      if (newDeliveryDateSelected) {
        setEstimatedDeliveryDateString(
          (newDeliveryDateSelected as Date).toLocaleString("fr-FR", {
            year: "numeric",
            month: "long",
            day: "numeric",
            weekday: "long",
          })
        );
      } else {
        setEstimatedDeliveryDateString(
          estimatedDeliveryDate.toLocaleString("fr-FR", {
            year: "numeric",
            month: "long",
            day: "numeric",
            weekday: "long",
          })
        );
      }
    } else {
      setEstimatedDeliveryDateString(null);
    }
  }, [getOrderEstimatedDeliveryDate, getOrderEstimatedDeliveryDateLoading]);

  const getCommentBlock = () => {
    const existingOrderComment = (order?.orderComment ?? []).find(
      comment => (comment.type as unknown as CommentType) === CommentType.ORDER
    );
    let commentBlock = undefined;
    if (existingOrderComment) {
      commentBlock = {
        update: [
          {
            data: {
              comment: {
                set: orderComment,
              },
            },
            where: {
              id: existingOrderComment?.id,
            },
          },
        ],
      };
    } else if (orderComment) {
      commentBlock = {
        create: [
          {
            comment: orderComment,
          },
        ],
      };
    }
    return commentBlock;
  };

  const saveOrderComment = async () => {
    try {
      await updateOrder({
        variables: {
          where: {
            id: order.id,
          },
          data: {
            comment: {
              set: orderComment ?? null,
            },
            orderComment: getCommentBlock(),
          },
        },
      });
      refetchOrder();
    } catch (e) {
      console.error(e);
    }
  };

  const onSubmit = async () => {
    try {
      setIsPageLoading(true);
      const toSaveDeliveryDate = newDeliveryDateSelected
        ? new Date(newDeliveryDateSelected)
        : undefined;
      if (toSaveDeliveryDate) toSaveDeliveryDate.setHours(12);
      const res = await updateOrder({
        variables: {
          where: {
            id: order.id,
          },
          data: {
            confirmedAt: {
              set: Date.now(),
            },
            comment: {
              set: orderComment ?? null,
            },
            status: {
              set: OrderStatus.Confirmed,
            },
            orderComment: getCommentBlock(),
            deliveryDate: toSaveDeliveryDate
              ? {
                  set: toSaveDeliveryDate,
                }
              : getOrderEstimatedDeliveryDate?.getOrderEstimatedDeliveryDate
                    ?.deliveryDate
                ? {
                    set: getOrderEstimatedDeliveryDate
                      ?.getOrderEstimatedDeliveryDate?.deliveryDate,
                  }
                : undefined,
            lastShippingDate: toSaveDeliveryDate
              ? {
                  set: getLastShippingDateFromDeliveryDate(toSaveDeliveryDate),
                }
              : getOrderEstimatedDeliveryDate?.getOrderEstimatedDeliveryDate
                ? {
                    set: getLastShippingDateFromDeliveryDate(
                      getOrderEstimatedDeliveryDate
                        ?.getOrderEstimatedDeliveryDate?.deliveryDate
                    ),
                  }
                : undefined,
            orderBilling: {
              create: [
                {
                  user: {
                    connect: {
                      id: order.user.id,
                    },
                  },
                },
              ],
            },
          },
        },
      });
      if (res.data?.updateOrder?.deliveryDate) {
        const estimatedDeliveryDate = new Date(
          res.data.updateOrder.deliveryDate
        );
        setEstimatedDeliveryDateString(
          estimatedDeliveryDate.toLocaleString("fr-FR", {
            year: "numeric",
            month: "long",
            day: "numeric",
            weekday: "long",
          })
        );
      }
      setIsPageLoading(false);
      setIsShowConfirmModal(true);
      if (checkHasGenericImplant(order)) {
        await updateDeliveryDate({
          variables: {
            orderId: order.id,
            newDeliveryDate: toSaveDeliveryDate,
            shouldUpdateAbutmentType: true,
          },
        });
      }
      await notifyPlacedOrder({
        variables: {
          orderId: order.id,
        },
      });
      setIsPageLoading(false);
    } catch (e) {
      console.error(e);
      setIsPageLoading(false);
    }
  };

  const onSubmitUpdateDeliveryDate = async (newDeliveryDate: Date) => {
    setIsPageLoading(true);
    const toSaveDeliveryDate = new Date(newDeliveryDate);
    if (toSaveDeliveryDate) toSaveDeliveryDate.setHours(12);
    const req = await updateDeliveryDate({
      variables: {
        orderId: order.id,
        newDeliveryDate: toSaveDeliveryDate,
        shouldUpdateAbutmentType: false,
      },
    });
    setIsModalCustomDeliveryDateOpened(false);
    if (req?.data?.updateOrderDeliveryDate) {
      refetch();
      refetchOrder();
      notifications.show({
        color: "green",
        title: "Date de livraison modifiée",
        message: "",
      });
      setNewDeliveryDateSelected(newDeliveryDate);
      setIsPageLoading(false);
    } else {
      notifications.show({
        color: "red",
        title: "Erreur lors de la demande de modification",
        message: "",
      });
      setIsPageLoading(false);
    }
  };

  if (
    !order.patient ||
    !order.address ||
    !order.products ||
    order.products.length < 1
  ) {
    return <div>Erreur données</div>;
  }

  if (
    getOrderEstimatedDeliveryDateLoading &&
    estimatedDeliveryDateString === undefined
  ) {
    return <Box></Box>;
  }

  const patientBirthday = order.patient.birthdate
    ? new Date(order.patient.birthdate as string).toLocaleDateString("fr-FR")
    : null;

  return (
    <Box
      p="lg"
      style={() => ({
        display: "flex",
        flexDirection: "column",
        height: "100%",
      })}
    >
      <LoadingOverlay
        visible={isPageLoading}
        zIndex={1000}
        overlayProps={{
          radius: "sm",
          blur: 2,
        }}
      >
        <Loader />
      </LoadingOverlay>
      <Modal
        opened={isModalCustomDeliveryDateOpened}
        size="auto"
        onClose={() => setIsModalCustomDeliveryDateOpened(false)}
        title={
          checkHasGenericImplant(order) ? (
            <div style={{ width: "32rem" }}>
              <Text
                style={{
                  textAlign: "center",
                  color: STYLE.primary,
                  fontWeight: "bold",
                }}
              >
                En choisissant des délais de fabrication raccourcis,
              </Text>
              <Text
                style={{
                  marginBottom: "1rem",
                  textAlign: "center",
                  color: STYLE.primary,
                  fontWeight: "bold",
                }}
              >
                vous acceptez de remplacer le pilier original fabriquant par un
                pilier personnalisé.
              </Text>
            </div>
          ) : (
            ""
          )
        }
      >
        <CustomDateSelection
          selectDate={newDeliveryDate =>
            onSubmitUpdateDeliveryDate(newDeliveryDate)
          }
          optimalDeliveryDate={
            new Date(
              getOrderEstimatedDeliveryDate?.getOrderEstimatedDeliveryDate?.deliveryDate
            )
          }
          bankHolidays={
            getOrderEstimatedDeliveryDate?.getOrderEstimatedDeliveryDate
              ?.bankHolidays
          }
          minimalRushDeliveryDate={
            getOrderEstimatedDeliveryDate?.getOrderEstimatedDeliveryDate
              ?.minimalRushDeliveryDate
          }
          selectedDate={newDeliveryDateSelected}
        />
      </Modal>
      {!isShowAddProductModal && (
        <>
          <Title>Complétez et validez votre commande</Title>
          <Space h="md" />
          <Title
            style={theme => ({
              color: theme.colors.primary[5],
            })}
            order={3}
          >
            Date de livraison estimée :{" "}
            {(
              newDeliveryDateSelected ?? estimatedDeliveryDateString
            )?.toLocaleString("fr-FR", {
              year: "numeric",
              month: "long",
              day: "numeric",
              weekday: "long",
            }) ??
              "En cours de calcul. Elle vous sera communiquée prochainement"}
            {user?.customDeliveryDateEnabled && (
              <Button
                style={{
                  marginLeft: "2rem",
                }}
                onClick={async () => {
                  await saveOrderComment();
                  setIsModalCustomDeliveryDateOpened(true);
                }}
              >
                Changer la date de livraison
              </Button>
            )}
          </Title>
          <Space h="md" />
          <Grid gutter="md">
            <Grid.Col span={6}>
              <Container
                p="xl"
                style={theme => ({
                  borderRadius: "10px",
                  backgroundColor: theme.colors.primaryLight[9],
                  height: "100%",
                })}
              >
                <Grid gutter="md">
                  <Grid.Col span={6}>
                    <Box>
                      <Title order={4}>Praticien</Title>
                      <Text>
                        {order.user.firstName} {order.user.lastName}
                      </Text>
                      <Text>
                        {order.address.streetNumber}{" "}
                        {order.address.streetName}{" "}
                      </Text>
                      <Text>
                        {order.address.city} {order.address.postcode}
                      </Text>
                    </Box>
                    <Space h="xl" />
                    <Box>
                      <Title order={4}>Patient</Title>
                      <Text>
                        {order.patient.firstName} {order.patient.lastName}
                      </Text>
                      {patientBirthday && <Text>{patientBirthday}</Text>}
                      {!order.patient.sex && (
                        <Text>{getPatientSexLabel(order.patient.sex)}</Text>
                      )}
                    </Box>
                  </Grid.Col>
                  <Grid.Col
                    span={6}
                    style={{
                      margin: "auto 0",
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        width: "fit-content",
                        float: "right",
                        marginBottom: "10px",
                      }}
                    >
                      <p
                        style={{
                          textAlign: "center",
                          margin: "0",
                        }}
                      >
                        Ajoutez des photos
                        <br />
                        via ce QR Code
                      </p>
                      <canvas
                        style={{
                          maxWidth: "170px",
                          maxHeight: "170px",
                          margin: "auto",
                        }}
                        ref={canvas}
                      />
                      <p
                        style={{
                          textAlign: "center",
                          margin: "5px",
                        }}
                      >
                        OU
                      </p>

                      <Dropzone
                        accept={{
                          "image/*": [],
                          "application/dicom": [".DCM", ".dcm"],
                          "model/stl": [".stl", ".STL"],
                        }}
                        onDrop={async files => {
                          await uploadFileToS3([...files], FileType.ORDER_FILE);
                          refetch();
                          refetchFilesOfOrder();
                        }}
                        style={{
                          border: "2px dashed gray",
                          borderRadius: "5px",
                          padding: "5px",
                          cursor: "pointer",
                        }}
                      >
                        <Flex
                          justify="center"
                          align="center"
                        >
                          <IconCloudUpload />
                          <Box ml="sm">
                            <span
                              style={{
                                fontWeight: "bold",
                                textWrap: "wrap",
                                fontSize: "14px",
                              }}
                            >
                              {" "}
                              {"Ajouter un fichier à votre commande"}
                            </span>
                          </Box>
                        </Flex>
                      </Dropzone>
                    </div>
                  </Grid.Col>
                </Grid>
                <Box>
                  {imageList && imageList.length > 0 && (
                    <Box
                      mt={10}
                      mb={10}
                    >
                      <FileList
                        files={[
                          ...imageList.filter(
                            file =>
                              !file.key.includes(".3OXZ") &&
                              !file.key.includes(
                                "-traçabilité-praticien.pdf"
                              ) &&
                              !file.key.includes("-traçabilité-patient.pdf")
                          ),
                        ]}
                        deleteOneFile={deleteOneFile}
                        downloadOneFile={getPresignedDownloadUrl}
                      />
                    </Box>
                  )}
                  <Textarea
                    value={orderComment}
                    color="primary"
                    placeholder="Un commentaire sur la commande ? (livraison, délais, etc.)"
                    onChange={event =>
                      setOrderComment(event.currentTarget.value)
                    }
                  />
                </Box>
                <Box></Box>
              </Container>
            </Grid.Col>
            <Grid.Col span={6}>
              <Container
                p="xl"
                style={theme => ({
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  borderRadius: "10px",
                  backgroundColor: theme.colors.primaryLight[9],
                  height: "100%",
                })}
              >
                <Box
                  style={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    {order.products.map(product => (
                      <ItemsDescription
                        key={product.id}
                        product={product}
                        order={order}
                      />
                    ))}
                  </div>
                  {checkHasGenericImplant(order) && (
                    <div>
                      <Text
                        style={{
                          color: STYLE.primary,
                          fontWeight: "bold",
                        }}
                      >
                        En choisissant des délais de fabrication raccourcis,
                      </Text>
                      <Text
                        style={{
                          marginBottom: "1rem",
                          color: STYLE.primary,
                          fontWeight: "bold",
                        }}
                      >
                        vous acceptez de remplacer le pilier original fabriquant
                        par un pilier personnalisé.
                      </Text>
                    </div>
                  )}
                </Box>
                <Button
                  h={60}
                  variant="white"
                  className={classes.button}
                  onClick={async () => {
                    await saveOrderComment();
                    setIsShowAddProductModal(true);
                  }}
                >
                  {"Ajouter un produit (ex: gouttière, ...)"}
                </Button>
              </Container>
            </Grid.Col>
          </Grid>
          <Space h="xl" />
          <StepsButtons
            handleSubmit={() => onSubmit()}
            applyBeforePreviousStep={async () => await saveOrderComment()}
            setPreviousAction={updateCreateOrderStep}
            previousStep={CreateOrderStep.Product}
            nextLabel="Valider ma commande"
            isValidateStyle={true}
          />
          {isShowConfirmModal && (
            <Modal
              opened={isShowConfirmModal}
              onClose={() => {
                setIsShowConfirmModal(false);
                navigate("/");
              }}
              size="xl"
              withCloseButton={false}
              centered
              overlayProps={{
                opacity: 0.6,
                blur: 17,
              }}
            >
              <Container
                p="lg"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignContent: "center",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Title
                  order={2}
                  style={theme => ({
                    color: theme.colors.primary[3],
                  })}
                >
                  Commande passée avec succès !
                </Title>
                <Space h="lg" />
                <Title
                  order={2}
                  style={theme => ({
                    color: theme.colors.green[9],
                  })}
                >
                  {estimatedDeliveryDateString &&
                  estimatedDeliveryDateString !== ""
                    ? `Date de livraison estimée: ${
                        estimatedDeliveryDateString ??
                        "Communiquée prochainement sur la page de la commande"
                      }`
                    : ""}
                </Title>
                <Space h="md" />
                <Text
                  style={{
                    textAlign: "center",
                  }}
                >
                  {
                    "Vous pouvez suivre votre commande depuis votre dashboard, ou en cliquant ci-dessous"
                  }
                </Text>
                <Space h="xl" />
                <Button
                  onClick={() => navigate(`/order/${order.id}`)}
                  data-testid="button-show-my-order"
                >
                  Voir ma commande
                </Button>
              </Container>
            </Modal>
          )}
        </>
      )}
      {isShowAddProductModal && (
        <Modal
          size="100%"
          opened={isShowAddProductModal}
          onClose={() => {
            setIsShowAddProductModal(false);
          }}
        >
          <CreateOrderAddProduct
            closeModal={() => setIsShowAddProductModal(false)}
          />
        </Modal>
      )}
    </Box>
  );
};

type DropzoneChildrenProps = {
  // productTypes: ItemType[];
};
export const DropzoneChildren: FC<DropzoneChildrenProps> = () => {
  return (
    <Box
      style={() => ({
        display: "flex",
      })}
    >
      <IconCloudUpload />
      <Box ml="sm">
        <Text
          size="sm"
          style={{ fontWeight: "bold" }}
        >
          {" "}
          {"Ajoutez ou glissez des fichiers à votre commande"}
        </Text>
      </Box>
    </Box>
  );
};
