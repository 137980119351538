import { ReactElement } from "react";

type CreateOrderFileInterceptorsProps = {
  children: ReactElement;
};

export const CreateOrderFileInterceptors = ({
  children,
}: CreateOrderFileInterceptorsProps) => {
  return children;
};
