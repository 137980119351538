import { Accordion, Space, Text, Title } from "@mantine/core";
import React, { FC, useState } from "react";
import { StepsButtons } from "/@/components/StepsButtons";
import {
  GetAnatomyItemChoicesQuery,
  GetOrderByUniqueAttributeDocument,
  ItemType,
  GetOrderByUniqueAttributeQuery,
  TeethShadeType,
} from "/@/generated/graphql";
import { ProductFromGetOrderByUniqueAttributeQuery } from "../../../../shared/types/products/products.types";
import { CreateAnatomyItemStep } from "/@/shared/types/orders/createOrder.types";
import client from "/@/apolloClient";
import { CreateAnatomyItemInfoForm } from "/@/pages/products/create/anatomy-item/CreateAnatomyItemInfoForm";
import { useCreateOrderStore } from "/@/shared/stores/orders/create-store.store";

import { getItemTypeLabel } from "@jasper/shared";

type CreateOrderAnatomyItemInfoProps = {
  product: NonNullable<ProductFromGetOrderByUniqueAttributeQuery>;
  allProducts: NonNullable<
    GetOrderByUniqueAttributeQuery["getOrderByUniqueAttribute"]["products"]
  >;
  anatomyItemChoicesData: GetAnatomyItemChoicesQuery;
  providedAnatomyItem?: NonNullable<
    ProductFromGetOrderByUniqueAttributeQuery["anatomyItem"]
  >[0];
  setItemStatusTable?: React.Dispatch<React.SetStateAction<string[]>>;
  order: GetOrderByUniqueAttributeQuery["getOrderByUniqueAttribute"];
  setIsLoading: (isLoading: boolean) => void;
};

const computeItemStatusTableValue = (
  anatomyItems: NonNullable<
    ProductFromGetOrderByUniqueAttributeQuery["anatomyItem"]
  >
): Array<string> => {
  const itemStatusTable = [];
  for (let i = 0; i < anatomyItems.length; i++) {
    const item = anatomyItems[i];
    if (item.itemType === ItemType.InlayCore) {
      if (
        item?.itemMaterial?.id !== "METAL_NON_PRECIOUS" &&
        !item?.inlayCoreMaterial?.id
      ) {
        itemStatusTable.push(item.id);
      }
    } else {
      if (
        item.itemType !== ItemType.InlayCore &&
        item.itemShade === null &&
        item.teethshadeType === TeethShadeType.SingleShade &&
        item?.itemMaterial?.id !== "METAL_NON_PRECIOUS"
      ) {
        itemStatusTable.push(item.id);
      }
    }
  }
  return itemStatusTable;
};

export const MultipleCreateAnatomyItemInfoForm: FC<
  CreateOrderAnatomyItemInfoProps
> = ({ product, anatomyItemChoicesData, allProducts, order, setIsLoading }) => {
  const [createProductIndex, updateCreateProductIndex] = useCreateOrderStore(
    state => [state.createProductIndex, state.updateCreateProductIndex]
  );
  const [itemStatusTable, setItemStatusTable] = useState<Array<string>>(
    computeItemStatusTableValue(
      product.anatomyItem as NonNullable<
        ProductFromGetOrderByUniqueAttributeQuery["anatomyItem"]
      >
    )
  );

  const updateCreateProductStep = useCreateOrderStore(
    state => state.updateCreateProductStep
  );
  if (!product || !product.anatomyItem || product.anatomyItem.length < 2) {
    console.log(product);
    return <p>Erreur produit</p>;
  }

  return (
    <>
      <Accordion
        variant="separated"
        value={itemStatusTable}
        multiple={true}
      >
        {product.anatomyItem
          // there is two items (crown + post and core) but only one block of question.
          // This prevent duplicate
          .filter(item => {
            if (item.itemType === ItemType.Crown) {
              const findTenon = (product.anatomyItem ?? []).find(itemComp => {
                if (
                  itemComp.teeth instanceof Array &&
                  item.teeth instanceof Array
                ) {
                  return (
                    JSON.stringify(itemComp.teeth) ===
                      JSON.stringify(item.teeth),
                    itemComp.itemType === ItemType.InlayCore
                  );
                }
                return (
                  JSON.parse(itemComp.teeth) === JSON.parse(item.teeth) &&
                  itemComp.itemType === ItemType.InlayCore
                );
              });
              if (findTenon) {
                return false;
              }
            }
            return true;
          })
          .sort((a, b) => {
            if (!a.teeth || !b.teeth) {
              return 0;
            }
            if (a.teeth < b.teeth) {
              return -1;
            }
            if (a.teeth > b.teeth) {
              return 1;
            }
            return 0;
          })
          .map(anatomyItem => (
            <Accordion.Item
              key={anatomyItem.id}
              value={anatomyItem.id}
            >
              <Accordion.Control
                onClick={() => {
                  if (!itemStatusTable.includes(anatomyItem.id as string)) {
                    setItemStatusTable(prevState => [
                      ...prevState,
                      anatomyItem.id,
                    ]);
                  }
                }}
              >
                <Title order={2}>
                  {`Matériau et teinte - ${getItemTypeLabel(product.productType)}`}{" "}
                  {anatomyItem.teeth instanceof Array
                    ? anatomyItem.teeth.join(" ")
                    : anatomyItem.teeth}{" "}
                  :{" "}
                  {itemStatusTable.includes(anatomyItem.id as string)
                    ? "À renseigner"
                    : "Validé"}
                </Title>
              </Accordion.Control>
              <Accordion.Panel>
                <CreateAnatomyItemInfoForm
                  setIsLoading={setIsLoading}
                  product={product}
                  anatomyItemChoicesData={anatomyItemChoicesData}
                  providedAnatomyItem={anatomyItem}
                  setItemStatusTable={setItemStatusTable}
                  allProducts={allProducts}
                  order={order}
                  hasIndividualItemCaracteristics={true}
                />
              </Accordion.Panel>
            </Accordion.Item>
          ))}
      </Accordion>
      <Space h="md" />
      {itemStatusTable.length !== 0 && (
        <>
          {" "}
          <Text style={{ color: "red" }}>
            Veuillez valider les prothèses pour pouvoir continuer
          </Text>
          <Space h="sm" />
        </>
      )}
      <StepsButtons
        handleSubmit={async () => {
          setIsLoading(true);
          await client.refetchQueries({
            include: [GetOrderByUniqueAttributeDocument],
          });
          setIsLoading(false);
          updateCreateProductStep(CreateAnatomyItemStep.Preferences);
        }}
        setPreviousAction={updateCreateProductIndex}
        previousStep={createProductIndex - 1}
        isFirst={createProductIndex > 0 ? false : true}
        isLast={itemStatusTable.length === 0 ? false : true}
      />
    </>
  );
};
