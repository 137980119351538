import { Box, Space, Switch } from "@mantine/core";
import React, { FC, useState } from "react";
import {
  ApplyProductHasIndividualCaracteristicsDocument,
  GetAnatomyItemChoicesQuery,
  GetOrderByUniqueAttributeDocument,
  ItemType,
  GetOrderByUniqueAttributeQuery,
} from "/@/generated/graphql";
import { ProductFromGetOrderByUniqueAttributeQuery } from "../../../../shared/types/products/products.types";
import { useMutation } from "@apollo/client";
import { openConfirmModal } from "@mantine/modals";
import { isArray } from "@apollo/client/cache/inmemory/helpers";
import { CreateAnatomyItemInfoForm } from "/@/pages/products/create/anatomy-item/CreateAnatomyItemInfoForm";
import { MultipleCreateAnatomyItemInfoForm } from "/@/pages/products/create/anatomy-item/MultipleCreateAnatomyItemInfoForm";

type CreateOrderAnatomyItemInfoProps = {
  product: NonNullable<ProductFromGetOrderByUniqueAttributeQuery>;
  allProducts: NonNullable<
    GetOrderByUniqueAttributeQuery["getOrderByUniqueAttribute"]["products"]
  >;
  anatomyItemChoicesData: GetAnatomyItemChoicesQuery;
  providedAnatomyItem?: NonNullable<
    ProductFromGetOrderByUniqueAttributeQuery["anatomyItem"]
  >[0];
  setItemStatusTable?: React.Dispatch<React.SetStateAction<string[]>>;
  order: GetOrderByUniqueAttributeQuery["getOrderByUniqueAttribute"];
  setIsLoading: (isLoading: boolean) => void;
};

export const CreateOrderAnatomyItemInfo: FC<
  CreateOrderAnatomyItemInfoProps
> = ({ product, anatomyItemChoicesData, allProducts, order, setIsLoading }) => {
  const [hasIndividualItemCaracteristics, setHasIndividualItemCaracteristics] =
    useState(product.hasIndividualItemCaracteristics);
  const [applyProductHasIndividualItemCaracteristics] = useMutation(
    ApplyProductHasIndividualCaracteristicsDocument,
    {
      refetchQueries: [GetOrderByUniqueAttributeDocument],
      awaitRefetchQueries: true,
    }
  );

  const onChangeHasIndividualItemCaracteristics = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = event.currentTarget.checked;
    openConfirmModal({
      title: "Êtes-vous sûr de vouloir continuer ?",
      children:
        "Si vous continuez, les caractéristiques de chaque prothèse seront réinitialisées.",
      labels: {
        confirm: "Oui",
        cancel: "Annuler",
      },
      onConfirm: () => {
        applyProductHasIndividualItemCaracteristics({
          variables: {
            productId: product.id,
            itemsHaveIndividualCaracteristics: isChecked,
          },
        }).then(() => setHasIndividualItemCaracteristics(isChecked));
      },
    });
  };

  if (!product || !product.anatomyItem) {
    return <p>Erreur produit, veuillez contacter le support.</p>;
  }
  const productTeethLength =
    typeof product.teeth === "object" && isArray(product.teeth)
      ? product.teeth.length
      : 1;

  return (
    <Box h="100%">
      {productTeethLength > 1 && product.productType !== ItemType.Bridge && (
        <>
          <Switch
            label="Caractéristiques différentes pour chaque prothèse"
            checked={hasIndividualItemCaracteristics}
            onChange={event => onChangeHasIndividualItemCaracteristics(event)}
          />
          <Space h="md" />
        </>
      )}
      {!hasIndividualItemCaracteristics ? (
        <CreateAnatomyItemInfoForm
          product={product}
          allProducts={allProducts}
          anatomyItemChoicesData={anatomyItemChoicesData}
          order={order}
          hasIndividualItemCaracteristics={false}
          setIsLoading={setIsLoading}
        />
      ) : (
        <MultipleCreateAnatomyItemInfoForm
          product={product}
          allProducts={allProducts}
          anatomyItemChoicesData={anatomyItemChoicesData}
          order={order}
          setIsLoading={setIsLoading}
        />
      )}
    </Box>
  );
};
