import { Box, Select, Space, Text, Title } from "@mantine/core";
import React, { FC, useState, useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { ButtonsSelect } from "/@/components/ButtonsSelect";
import { StepsButtons } from "/@/components/StepsButtons";
import { CreateRemovableItemPartialDentureStep } from "../../../../shared/types/orders/createOrder.types";
import { useMutation, useQuery } from "@apollo/client";
import {
  GetRemovableItemChoicesQuery,
  UpdateManyRemovableItemDocument,
  GetAccountProductPreferencesDocument,
} from "/@/generated/graphql";
import { ProductFromGetOrderByUniqueAttributeQuery } from "/@/shared/types/products/products.types";
import { useCreateOrderStore } from "/@/shared/stores/orders/create-store.store";

import {
  getItemTypeLabel,
  PartialAndFullDentureProsthesisShadeType,
  findUserPreferenceForRemovablePreference,
  filterShadeByShadeType,
  formatChoicesData,
} from "@jasper/shared";

type PartialDentureInfoFormData = {
  material: string;
  gingivaShade: string;
  teethShade: string;
};

type CreateRemovableItemPartialDentureInfoProps = {
  product: ProductFromGetOrderByUniqueAttributeQuery;
  removableItemChoicesData: GetRemovableItemChoicesQuery;
  order: GetOrderByUniqueAttributeQuery["getOrderByUniqueAttribute"];
  setIsLoading: (isLoading: boolean) => void;
};
export const CreateRemovableItemPartialDentureInfo: FC<
  CreateRemovableItemPartialDentureInfoProps
> = ({ product, removableItemChoicesData, order, setIsLoading }) => {
  const [updateCreateProductStep] = useCreateOrderStore(state => [
    state.updateCreateProductStep,
  ]);

  const newRemovableItem = product.removableItem[0];

  const [updateManyRemovableItem] = useMutation(
    UpdateManyRemovableItemDocument,
    {
      refetchQueries: ["getOrderByUniqueAttribute"],
      awaitRefetchQueries: true,
    }
  );

  const { data: accountProductPreferences } = useQuery(
    GetAccountProductPreferencesDocument
  );

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<PartialDentureInfoFormData>({
    defaultValues: {
      material: newRemovableItem?.itemMaterial?.id,
      teethShade: newRemovableItem?.teethShade?.id,
      gingivaShade: newRemovableItem?.gingivaShade?.id ?? "GINGIVA_STANDARD",
      shadeType: newRemovableItem?.teethShade?.shadeType
        ? newRemovableItem?.teethShade?.shadeType
        : (findUserPreferenceForRemovablePreference(
            order?.user?.id,
            accountProductPreferences?.getAccountProductPreferences
          )?.shadeType ??
          PartialAndFullDentureProsthesisShadeType.VITA_CLASSIC),
    },
  });

  const watchShadeType = watch("shadeType");

  const [shadeFormChoices, setShadeFormChoice] = useState(
    filterShadeByShadeType(
      removableItemChoicesData?.getItemShadesWhere ?? [],
      watchShadeType
    )
  );

  const materialFormChoices = removableItemChoicesData?.getItemMaterialsWhere
    ? formatChoicesData(removableItemChoicesData.getItemMaterialsWhere)
    : [];

  const gingivaShadeFormChoices = removableItemChoicesData?.getItemShadesWhere
    ? formatChoicesData(
        removableItemChoicesData.getItemShadesWhere.filter(
          shade => shade.gingiva === true
        )
      )
    : [];

  useEffect(() => {
    setShadeFormChoice(
      filterShadeByShadeType(
        removableItemChoicesData?.getItemShadesWhere,
        watchShadeType
      )
    );
  }, [watchShadeType]);

  useEffect(() => {
    const shadeType = findUserPreferenceForRemovablePreference(
      order?.user?.id,
      accountProductPreferences?.getAccountProductPreferences
    )?.shadeType;
    if (
      !newRemovableItem?.teethShade?.shadeType &&
      shadeType &&
      shadeType !== watchShadeType
    ) {
      setValue("shadeType", shadeType);
    }
  }, [accountProductPreferences?.getAccountProductPreferences]);

  const onSubmit: SubmitHandler<PartialDentureInfoFormData> = async data => {
    setIsLoading(true);
    await updateManyRemovableItem({
      variables: {
        productId: product.id,
        itemMaterialId: data.material,
        gingivaShadeId: data.gingivaShade,
        teethShadeId: data.teethShade,
      },
    });
    updateCreateProductStep(CreateRemovableItemPartialDentureStep.Clasps);
    setIsLoading(false);
  };

  if (!product.removableItem || product.removableItem.length <= 0) {
    return (
      <div>
        <Text>
          {"Erreur sur l'ajout de produit. Veuillez contacter le support."}
        </Text>
      </div>
    );
  }

  return (
    <Box>
      <Title
        order={2}
      >{`Matériau et teinte - ${getItemTypeLabel(product.productType)?.toLowerCase()}`}</Title>
      <Space h="md" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Title order={5}>{"Matériau"}</Title>
        <Controller
          name="material"
          rules={{ required: true }}
          control={control}
          render={({ field }) => (
            <ButtonsSelect
              data={materialFormChoices}
              value={field.value}
              onChange={value => field.onChange(value)}
            />
          )}
        />
        {errors?.material?.type === "required" && (
          <Text
            size="sm"
            style={{ color: "red" }}
          >
            Veuillez choisir un matériau
          </Text>
        )}
        <Space h="md" />
        <Text
          size="sm"
          style={{ fontWeight: "bold" }}
        >
          {"Type de teintier"}
        </Text>
        <Controller
          name="shadeType"
          rules={{ required: true }}
          control={control}
          render={({ field }) => (
            <ButtonsSelect
              data={Object.keys(PartialAndFullDentureProsthesisShadeType).map(
                shadeType => ({
                  value: shadeType,
                  label: shadeType.replaceAll("_", " "),
                })
              )}
              value={field.value}
              onChange={value => field.onChange(value)}
            />
          )}
        />
        {errors?.shadeType?.type === "required" && (
          <Text
            size="sm"
            style={{ color: "red" }}
          >
            Veuillez choisir un type de teintier
          </Text>
        )}
        <Space h="md" /> <Title order={5}>{"Teinte des dents"}</Title>
        <Controller
          name="teethShade"
          rules={{ required: true }}
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              data={shadeFormChoices}
            />
          )}
        />
        {errors?.teethShade?.type === "required" && (
          <Text
            size="sm"
            style={{ color: "red" }}
          >
            Veuillez choisir les teintes de votre prothèse
          </Text>
        )}
        <Space h="md" />
        <Title order={5}>{"Teinte de gencive"}</Title>
        <Controller
          name="gingivaShade"
          rules={{ required: true }}
          control={control}
          render={({ field }) => (
            <ButtonsSelect
              data={gingivaShadeFormChoices}
              value={field.value}
              onChange={value => field.onChange(value)}
            />
          )}
        />
        {errors?.gingivaShade?.type === "required" && (
          <Text
            size="sm"
            style={{ color: "red" }}
          >
            Veuillez choisir une teinte de gencive
          </Text>
        )}
        <Space h="xl" />
        <StepsButtons
          handleSubmit={handleSubmit(onSubmit)}
          setPreviousAction={updateCreateProductStep}
          previousStep={
            CreateRemovableItemPartialDentureStep.TeethToManufacture
          }
        />
      </form>
    </Box>
  );
};
