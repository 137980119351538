import { Box, Select, Space, Text, Title } from "@mantine/core";
import React, { FC, useState, useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { ButtonsSelect } from "/@/components/ButtonsSelect";
import { StepsButtons } from "/@/components/StepsButtons";
import { CreateRemovableItemFullDentureStep } from "../../../../shared/types/orders/createOrder.types";
import {
  ProductFromGetOrderByUniqueAttributeQuery,
  fullDentureTeethShapeChoices,
} from "/@/shared/types/products/products.types";
import { useMutation, useQuery } from "@apollo/client";
import {
  GetRemovableItemChoicesQuery,
  UpdateProductDocument,
  UpdateRemovableItemDocument,
  GetAccountProductPreferencesDocument,
  GetOrderByUniqueAttributeQuery,
} from "/@/generated/graphql";
import { useCreateOrderStore } from "/@/shared/stores/orders/create-store.store";

import {
  PartialAndFullDentureProsthesisShadeType,
  findUserPreferenceForRemovablePreference,
  filterShadeByShadeType,
  formatChoicesData,
} from "@jasper/shared";

type FullDentureInfoFormData = {
  gingivaShade: string;
  teethShade: string;
  teethShape: string;
};

type CreateRemovableItemFullDentureInfoProps = {
  product: ProductFromGetOrderByUniqueAttributeQuery;
  removableItemChoicesData: GetRemovableItemChoicesQuery;
  order: GetOrderByUniqueAttributeQuery["getOrderByUniqueAttribute"];
  setIsLoading: (isLoading: boolean) => void;
};
export const CreateRemovableItemFullDentureInfo: FC<
  CreateRemovableItemFullDentureInfoProps
> = ({ product, removableItemChoicesData, order, setIsLoading }) => {
  const [updateRemovableItem] = useMutation(UpdateRemovableItemDocument);
  const [updateProduct] = useMutation(UpdateProductDocument, {
    refetchQueries: ["getOrderByUniqueAttribute"],
    awaitRefetchQueries: true,
  });
  const { data: accountProductPreferences } = useQuery(
    GetAccountProductPreferencesDocument
  );

  const [
    createProductIndex,
    updateCreateProductIndex,
    updateCreateProductStep,
  ] = useCreateOrderStore(state => [
    state.createProductIndex,
    state.updateCreateProductIndex,
    state.updateCreateProductStep,
  ]);

  const newRemovableItem = product.removableItem[0];
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<FullDentureInfoFormData>({
    defaultValues: {
      gingivaShade: newRemovableItem?.gingivaShade?.id ?? "GINGIVA_STANDARD",
      teethShade: newRemovableItem?.teethShade?.id,
      teethShape: product?.itemPreferences?.set?.teethShape,
      shadeType: newRemovableItem?.teethShade?.shadeType
        ? newRemovableItem?.teethShade?.shadeType
        : (findUserPreferenceForRemovablePreference(
            order?.user?.id,
            accountProductPreferences?.getAccountProductPreferences
          )?.shadeType ??
          PartialAndFullDentureProsthesisShadeType.VITA_CLASSIC),
    },
  });

  const watchShadeType = watch("shadeType");

  const [shadeFormChoices, setShadeFormChoice] = useState(
    filterShadeByShadeType(
      removableItemChoicesData?.getItemShadesWhere ?? [],
      watchShadeType
    )
  );

  useEffect(() => {
    setShadeFormChoice(
      filterShadeByShadeType(
        removableItemChoicesData?.getItemShadesWhere,
        watchShadeType
      )
    );
  }, [watchShadeType]);

  useEffect(() => {
    const shadeType = findUserPreferenceForRemovablePreference(
      order?.user?.id,
      accountProductPreferences?.getAccountProductPreferences
    )?.shadeType;
    if (
      !newRemovableItem?.teethShade?.shadeType &&
      shadeType &&
      shadeType !== watchShadeType
    ) {
      setValue("shadeType", shadeType);
    }
  }, [accountProductPreferences?.getAccountProductPreferences]);

  const gingivaShadeFormChoices = removableItemChoicesData?.getItemShadesWhere
    ? formatChoicesData(
        removableItemChoicesData.getItemShadesWhere.filter(
          shade => shade.gingiva === true
        )
      )
    : [];

  const onSubmit: SubmitHandler<FullDentureInfoFormData> = async data => {
    setIsLoading(true);
    const preferencesData = {
      teethShape: data.teethShape,
    };
    await updateRemovableItem({
      variables: {
        where: {
          id: newRemovableItem.id,
        },
        data: {
          itemMaterial: {
            connect: {
              id: "ACRYLIC",
            },
          },
          teethShade: {
            connect: {
              id: data.teethShade,
            },
          },
          gingivaShade: {
            connect: {
              id: data.gingivaShade,
            },
          },
        },
      },
    });
    try {
      await updateProduct({
        variables: {
          where: {
            id: product.id,
          },
          data: {
            itemPreferences: {
              set: preferencesData,
            },
          },
        },
      });
      setIsLoading(false);
      updateCreateProductIndex(createProductIndex + 1);
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
    }
  };

  if (!product.removableItem || product.removableItem.length <= 0) {
    return (
      <div>
        <Text>
          {"Erreur sur l'ajout de produit. Veuillez contacter le support."}
        </Text>
      </div>
    );
  }

  return (
    <Box>
      <Title order={2}>
        {"Caractéristiques de votre Prothèse Amovible Complète"}
      </Title>
      <Space h="md" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Title order={4}>{"Teinte"}</Title>
        <Space h="md" />
        <Title order={4}>{"Gencives"}</Title>
        <Controller
          name="gingivaShade"
          rules={{ required: true }}
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              data={gingivaShadeFormChoices}
            />
          )}
        />
        {errors?.gingivaShade?.type === "required" && (
          <Text
            size="sm"
            style={{ color: "red" }}
          >
            Veuillez choisir une teinte de gencive pour votre prothèse
          </Text>
        )}
        <Space h="md" />
        <Title order={4}>{"Type de teintier"}</Title>
        <Controller
          name="shadeType"
          rules={{ required: true }}
          control={control}
          render={({ field }) => (
            <ButtonsSelect
              data={Object.keys(PartialAndFullDentureProsthesisShadeType).map(
                shadeType => ({
                  value: shadeType,
                  label: shadeType.replaceAll("_", " "),
                })
              )}
              value={field.value}
              onChange={value => field.onChange(value)}
            />
          )}
        />
        {errors?.shadeType?.type === "required" && (
          <Text
            size="sm"
            style={{ color: "red" }}
          >
            Veuillez choisir un type de teintier
          </Text>
        )}
        <Space h="md" />
        <Title order={4}>{"Dents"}</Title>
        <Controller
          name="teethShade"
          rules={{ required: true }}
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              data={shadeFormChoices}
            />
          )}
        />
        {errors?.teethShade?.type === "required" && (
          <Text
            size="sm"
            style={{ color: "red" }}
          >
            Veuillez choisir les teintes de votre prothèse
          </Text>
        )}
        <Space h="md" />
        <Title order={4}>{"Forme des dents"}</Title>
        <Controller
          name="teethShape"
          rules={{ required: true }}
          control={control}
          render={({ field }) => (
            <ButtonsSelect
              data={fullDentureTeethShapeChoices}
              numberOfColumns={3}
              value={field.value}
              onChange={value => field.onChange(value)}
            />
          )}
        />
        {errors?.teethShape?.type === "required" && (
          <Text
            size="sm"
            style={{ color: "red" }}
          >
            Veuillez choisir une forme de dents
          </Text>
        )}
        <Space h="xl" />
        <StepsButtons
          handleSubmit={handleSubmit(onSubmit)}
          setPreviousAction={updateCreateProductStep}
          previousStep={CreateRemovableItemFullDentureStep.Typology}
        />
      </form>
    </Box>
  );
};
