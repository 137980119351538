import { Box, Grid, Image, Space, Text, Title } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  GetManyOrdersOfUserDocument,
  OrderStatus,
  SortOrder,
  UserType,
} from "/@/generated/graphql";
import { useAuthContext } from "/@/shared/contexts/AuthContext";
import { OrderSummaryRow } from "/@/components/order/OrderSummaryRow";
import trios3ImgUrl from "/@/assets/images/trios3.png";
import { useQuery } from "@apollo/client";

const getFirstDayOfMonth = () => {
  const dt = new Date();
  dt.setDate(1);
  dt.setHours(0);
  dt.setMinutes(1);
  return dt;
};

const getTodayDate = () => {
  const dt = new Date();
  return dt;
};

const firstDayOfMonth = getFirstDayOfMonth();
const todayDate = getTodayDate();

export const Dashboard: FC = () => {
  const navigate = useNavigate();
  const { user } = useAuthContext();

  const { data: draftOrdersData } = useQuery(GetManyOrdersOfUserDocument, {
    fetchPolicy: "network-only",
    variables: {
      where: {
        status: {
          equals: OrderStatus.Draft,
        },
      },
      take: 5,
      orderBy: [
        {
          confirmedAt: {
            nulls: "last",
            sort: SortOrder.Desc,
          },
        },
        {
          updatedAt: SortOrder.Desc,
        },
      ],
    },
  });

  const getNumberOfOrderToShowOnHomePage = () => {
    let diviseur = 200;
    if ((draftOrdersData?.getManyOrdersOfUser ?? []).length === 1) {
      diviseur = 300;
    }
    if ((draftOrdersData?.getManyOrdersOfUser ?? []).length >= 2) {
      diviseur = 350;
    }
    const nb = Math.floor(window.screen.availHeight / diviseur) || 2;
    if (nb < 2) {
      return 2;
    }
    return nb;
  };

  const { data } = useQuery(GetManyOrdersOfUserDocument, {
    fetchPolicy: "network-only",
    variables: {
      where: {
        status: {
          not: {
            equals: OrderStatus.Draft,
          },
        },
      },
      take: getNumberOfOrderToShowOnHomePage(),
      orderBy: [
        {
          updatedAt: SortOrder.Desc,
        },
      ],
    },
  });

  const { data: getOrdersOfThisMonth } = useQuery(GetManyOrdersOfUserDocument, {
    fetchPolicy: "network-only",
    variables: {
      where: {
        createdAt: {
          gte: firstDayOfMonth.getTime(),
          lte: todayDate.getTime(),
        },
        isArchived: {
          equals: false,
        },
      },
      orderBy: [
        {
          updatedAt: SortOrder.Desc,
        },
      ],
    },
  });

  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 15 * 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const { classes } = useStyles();

  if (!data || !draftOrdersData) {
    return null;
  }

  const currentDateAndTimeString = currentTime.toLocaleString("fr-FR", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
  const currentMonthAndYearString = currentTime.toLocaleString("fr-FR", {
    year: "numeric",
    month: "long",
  });

  const currentMonthOngoingOrdersCount =
    getOrdersOfThisMonth?.getManyOrdersOfUser.filter(order => {
      const orderDate = new Date(order.createdAt);
      const today = new Date();
      return (
        (order.status === OrderStatus.Completed ||
          order.status === OrderStatus.Confirmed ||
          order.status === OrderStatus.Producing ||
          order.status === OrderStatus.ProducingInTransit ||
          order.status === OrderStatus.Shipped) &&
        orderDate.getMonth() === today.getMonth()
      );
    }).length;

  const currentMonthDeliveredOrdersCount =
    getOrdersOfThisMonth?.getManyOrdersOfUser.filter(order => {
      const orderDate = new Date(order.createdAt);
      const today = new Date();
      return (
        order.status === OrderStatus.Delivered &&
        orderDate.getMonth() === today.getMonth() &&
        orderDate.getYear() === today.getYear()
      );
    }).length;

  return (
    <Box
      p="sm"
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
      }}
    >
      <Grid
        gutter={0}
        p="sm"
        style={{
          width: "100%",
          height: "fit-content",
        }}
      >
        <Grid.Col span={8}>
          <Box
            pr="md"
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            <Box
              p="xl"
              style={theme => ({
                width: "100%",
                height: "100%",
                borderRadius: theme.radius.md,
                backgroundColor: theme.colors.primaryLight[9],
              })}
            >
              <Grid
                style={{
                  height: "100%",
                }}
              >
                <Grid.Col
                  span={8}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                  }}
                >
                  <Text
                    size="sm"
                    style={theme => ({
                      color: theme.colors.greyScale[6],
                      fontWeight: "bold",
                    })}
                    mt="7%"
                  >
                    {currentDateAndTimeString}
                  </Text>
                  <Space h="xs" />
                  <Title order={3}>
                    Bonjour{" "}
                    {user?.type === UserType.Dentist && !user?.managedUserGroup
                      ? "Dr."
                      : ""}{" "}
                    {user?.firstName} {user?.lastName}
                  </Title>
                  <Space h="md" />
                  <Text
                    size="md"
                    style={theme => ({
                      color: theme.colors.greyScale[5],
                    })}
                  >
                    Découvrez toutes les bonnes pratiques et protocoles de votre
                    caméra intra-orale avec le <b>guide Jasper !</b>
                  </Text>
                </Grid.Col>
                <Grid.Col
                  span={4}
                  mt="md"
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                  }}
                >
                  <Image src={trios3ImgUrl} />
                </Grid.Col>
              </Grid>
            </Box>
          </Box>
        </Grid.Col>
        <Grid.Col
          span={4}
          style={{
            height: "100%",
            minHeight: "fit-content",
            // width: "100%",
            // display: "flex",
            // flexDirection: "column",
            // justifyContent: "space-evenly",
          }}
        >
          <Box
            style={{
              // height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              minHeight: "fit-content",
            }}
          >
            <Box
              style={theme => ({
                borderRadius: theme.radius.md,
                backgroundColor: theme.colors.primaryLight[8],
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                // height: "100%",
                minHeight: "fit-content",
              })}
              p="sm"
            >
              <Title order={5}>Commandes en cours</Title>
              <Title
                order={1}
                size={40}
                style={theme => ({
                  color: theme.colors.primary[3],
                })}
              >
                {currentMonthOngoingOrdersCount}
              </Title>
              <Text
                size="sm"
                style={theme => ({
                  color: theme.colors.greyScale[3],
                })}
              >
                {currentMonthAndYearString.replace(/^\w/, c => c.toUpperCase())}
              </Text>
            </Box>
            <Space h="md" />
            <Box
              style={theme => ({
                borderRadius: theme.radius.md,
                backgroundColor: theme.colors.primaryLight[6],
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                // height: "100%",
              })}
              p="sm"
            >
              <Title order={5}>Commandes livrées</Title>
              <Title
                order={1}
                size={40}
                style={theme => ({
                  color: theme.colors.primary[3],
                })}
              >
                {currentMonthDeliveredOrdersCount}
              </Title>
              <Text
                size="sm"
                style={theme => ({
                  color: theme.colors.greyScale[3],
                })}
              >
                {currentMonthAndYearString.replace(/^\w/, c => c.toUpperCase())}
              </Text>
            </Box>
          </Box>
        </Grid.Col>
      </Grid>
      <Box className={classes.container}>
        {(draftOrdersData?.getManyOrdersOfUser ?? []).length > 0 && (
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Title
              order={3}
              style={theme => ({
                color: theme.colors.black,
              })}
            >
              Notifications
            </Title>
            <Title
              order={6}
              style={{
                cursor: "pointer",
              }}
              onClick={() => navigate("/notifications")}
            >
              Voir tout
            </Title>
          </Box>
        )}
        <>
          {draftOrdersData?.getManyOrdersOfUser.slice(0, 2).map(order => {
            return (
              <OrderSummaryRow
                key={order.id}
                order={order}
              />
            );
          })}
        </>
      </Box>
      <Box className={classes.container}>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Title
            order={3}
            style={theme => ({
              color: theme.colors.black,
            })}
          >
            Vos dernières commandes
          </Title>
          <Title
            order={6}
            style={{
              cursor: "pointer",
            }}
            onClick={() => navigate("/orders")}
          >
            Voir tout
          </Title>
        </Box>
        <>
          {[...data.getManyOrdersOfUser]
            .slice(0, getNumberOfOrderToShowOnHomePage())
            .map(order => {
              return (
                <OrderSummaryRow
                  key={order.id}
                  order={order}
                />
              );
            })}
        </>
      </Box>
    </Box>
  );
};

const useStyles = createStyles(theme => ({
  container: {
    padding: `${theme.spacing.sm}px ${theme.spacing.xs}px`,
    width: "100%",
  },
  stepper: {
    width: "70%",
  },
  stepperText: {
    fontSize: theme.fontSizes.xs,
  },
  stepperLine: {
    backgroundColor: theme.colors.greyScale[8],
  },
  stepperLineActive: {
    backgroundColor: theme.colors.primary[4],
  },
}));
