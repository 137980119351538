import React, { createContext, useState } from "react";
import { useContext } from "react";
import { ReactNode } from "react";

type IpcContextState = {
  creatingOrderId: string | null;
};

type ContextValue = {
  creatingOrderId: string | null;
  setCreatingOrderId: React.Dispatch<React.SetStateAction<string | null>>;
};

type IpcContextProviderProps = {
  children: ReactNode;
};

const IpcContext = createContext<ContextValue | undefined>(undefined);
const initialState: IpcContextState = {
  creatingOrderId: null,
};

function IpcContextProvider({ children }: IpcContextProviderProps) {
  const [creatingOrderId, setCreatingOrderId] = useState(
    initialState.creatingOrderId
  );
  const ipcContextValue = {
    creatingOrderId,
    setCreatingOrderId,
  };

  return (
    <IpcContext.Provider value={ipcContextValue}>
      {children}
    </IpcContext.Provider>
  );
}

function useIpcContext() {
  const context = useContext(IpcContext);
  if (context === undefined) {
    throw new Error("useIpcContext not used within the IpcContextProvider");
  }
  return context;
}

export { IpcContextProvider, useIpcContext };
