import React, { FC, useEffect, useRef, useState } from "react";
import { FileType, getDomain } from "@jasper/shared";
import { useParams } from "react-router-dom";
import { Box, MantineProvider, Text } from "@mantine/core";
import Logo from "/@/assets/images/logo.svg";
import Cookies from "js-cookie";
import { Carousel } from "@jasper/shared/stories/Carousel/Carousel";
import { io, Socket } from "socket.io-client";
import { getApiUrl } from "/@/services/api/api";
import { ButtonFileInput } from "@jasper/shared/stories/ButtonFileInput/ButtonFileInput";
import { IconTrash } from "@tabler/icons-react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { FileWithPath } from "@mantine/dropzone";
import {
  DeleteS3FileDocument,
  GET_FILE_DOWNLOAD_URL,
  getPresignedPost,
} from "@jasper/shared/gql/files";
import axios from "axios";
import { isUUID } from "@jasper/shared/utils/file.utils";
import LZString from "lz-string";
import { openDB } from "idb";
import { handleImageUpload } from "@jasper/shared";

interface RouteParams {
  token: string | undefined;
  id: string | undefined;
  name: string | undefined;
  filetype: string | undefined;
}

export const UploadFileHelpPage: FC = () => {
  const [files, setFiles] = useState<any[]>([]);
  const count = useRef<number>(0);
  const param = useParams<keyof RouteParams>();
  const socketRef = useRef<Socket | null>(null);

  // Set authentication cookie
  if (param.token !== undefined) {
    Cookies.set("Authentication", param.token, {
      expires: 1,
      domain: getDomain(import.meta.env.MODE),
    });
  }

  if (!param.id || !param.name || !param.token) {
    return null; // Handle missing params gracefully
  }

  const [deleteS3File] = useMutation(DeleteS3FileDocument);
  const [getPresignedPostDocument] = useLazyQuery(getPresignedPost);
  const [getPresignedDownload] = useLazyQuery(GET_FILE_DOWNLOAD_URL);

  const saveLargeJSON = async (json: {}) => {
    const db = await openDB("WebappDB", 1, {
      upgrade(db) {
        if (!db.objectStoreNames.contains("IndexDbFiles")) {
          db.createObjectStore("IndexDbFiles");
        }
      },
    });
    await db.put("IndexDbFiles", json, param.id);
  };

  const loadLargeJSON = async () => {
    if (param.id !== undefined) {
      const db = await openDB("WebappDB", 1, {
        upgrade(db) {
          if (!db.objectStoreNames.contains("IndexDbFiles")) {
            db.createObjectStore("IndexDbFiles");
          }
        },
      });
      return await db.get("IndexDbFiles", param.id);
    }
  };

  const uploadFileToS3 = async (
    files: FileWithPath[]
  ): Promise<any[] | null> => {
    if (!files) return null;
    const filesWithKey: any[] = await Promise.all(
      files.map(async file => {
        const getPresignedPostData = await getPresignedPostDocument({
          variables: {
            fileName: file.name,
          },
        });
        const presignedPost = JSON.parse(
          getPresignedPostData?.data?.getPresignedFileUploadUrl ?? ""
        );
        const uploadFormData = new FormData();
        Object.keys(presignedPost.fields).forEach(key => {
          uploadFormData.append(key, presignedPost.fields[key]);
        });
        uploadFormData.append("file", file);

        const res = await axios.post(presignedPost.url, uploadFormData, {
          // investiguer ici pour erreur S3
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        if (res.status === 201 && presignedPost.fields.key) {
          return new Promise<any>((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () =>
              resolve({
                fileType: FileType.HELP_FILE,
                key: presignedPost.fields.key ?? null,
                path: reader.result as string,
              });
            reader.onerror = error => reject(error);
          });
        }
      })
    );
    return filesWithKey.filter(file => file !== null);
  };

  const deleteOneS3File = async (key: string) => {
    const deletedFile = await deleteS3File({
      variables: { key },
    });
    setFiles(files.filter(f => f.key !== deletedFile.data.deleteS3File));
  };

  const getPresignedDownloadUrl = async (key: string) => {
    const res = await getPresignedDownload({
      variables: { key },
    });
    window.open(res?.data?.getPresignedFileDownloadUrl, "_blank");
  };

  useEffect(() => {
    if (param.id) {
      loadLargeJSON().then(data => {
        if (data) {
          setFiles(JSON.parse(data));
        }
      });
    }
    const socket = io(getApiUrl(), {
      query: {
        type: "helpFile",
        id: param.id,
      },
    });
    socketRef.current = socket;

    socket.on("disconnect", reason => {
      console.info("disconnect", reason, "No user id in upload file help page");
      socket.connect();
    });
    return () => {
      socket.disconnect();
      socketRef.current = null;
    };
  }, [param.id]);

  useEffect(() => {
    if (param.id && count.current > 0) saveLargeJSON(JSON.stringify(files));
    if (socketRef.current && count.current > 0) {
      socketRef.current.emit("clientOrderHelpFileSave", {
        id: param.id,
        /* eslint-disable @typescript-eslint/no-unused-vars */
        payload: LZString.compress(
          JSON.stringify(files.map(({ path, ...rest }) => rest))
        ),
      });
    }
    count.current += 1;
  }, [files]);

  return (
    <>
      <div
        style={{
          width: "100%",
          minHeight: "100vh",
          display: "flex",
          backgroundColor: "#5172E6",
          padding: "20px 0",
        }}
      >
        <div
          style={{
            width: "80%",
            minHeight: "60%",
            margin: "auto",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "white",
            borderRadius: "10px",
            padding: "20px",
          }}
        >
          <img
            src={Logo}
            style={{
              height: "auto",
              margin: "20px auto",
            }}
          />
          <p>
            Complétez votre message d'aide avec une ou plusieurs photos.
            <br />
          </p>
          <p>Nom du patient : {param.name}</p>
          <ButtonFileInput
            onFilesAdd={async uploadedFile => {
              const fileList: File[] = await Promise.all(
                [...uploadedFile].map(async file => {
                  const name = file.name.split(".");
                  return new File(
                    [(await handleImageUpload(file)) ?? file],
                    `photo_${files.length + (length + 1)}.${name[name.length - 1]}`,
                    {
                      type: file.type,
                    }
                  );
                })
              );
              const res = await uploadFileToS3(fileList);
              if (res) {
                setFiles([...files, ...res]);
              }
            }}
            buttonText={
              <span>
                Prendre une photo ou
                <br />
                choisir dans la photothèque
              </span>
            }
            accept="image/*"
          />
          {files && files.length > 0 && (
            <Box>
              <div
                style={{
                  padding: "20px",
                }}
              >
                <Carousel imageList={files.toReversed()} />
              </div>
              <MantineProvider>
                <Text>Fichiers ajoutés:</Text>
                <Box>
                  {files.toReversed().map(file => (
                    <Box
                      key={file.id}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      {file.url && (
                        <a
                          id={file.key}
                          style={{
                            display: "none",
                          }}
                          href={file.url}
                          download={file.key}
                          target="_blank"
                        ></a>
                      )}
                      <div
                        onClick={async () => {
                          if (file.url) {
                            document.getElementById(file.key)?.click();
                          } else {
                            getPresignedDownloadUrl(file.key);
                          }
                        }}
                        className="link-style"
                      >
                        {file.path && (
                          <div className="icon-style">
                            <img src={file.path} />
                          </div>
                        )}
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <span
                            style={{
                              margin: "auto",
                            }}
                          >
                            {isUUID(file.key)
                              ? file.key.substring(37)
                              : file.key}
                          </span>
                        </div>
                      </div>
                      <Box
                        style={{
                          cursor: "pointer",
                          display: "flex",
                        }}
                      >
                        <IconTrash
                          style={{
                            margin: "auto",
                          }}
                          onClick={async () => {
                            await deleteOneS3File(file.key);
                          }}
                        />
                      </Box>
                    </Box>
                  ))}
                </Box>
              </MantineProvider>
            </Box>
          )}
        </div>
      </div>
    </>
  );
};
