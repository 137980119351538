import React, { useEffect, useState } from "react";
import { RoutesDispatcher } from "/@/routes/RoutesDispatcher";
import { api } from "./services/api/api";
import { useAuthContext } from "./shared/contexts/AuthContext";
import "dayjs/locale/fr";
import "@mantine/dropzone/styles.css";

function App() {
  const [authInit, setAuthInit] = useState(true);
  const { logIn, logOut } = useAuthContext();

  const checkIfLoggedIn = async () => {
    const user = await api.get("/users/me");
    return user;
  };

  const onFocus = () => {
    if (!checkIfLoggedIn()) {
      logOut();
    }
  };

  useEffect(() => {
    window.addEventListener("focus", onFocus);

    return () => {
      window.removeEventListener("focus", onFocus);
    };
  }, [false]);

  useEffect(() => {
    checkIfLoggedIn()
      .then(res => {
        logIn(res.data);
      })
      .catch(() => {
        logOut();
      })
      .finally(() => {
        setAuthInit(false);
      });
  }, []);

  return !authInit ? (
    <>
      <RoutesDispatcher />
    </>
  ) : null;
}

export default App;
