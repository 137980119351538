import { Box, Button, Grid, Modal, Space, Text, Title } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import {
  File,
  GetOrderByUniqueAttributeQuery,
  Order,
  OrderComment,
  OrderStatus,
} from "/@/generated/graphql";
import React, { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DentalArchesSvg from "/@/pages/orders/DentalArchesSvg";
import { OrderStatusStepper } from "/@/pages/orders/OrderStatusStepper";
import { OrderFilesManagement } from "/@/pages/orders/OrderFilesManagement";
import { useQuery, useLazyQuery } from "@apollo/client";
import { OrderGetHelp } from "/@/pages/orders/OrderGetHelp";
import { OrderGetRedo } from "/@/pages/orders/OrderGetRedo";
import { ItemsDescription } from "/@/components/order/ItemsDescription";

import {
  getAddressLabelFirstLine,
  getAddressLabelSecondLine,
  getOrderFirstProduct,
  getOrderStatusStep,
  getPatientLabelFirstLine,
  getPatientLabelSecondLine,
  getProductTeeth,
  getAllTeeths,
  getAllOrderComments,
  getAllRedoComments,
  GET_FILE_DOWNLOAD_URL,
} from "@jasper/shared";
import { GET_ORDER_BY_UNIQUE_ATTRIBUTE } from "@jasper/shared/gql/orders";
import { FileList } from "@jasper/shared/stories/FileList/FileList";
import { v4 as uuidv4 } from "uuid";

export const OrderPage: FC = () => {
  const param = useParams();
  const { classes } = useStyles();

  const [getPresignedDownload] = useLazyQuery(GET_FILE_DOWNLOAD_URL);
  const getPresignedDownloadUrl = async (key: string) => {
    const res = await getPresignedDownload({
      variables: { key },
    });
    window.open(res?.data?.getPresignedFileDownloadUrl, "_blank");
  };
  const getFileDownloadUrl = async (key: string) => {
    const res = await getPresignedDownload({
      variables: { key },
    });
    return res?.data?.getPresignedFileDownloadUrl;
  };
  const getFilesWithPath = async (orderComment: any[]) => {
    return await Promise.all(
      orderComment.map(async msg => {
        const updatedFiles = await Promise.all(
          msg.files.map(async (file: File) => {
            try {
              return {
                id: file.id,
                path: await getFileDownloadUrl(file.key),
                key: file.key,
                fileType: file.fileType,
              };
            } catch (error) {
              console.error(
                `Error fetching path for file key: ${file.key}`,
                error
              );
              return {
                key: file.key,
                fileType: file.fileType,
                path: null,
              };
            }
          })
        );
        return {
          ...msg,
          files: updatedFiles,
        };
      })
    );
  };

  const [selectedProduct, setSelectedProduct] = useState<
    | NonNullable<
        GetOrderByUniqueAttributeQuery["getOrderByUniqueAttribute"]["products"]
      >[0]
    | null
  >(null);
  const [
    isOrderFilesManagementModalOpened,
    setIsOrderFilesManagementModalOpened,
  ] = useState(false);
  const [isOrderHelpModalOpened, setIsOrderHelpModalOpened] = useState(false);
  const [isOrderRedoModalOpened, setIsOrderRedoModalOpened] = useState(false);
  const [orderComments, setOrderComments] = useState<OrderComment[]>([]);

  const { data, refetch } = useQuery(GET_ORDER_BY_UNIQUE_ATTRIBUTE, {
    fetchPolicy: "network-only",
    variables: {
      where: {
        id: param.id,
      },
    },
  });

  useEffect(() => {
    if (data) {
      setSelectedProduct(getOrderFirstProduct(data.getOrderByUniqueAttribute));
      getFilesWithPath(data.getOrderByUniqueAttribute.orderComment).then(
        orderCommentsWithUpdatedFilesWithFilePath => {
          setOrderComments(orderCommentsWithUpdatedFilesWithFilePath);
        }
      );
    }
  }, [data]);

  if (
    !data ||
    !data.getOrderByUniqueAttribute ||
    !data.getOrderByUniqueAttribute.products ||
    data.getOrderByUniqueAttribute.products.length < 1
  ) {
    return null;
  } else {
    const user = data.getOrderByUniqueAttribute.user;
    const order = data.getOrderByUniqueAttribute;
    const orderDate = new Date(data.getOrderByUniqueAttribute.createdAt);
    let expectedDeliveryDate = null;
    if (data.getOrderByUniqueAttribute.deliveryDate !== null) {
      expectedDeliveryDate = new Date(0);
      expectedDeliveryDate.setUTCMilliseconds(
        data.getOrderByUniqueAttribute.deliveryDate
      );
    } else {
      expectedDeliveryDate = new Date();
      expectedDeliveryDate.setDate(orderDate.getDate() + 10);
    }

    return (
      <Box
        p="lg"
        style={() => ({
          display: "flex",
          flexDirection: "column",
          height: "100%",
        })}
      >
        <Modal
          centered
          transitionProps={{
            transition: "fade",
            duration: 300,
          }}
          withCloseButton={false}
          size="xl"
          opened={isOrderFilesManagementModalOpened}
          onClose={() => setIsOrderFilesManagementModalOpened(false)}
          styles={{
            title: {
              alignContent: "center",
              justifyContent: "center",
            },
          }}
        >
          <OrderFilesManagement
            order={data.getOrderByUniqueAttribute}
            closeModal={() => setIsOrderFilesManagementModalOpened(false)}
          />
        </Modal>
        <Modal
          centered
          transitionProps={{
            transition: "fade",
            duration: 300,
          }}
          withCloseButton={false}
          size="xl"
          opened={isOrderHelpModalOpened}
          onClose={() => setIsOrderHelpModalOpened(false)}
          styles={{
            title: {
              alignContent: "center",
              justifyContent: "center",
            },
          }}
        >
          <OrderGetHelp
            order={data.getOrderByUniqueAttribute}
            refetch={refetch}
            closeModal={() => setIsOrderHelpModalOpened(false)}
            id={localStorage.getItem("help_temp_ID") || ""}
          />
        </Modal>
        <Modal
          centered
          transitionProps={{
            transition: "fade",
            duration: 300,
          }}
          withCloseButton={false}
          size="xl"
          opened={isOrderRedoModalOpened}
          onClose={() => setIsOrderRedoModalOpened(false)}
          styles={{
            title: {
              alignContent: "center",
              justifyContent: "center",
            },
          }}
        >
          <OrderGetRedo
            order={data.getOrderByUniqueAttribute}
            closeModal={() => {
              refetch();
              setIsOrderRedoModalOpened(false);
            }}
          />
        </Modal>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Title
              order={4}
              style={theme => ({
                color: theme.colors.primary[4],
              })}
            >
              Commande {order.orderReference}
            </Title>
          </Box>
          <Box
            style={theme => ({
              display: "flex",
              gap: theme.spacing.sm,
            })}
          >
            <Button
              variant="filled"
              onClick={() => setIsOrderFilesManagementModalOpened(true)}
            >
              Fichiers
            </Button>
            {order.status !== OrderStatus.Delivered && (
              <Button
                variant="outline"
                onClick={() => {
                  setIsOrderHelpModalOpened(true);
                  if (!localStorage.getItem("help_temp_ID"))
                    localStorage.setItem("help_temp_ID", uuidv4());
                }}
              >
                Commentaire
              </Button>
            )}
            {order.status === OrderStatus.Delivered && (
              <Button
                variant="outline"
                onClick={() => setIsOrderRedoModalOpened(true)}
              >
                Refabrication
              </Button>
            )}
          </Box>
        </Box>
        <Space h="sm" />
        <Grid
          gutter="xl"
          style={{
            flexGrow: 1,
            minHeight: "fit-content",
            maxHeight: "30%",
          }}
        >
          <Grid.Col
            className={classes.gridColContainer}
            span={5}
            style={{
              height: "inherit",
            }}
          >
            <Box className={classes.itemsDetails}>
              <Title order={5}>Patient</Title>
              <Text className={classes.boxText}>
                {getPatientLabelFirstLine(order)}
              </Text>
              <Text className={classes.boxText}>
                {getPatientLabelSecondLine(order)}
              </Text>
              <Space h="md" />
              <Title order={5}>Praticien</Title>
              <Text className={classes.boxText}>
                {user.firstName} {user.lastName}
              </Text>
              <Text className={classes.boxText}>
                {getAddressLabelFirstLine(order)}
              </Text>
              <Text className={classes.boxText}>
                {getAddressLabelSecondLine(order)}
              </Text>
            </Box>
          </Grid.Col>
          <Grid.Col
            className={classes.gridColContainer}
            span={7}
            style={{
              height: "inherit",
            }}
          >
            <Box className={classes.itemsDetails}>
              <Space h="md" />
              <Title order={5}>
                Date de livraison estimée:{" "}
                {expectedDeliveryDate.toLocaleDateString()}
              </Title>
              <Space h="md" />
              <OrderStatusStepper
                orderStatus={getOrderStatusStep(
                  data.getOrderByUniqueAttribute.status
                )}
                order={data.getOrderByUniqueAttribute as Order}
                labelsUnder={true}
                grow={true}
              />
            </Box>
          </Grid.Col>
        </Grid>
        <Space h="xl" />
        <Grid
          gutter="xl"
          style={{ flexGrow: 1 }}
        >
          <Grid.Col
            className={classes.gridColContainer}
            span={5}
            style={{
              height: "inherit",
            }}
          >
            <Box
              className={classes.itemsDetails}
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Title
                order={4}
                className={classes.boxTitle}
              >
                Dents du produit sélectionné
              </Title>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                {getProductTeeth(getOrderFirstProduct(order)) && (
                  <DentalArchesSvg
                    teeth={
                      selectedProduct?.productType === "PARTIAL_DENTURE" &&
                      selectedProduct?.removableItem &&
                      selectedProduct?.removableItem?.length > 0
                        ? selectedProduct?.teeth
                        : selectedProduct?.removableItem &&
                            selectedProduct?.removableItem?.length > 0 &&
                            selectedProduct?.removableItem[0]?.isBothJaws
                          ? getAllTeeths()
                          : selectedProduct?.teeth
                    }
                    width={275}
                    height={366}
                  />
                )}
              </Box>
            </Box>
          </Grid.Col>
          <Grid.Col
            className={classes.gridColContainer}
            span={7}
            style={{
              height: "inherit",
            }}
          >
            <Box
              className={classes.itemsDetails}
              style={{ height: "100%" }}
            >
              <Title
                order={4}
                className={classes.boxTitle}
              >
                {`Détail de la commande${
                  order.products && order.products.length > 1
                    ? " (cliquez sur un produit pour le sélectionner)"
                    : ""
                }`}
              </Title>
              <Box>
                <Space h="md" />
                <Box>
                  {order.products &&
                    order.products.map(product => (
                      <Box key={product.id}>
                        <Button
                          variant={
                            selectedProduct?.id === product.id
                              ? "filled"
                              : "outline"
                          }
                          styles={{
                            inner: {
                              justifyContent: "start",
                            },
                          }}
                          color={
                            selectedProduct?.id === product.id
                              ? "primary.5"
                              : undefined
                          }
                          style={theme => ({
                            "&:hover": {
                              backgroundColor:
                                selectedProduct?.id === product.id
                                  ? undefined
                                  : theme.colors.greyScale[9],
                            },
                          })}
                          h="auto"
                          p={8}
                          mb="xs"
                          w="100%"
                          onClick={() => setSelectedProduct(product)}
                        >
                          <ItemsDescription
                            key={product.id}
                            product={product}
                            order={order}
                          />
                        </Button>
                      </Box>
                    ))}
                </Box>
                <Space h="xl" />
                <Title order={5}>Notes du praticien sur la commande</Title>
                <div
                  style={{
                    padding: "10px",
                  }}
                >
                  {getAllOrderComments(order).length > 0
                    ? getAllOrderComments(order).map(comment => {
                        return (
                          <div
                            key={comment.id}
                            style={{
                              backgroundColor: "#f5f5f5",
                              padding: "5px 10px",
                              marginBottom: "5px",
                              borderRadius: "5px",
                            }}
                          >
                            {comment?.comment && (
                              <span
                                style={{
                                  fontWeight: "500",
                                }}
                              >
                                {comment?.comment}
                              </span>
                            )}
                            {(orderComments.find(
                              orderComment => orderComment.id === comment.id
                            )?.files?.length ?? 0) > 0 && (
                              <div
                                style={{
                                  margin: "5px 10px",
                                }}
                              >
                                <FileList
                                  files={
                                    orderComments.find(
                                      orderComment =>
                                        orderComment.id === comment.id
                                    )?.files ?? []
                                  }
                                  downloadOneFile={getPresignedDownloadUrl}
                                />
                              </div>
                            )}
                          </div>
                        );
                      })
                    : "Pas de note pour cette commande"}
                </div>
                {getAllRedoComments(order).length > 0 && (
                  <>
                    <Space h="xl" />
                    <Title order={5}>
                      Notes du praticien sur la demande de refabrication de
                      cette commande
                    </Title>
                    <ul>
                      {getAllRedoComments(order).map(redo => {
                        return (
                          <Text size="sm">
                            {redo?.comment ? (
                              <li>
                                <span
                                  style={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  {new Date(
                                    redo?.createdAt
                                  ).toLocaleDateString()}
                                </span>
                                : {redo?.comment}
                              </li>
                            ) : (
                              ""
                            )}
                          </Text>
                        );
                      })}
                    </ul>
                  </>
                )}
              </Box>
            </Box>
          </Grid.Col>
        </Grid>
      </Box>
    );
  }
};

const useStyles = createStyles(theme => ({
  gridColContainer: {
    height: "100%",
  },
  itemsDetails: {
    borderRadius: "10px",
    outline: `1px solid ${theme.colors.greyScale[9]}`,
    padding: theme.spacing.md,
    height: "100%",
    minHeight: "100%",
    minWidth: "100%",
  },
  boxTitle: {
    color: theme.colors.primary[4],
  },
  boxText: {
    fontSize: theme.fontSizes.sm,
  },
}));
