import { Box, Space, Text, Textarea, Title } from "@mantine/core";
import React, { FC, useState } from "react";
import { CreateRemovableItemPartialDentureStep } from "../../../../shared/types/orders/createOrder.types";
import { StepsButtons } from "/@/components/StepsButtons";
import { useMutation } from "@apollo/client";
import {
  UpdateManyRemovableItemDocument,
  UpdateOrderDocument,
} from "/@/generated/graphql";
import { ProductFromGetOrderByUniqueAttributeQuery } from "/@/shared/types/products/products.types";
import { useCreateOrderStore } from "/@/shared/stores/orders/create-store.store";
import { CommentType } from "@jasper/shared";

type CreateRemovableItemPartialDentureClaspsProps = {
  product: ProductFromGetOrderByUniqueAttributeQuery;
  setIsLoading: (isLoading: boolean) => void;
};
export const CreateRemovableItemPartialDentureClasps: FC<
  CreateRemovableItemPartialDentureClaspsProps
> = ({ product, setIsLoading }) => {
  const [updateCreateProductStep, newOrderId] = useCreateOrderStore(state => [
    state.updateCreateProductStep,
    state.newOrderId,
  ]);

  const newRemovableItem = product.removableItem[0];
  const [claspsNotes, setClaspsNotes] = useState(
    newRemovableItem?.dentistNotes ?? ""
  );

  const [updateOrder] = useMutation(UpdateOrderDocument);
  const [updateManyRemovableItem] = useMutation(
    UpdateManyRemovableItemDocument,
    {
      refetchQueries: ["getOrderByUniqueAttribute"],
      awaitRefetchQueries: true,
    }
  );

  if (!product.removableItem || product.removableItem.length <= 0) {
    return (
      <div>
        <Text>
          {"Erreur sur l'ajout de produit. Veuillez contacter le support."}
        </Text>
      </div>
    );
  }

  const onSubmit = async () => {
    setIsLoading(true);
    if (claspsNotes) {
      await updateManyRemovableItem({
        variables: {
          productId: product.id,
          dentistNotes: claspsNotes,
        },
      });
      await updateOrder({
        variables: {
          where: {
            id: newOrderId,
          },
          data: {
            orderComment: {
              create: [
                {
                  comment: claspsNotes,
                  type: CommentType.PARTIAL_DENTURE,
                },
              ],
            },
          },
        },
      });
    }
    updateCreateProductStep(CreateRemovableItemPartialDentureStep.Workflow);
    setIsLoading(false);
  };

  return (
    <Box>
      <Title order={2}>{"Détails concernant les crochets"}</Title>
      <Text
        size="sm"
        style={{ fontWeight: "bold" }}
      >
        {"Notes du praticien (positionnement des crochets, etc.)"}
      </Text>
      <Space h="md" />
      <Textarea
        autosize
        minRows={5}
        maxRows={10}
        color="primary"
        placeholder="Un commentaire?"
        value={claspsNotes}
        onChange={event => setClaspsNotes(event.currentTarget.value)}
      />
      <Space h="xl" />
      <StepsButtons
        handleSubmit={() => onSubmit()}
        setPreviousAction={updateCreateProductStep}
        previousStep={CreateRemovableItemPartialDentureStep.Item}
      />
    </Box>
  );
};
