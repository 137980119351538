import React, { FC } from "react";
import {
  GetOrderByUniqueAttributeQuery,
  GetRemovableItemChoicesQuery,
  GetUserProductPreferencesQuery,
} from "/@/generated/graphql";
import { CreateRemovableItemSplintStep } from "../../../../shared/types/orders/createOrder.types";
import { CreateRemovableItemSplintInfo } from "./CreateRemovableItemSplintInfo";
import { Grid, Space } from "@mantine/core";
import { CreateProductSummarySideBar } from "/@/pages/products/create/CreateProductSummarySideBar";
import { useCreateOrderStore } from "/@/shared/stores/orders/create-store.store";

type CreateRemovableItemSplintProps = {
  product: NonNullable<
    GetOrderByUniqueAttributeQuery["getOrderByUniqueAttribute"]["products"]
  >[0];
  patient: GetOrderByUniqueAttributeQuery["getOrderByUniqueAttribute"]["patient"];
  userProductPreferences: GetUserProductPreferencesQuery;
  removableItemChoicesData: GetRemovableItemChoicesQuery;
  setIsLoading: (isLoading: boolean) => void;
};

export const CreateRemovableItemSplint: FC<CreateRemovableItemSplintProps> = ({
  patient,
  product,
  setIsLoading,
}) => {
  const [isBothJaws, setIsBothJaws] = React.useState<boolean>(false);
  const createProductStep = useCreateOrderStore(
    state => state.createProductStep
  );
  const dataContent = () => {
    switch (createProductStep) {
      case CreateRemovableItemSplintStep.Item:
        return (
          <CreateRemovableItemSplintInfo
            product={product}
            setIsBothJaws={setIsBothJaws}
            setIsLoading={setIsLoading}
          />
        );
      default:
        return <div>ERROR create removable item splint</div>;
    }
  };

  return (
    <Grid
      columns={36}
      gutter={0}
      style={{ height: "100%" }}
    >
      <Grid.Col
        span={
          createProductStep < CreateRemovableItemSplintStep.Review ? 26 : 36
        }
        style={theme => ({
          padding: theme.spacing.lg,
        })}
      >
        {/* <Stepper
          active={createProductStep}
          classNames={{step: classes.stepper, stepBody: classes.stepBody}}
        >
          <Stepper.Step label="Gouttière" />
        </Stepper> */}
        <Space h="xl" />
        {dataContent()}
      </Grid.Col>
      {createProductStep !== CreateRemovableItemSplintStep.Review && (
        <Grid.Col
          span={10}
          style={theme => ({
            padding: theme.spacing.sm,
            backgroundColor: theme.colors.primaryLight[9],
          })}
        >
          <CreateProductSummarySideBar
            product={product}
            patient={patient}
            isBothJaws={isBothJaws}
          />
        </Grid.Col>
      )}
    </Grid>
  );
};
