import {
  Box,
  Button,
  Container,
  PasswordInput,
  Space,
  TextInput,
  Title,
} from "@mantine/core";
import React, { FC } from "react";
import Logo from "/@/assets/images/logo.svg";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { api } from "../../services/api/api";
import { notifications } from "@mantine/notifications";
import { usePostHog } from "posthog-js/react";
import { useAuthContext } from "../../shared/contexts/AuthContext";
import Cookies from "js-cookie";
import packageJson from "../../../package.json";

export const LoginPage: FC = () => {
  type LoginFormData = {
    email: string;
    password: string;
  };

  const { register, handleSubmit } = useForm<LoginFormData>();
  const navigate = useNavigate();
  const { logIn } = useAuthContext();
  const posthog = usePostHog();

  const onSubmit = async (data: LoginFormData) => {
    try {
      data.version = packageJson.version;
      const res = await api.post("auth/login", data);
      if (res.status !== 200 || res.data === "undefined") {
        throw new Error();
      }
      Cookies.remove("Authentication");
      Cookies.remove("Refresh");
      logIn(res.data);
      posthog?.identify(res.data.id, {
        email: res.data.email,
      });
      navigate("/");
    } catch (e) {
      console.error(e);
      notifications.show({
        color: "red",
        title: "Identifiants incorrects",
        message: "",
      });
    }
  };

  return (
    <Container
      fluid
      style={theme => ({
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: theme.colors.primary[8],
      })}
    >
      <Box
        style={theme => ({
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "600px",
          minWidth: "500px",
          backgroundColor: "white",
          borderRadius: theme.radius.md,
        })}
      >
        <img src={Logo} />
        <Space h="xl" />
        <Title order={4}>
          {"Le laboratoire de prothèses"}
          {"\n"} {"nouvelle génération"}
        </Title>
        <Space h="xl" />
        <Box
          style={{
            width: "35%",
            minWidth: "400px",
          }}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextInput
              {...register("email", {
                required: true,
              })}
              label="Adresse email"
              placeholder="hello@gmail.com"
              size="md"
              data-testid="input-email"
            />
            <PasswordInput
              {...register("password", {
                required: true,
              })}
              label="Mot de passe"
              placeholder="Mot de passe"
              mt="md"
              size="md"
              data-testid="input-password"
            />
            <Button
              fullWidth
              mt="xl"
              size="md"
              type="submit"
              color="primary.4"
              mb={40}
              data-testid="submit-login"
            >
              Connexion
            </Button>
          </form>
        </Box>
      </Box>
    </Container>
  );
};
