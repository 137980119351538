import React, { FC } from "react";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { getTeethLabel } from "@jasper/shared";
import { Box, Text } from "@mantine/core";
import { ProductGenericType } from "/@/shared/types/products/products.types";
import { getPartialDentureWorkflowShortLabel } from "/@/shared/utils/products/products.utils";
import { GetOrderByUniqueAttributeQuery, ItemType } from "/@/generated/graphql";

import {
  isAnatomyItemType,
  isImplantItemType,
  isRemovableItemType,
  getItemMultiShadeLabel,
  getItemMaterialLabel,
  getItemInlayCoreHasClavetteLabel,
  getItemShadeLabel,
  getImplantRetentionTypeLabel,
  getItemAbutmentMaterialLabel,
  sortItems,
  getItemAbutmentTypeLabel,
  getItemTypeWithCountLabel,
  getItemInlayCoreMaterialLabel,
  getItemInlayCoreScanPostLabel,
  getAllPartialDentureComments,
  displayPartialDentureComments,
} from "@jasper/shared";

type ItemsDescriptionProps = {
  product: ProductGenericType;
  order?: GetOrderByUniqueAttributeQuery["getOrderByUniqueAttribute"];
};

export const ItemsDescription: FC<ItemsDescriptionProps> = ({
  product,
  order,
}) => {
  if (!product) return null;
  let items = isAnatomyItemType(product.productType)
    ? product.anatomyItem
    : isImplantItemType(product.productType)
      ? product.implantItem
      : isRemovableItemType(product.productType) ||
          product.productType === ItemType.ScanOnly
        ? product.removableItem
        : null;
  if (!items || (Array.isArray(items) && items.length < 1)) return null;
  if (!Array.isArray(items)) {
    items = [items];
  }

  const showItemOrProductTeeths = (
    productType: ItemType,
    itemType: ItemType
  ): boolean => {
    if (isAnatomyItemType(productType)) {
      return true;
    }
    if (isImplantItemType(productType) && itemType !== ItemType.BridgeImplant) {
      return true;
    }
    return false;
  };

  return (
    <Box>
      {sortItems(items, product.productType).map((item: any) => {
        if (item.itemType === ItemType.BridgePontic) {
          return null;
        }
        return (
          <Box
            key={item.id}
            style={{
              textAlign: "start",
            }}
          >
            <Text size="sm">
              {`${getItemTypeWithCountLabel(item)} ${getTeethLabel(
                item.itemType,
                showItemOrProductTeeths(product.productType, item.itemType)
                  ? item.teeth
                  : product.teeth,
                item.teeth
              )}`}
            </Text>
            {item.itemType !== ItemType.InlayCore && (
              <div
                style={{
                  marginLeft: "1rem",
                }}
              >
                {item?.workflowType && (
                  <Text size="sm">{`- ${getPartialDentureWorkflowShortLabel(product)}`}</Text>
                )}
                {item?.itemMaterial && (
                  <Text size="sm">{`- ${getItemMaterialLabel(item)}`}</Text>
                )}
                {item?.itemShade && (
                  <Text size="sm">{`- ${getItemShadeLabel(item)}`}</Text>
                )}
                {item?.teethshadeType === "MULTI_SHADE" && (
                  <>
                    <Text size="sm">- Teinte:</Text>
                    <Text
                      size="sm"
                      style={{
                        whiteSpace: "pre-line",
                        marginLeft: "1rem",
                      }}
                    >{`${getItemMultiShadeLabel(item)}`}</Text>
                  </>
                )}
                {item?.teethShade && (
                  <Text size="sm">{`- ${getItemShadeLabel(item)}`}</Text>
                )}
                {item.itemType === ItemType.PartialDenture &&
                  order &&
                  getAllPartialDentureComments(order).length > 0 && (
                    <Text size="sm">{`- Commentaire sur les crochets: ${displayPartialDentureComments(order)}`}</Text>
                  )}
              </div>
            )}
            <div
              style={{
                marginLeft: "1rem",
              }}
            >
              {getItemInlayCoreMaterialLabel(item) && (
                <Text size="sm">{`- ${getItemInlayCoreMaterialLabel(item)}`}</Text>
              )}
              {getItemInlayCoreHasClavetteLabel(item) && (
                <Text size="sm">{`- ${getItemInlayCoreHasClavetteLabel(item)}`}</Text>
              )}
              {getItemInlayCoreScanPostLabel(item) && (
                <Text size="sm">{`- ${getItemInlayCoreScanPostLabel(item)}`}</Text>
              )}
              {getImplantRetentionTypeLabel(item) && (
                <Text size="sm">{`- ${getImplantRetentionTypeLabel(item)}`}</Text>
              )}
              {getItemAbutmentTypeLabel(item) && (
                <Text size="sm">{`- ${getItemAbutmentTypeLabel(item)}`}</Text>
              )}
              {getItemAbutmentMaterialLabel(item) && (
                <Text size="sm">{`- ${getItemAbutmentMaterialLabel(item)}`}</Text>
              )}
              {item?.itemType === ItemType.Implant &&
                item.implantReferenceSuggestion && (
                  <Text size="sm">{`- Implant reference suggestion : ${item.implantReferenceSuggestion}`}</Text>
                )}
              {item?.itemType === ItemType.Implant &&
                item.scanBodySuggestion && (
                  <Text size="sm">{`- Référence scanbody : ${item.scanBodySuggestion}`}</Text>
                )}
              {item?.itemType === ItemType.Implant && item.implantPlatform && (
                <Text size="sm">{`- Plateforme implantaire : ${item.implantPlatform}`}</Text>
              )}
              {item?.itemType === ItemType.Implant && item.implantReference && (
                <>
                  <Text size="sm">{`- Marque d'implant : ${item.implantReference?.brand}`}</Text>
                  <Text size="sm">{`- Référence : ${item.implantReference?.label}`}</Text>
                </>
              )}
              {item?.itemType === ItemType.Implant &&
                item.standardAbutmentSuggestion && (
                  <Text size="sm">{`- Préférence de pilier standard : ${item.standardAbutmentSuggestion}`}</Text>
                )}
            </div>
          </Box>
        );
      })}
    </Box>
  );
};
