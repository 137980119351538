import { Box, Space, Text, Title } from "@mantine/core";
import React, { FC } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { ButtonsSelect } from "/@/components/ButtonsSelect";
import { StepsButtons } from "/@/components/StepsButtons";
import {
  UpdateManyRemovableItemDocument,
  WorkflowType,
} from "/@/generated/graphql";
import { CreateRemovableItemPartialDentureStep } from "../../../../shared/types/orders/createOrder.types";
import {
  ProductFromGetOrderByUniqueAttributeQuery,
  WorkflowTypeChoices,
} from "/@/shared/types/products/products.types";
import { useMutation } from "@apollo/client";
import { useCreateOrderStore } from "/@/shared/stores/orders/create-store.store";

type PartialDentureWorkflowFormData = {
  workflowType: WorkflowType;
};

type CreateRemovableItemPartialDentureWorkflowProps = {
  product: ProductFromGetOrderByUniqueAttributeQuery;
  setIsLoading: (isLoading: boolean) => void;
};
export const CreateRemovableItemPartialDentureWorkflow: FC<
  CreateRemovableItemPartialDentureWorkflowProps
> = ({ product, setIsLoading }) => {
  const [updateCreateProductStep, incrementCreateProductIndex] =
    useCreateOrderStore(state => [
      state.updateCreateProductStep,
      state.incrementCreateProductIndex,
    ]);

  const newRemovableItem = product.removableItem[0];

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<PartialDentureWorkflowFormData>({
    defaultValues: {
      workflowType: newRemovableItem?.workflowType,
    },
  });

  const [updateManyRemovableItem] = useMutation(
    UpdateManyRemovableItemDocument,
    {
      refetchQueries: ["getOrderByUniqueAttribute"],
      awaitRefetchQueries: true,
    }
  );

  if (!product.removableItem || product.removableItem.length <= 0) {
    return (
      <div>
        <Text>
          {"Erreur sur l'ajout de produit. Veuillez contacter le support."}
        </Text>
      </div>
    );
  }

  const onSubmit: SubmitHandler<
    PartialDentureWorkflowFormData
  > = async data => {
    setIsLoading(true);
    await updateManyRemovableItem({
      variables: {
        productId: product.id,
        workflowType: data.workflowType,
      },
    });
    incrementCreateProductIndex();
    setIsLoading(false);
  };

  return (
    <Box>
      <Title order={2}>{"Flux de travail"}</Title>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Text
          size="sm"
          style={{ fontWeight: "bold" }}
        >
          {"Comment souhaitez-vous procéder ?"}
        </Text>
        <Controller
          name="workflowType"
          rules={{ required: true }}
          control={control}
          render={({ field }) => (
            <ButtonsSelect
              data={WorkflowTypeChoices}
              value={field.value}
              align="left"
              numberOfColumns={1}
              onChange={value => field.onChange(value)}
            />
          )}
        />
        {errors?.workflowType?.type === "required" && (
          <Text
            size="sm"
            style={{ color: "red" }}
          >
            Veuillez choisir un type de flux de travail
          </Text>
        )}
        <Space h="xl" />
        <StepsButtons
          handleSubmit={handleSubmit(onSubmit)}
          setPreviousAction={updateCreateProductStep}
          previousStep={CreateRemovableItemPartialDentureStep.Clasps}
        />
      </form>
    </Box>
  );
};
