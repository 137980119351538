import { Box, Space, Text, Title } from "@mantine/core";
import React, { FC, useState, useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { ButtonsSelect } from "/@/components/ButtonsSelect";
import { StepsButtons } from "/@/components/StepsButtons";
import { CreateRemovableItemPartialDentureStep } from "../../../../shared/types/orders/createOrder.types";
import DentalArchesSvg from "/@/pages/orders/DentalArchesSvg";
import {
  ProductFromGetOrderByUniqueAttributeQuery,
  yesNoChoices,
} from "/@/shared/types/products/products.types";
import { useMutation } from "@apollo/client";
import {
  DeleteManyRemovableItemDocument,
  CreateRemovableItemDocument,
  ItemType,
  UpdateProductDocument,
} from "/@/generated/graphql";
import { useCreateOrderStore } from "/@/shared/stores/orders/create-store.store";
import { notifications } from "@mantine/notifications";

import {
  getItemTypeLabel,
  getTeethsFromUpperArch,
  getTeethsFromLowerArch,
} from "@jasper/shared";

type PartialDentureTeethToManufactureFormData = {
  isReplacement: string;
};

type CreateRemovableItemPartialDentureTeethToManufactureProps = {
  product: ProductFromGetOrderByUniqueAttributeQuery;
  setIsLoading: (isLoading: boolean) => void;
};
export const CreateRemovableItemPartialDentureTeethToManufacture: FC<
  CreateRemovableItemPartialDentureTeethToManufactureProps
> = ({ product, setIsLoading }) => {
  const [
    createProductIndex,
    updateCreateProductIndex,
    updateCreateProductStep,
  ] = useCreateOrderStore(state => [
    state.createProductIndex,
    state.updateCreateProductIndex,
    state.updateCreateProductStep,
  ]);

  const newRemovableItem = product.removableItem[0];

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<PartialDentureTeethToManufactureFormData>({
    defaultValues: {
      isReplacement:
        newRemovableItem?.isReplacement !== "undefined"
          ? newRemovableItem?.isReplacement
          : null,
    },
  });

  const [createRemovableItem] = useMutation(CreateRemovableItemDocument, {
    refetchQueries: ["getOrderByUniqueAttribute"],
    awaitRefetchQueries: true,
  });

  const [updateProduct] = useMutation(UpdateProductDocument);

  const [deleteManyRemovableItem] = useMutation(
    DeleteManyRemovableItemDocument
  );

  const [teethToManufacture, setTeethToManufacture] = useState<number[]>([]);

  useEffect(() => {
    const allTeethTwoManufacture = (product?.removableItem ?? []).reduce(
      (acc, removableItem) => {
        if (removableItem?.teethToManufacture) {
          acc.push(...(removableItem?.teethToManufacture ?? []));
        }
        return acc;
      },
      []
    );
    if (allTeethTwoManufacture) {
      setTeethToManufacture(allTeethTwoManufacture);
    }
  }, [false]);

  if (!product.removableItem || product.removableItem.length <= 0) {
    return (
      <div>
        <Text>
          {"Erreur sur l'ajout de produit. Veuillez contacter le support."}
        </Text>
      </div>
    );
  }

  const onSubmit: SubmitHandler<
    PartialDentureTeethToManufactureFormData
  > = async data => {
    if (teethToManufacture.length === 0) {
      notifications.show({
        title: "Veuillez selectionner les dents à inclure dans la PAP",
        color: "red",
        message: "",
      });
      return;
    }
    setIsLoading(true);
    await deleteManyRemovableItem({
      variables: {
        where: {
          productId: {
            equals: product.id,
          },
        },
      },
    });
    await updateProduct({
      variables: {
        where: {
          id: product.id,
        },
        data: {
          teeth: teethToManufacture,
        },
      },
    });
    const teethsFromLowerArch = getTeethsFromLowerArch(teethToManufacture);
    const teethsFromUpperArch = getTeethsFromUpperArch(teethToManufacture);
    if (teethsFromLowerArch && teethsFromLowerArch.length > 0) {
      await createRemovableItem({
        variables: {
          args: {
            product: {
              connect: {
                id: product.id,
              },
            },
            teeth: teethsFromLowerArch,
            itemType: ItemType.PartialDenture,
            teethToManufacture: {
              set: teethsFromLowerArch,
            },
            isReplacement: data.isReplacement,
            teethShade: newRemovableItem?.teethShade?.id
              ? {
                  connect: {
                    id: newRemovableItem.teethShade?.id,
                  },
                }
              : undefined,
            gingivaShade: newRemovableItem?.gingivaShade?.id
              ? {
                  connect: {
                    id: newRemovableItem.gingivaShade?.id,
                  },
                }
              : undefined,
            itemMaterial: newRemovableItem?.itemMaterial?.id
              ? {
                  connect: {
                    id: newRemovableItem.itemMaterial?.id,
                  },
                }
              : undefined,
            dentistNotes: newRemovableItem?.dentistNotes,
            workflowType: newRemovableItem?.workflowType,
          },
        },
      });
    }
    if (teethsFromUpperArch && teethsFromUpperArch.length > 0) {
      await createRemovableItem({
        variables: {
          args: {
            product: {
              connect: {
                id: product.id,
              },
            },
            teeth: teethsFromUpperArch,
            itemType: ItemType.PartialDenture,
            teethToManufacture: {
              set: teethsFromUpperArch,
            },
            isReplacement: data.isReplacement,
            teethShade: newRemovableItem?.teethShade?.id
              ? {
                  connect: {
                    id: newRemovableItem.teethShade?.id,
                  },
                }
              : undefined,
            gingivaShade: newRemovableItem?.gingivaShade?.id
              ? {
                  connect: {
                    id: newRemovableItem.gingivaShade?.id,
                  },
                }
              : undefined,
            itemMaterial: newRemovableItem?.itemMaterial?.id
              ? {
                  connect: {
                    id: newRemovableItem.itemMaterial?.id,
                  },
                }
              : undefined,
            dentistNotes: newRemovableItem?.dentistNotes,
            workflowType: newRemovableItem?.workflowType,
          },
        },
      });
    }
    setIsLoading(false);
    updateCreateProductStep(CreateRemovableItemPartialDentureStep.Item);
  };

  return (
    <Box>
      <Title
        order={2}
      >{`Information sur votre ${getItemTypeLabel(product.productType)?.toLowerCase()}`}</Title>
      <Space h="xl" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Text
          size="sm"
          style={{ fontWeight: "bold" }}
        >
          {"S'agit-il d'un remplacement de prothèse ?"}
        </Text>
        <Controller
          name="isReplacement"
          rules={{
            validate: value => value !== undefined,
          }}
          control={control}
          render={({ field }) => (
            <ButtonsSelect
              data={yesNoChoices}
              value={field.value}
              onChange={value => field.onChange(value)}
            />
          )}
        />
        {errors?.isReplacement?.type === "required" && (
          <Text
            size="sm"
            style={{ color: "red" }}
          >
            {
              "Veuillez indiquer s'il s'agit d'un remplacement de prothèse adjointe"
            }
          </Text>
        )}
        <Space h="md" />{" "}
        <Text
          size="sm"
          style={{ fontWeight: "bold" }}
        >
          {"Sélectionnez les dents à inclure dans la PAP"}
        </Text>
        <Space h="xl" />
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <DentalArchesSvg
            teeth={teethToManufacture}
            width={300}
            height={400}
            onClick={(tooth: number) =>
              teethToManufacture.includes(tooth)
                ? setTeethToManufacture(
                    [...teethToManufacture].filter(t => t !== tooth)
                  )
                : setTeethToManufacture([...teethToManufacture, tooth])
            }
          />
        </Box>
        <StepsButtons
          handleSubmit={handleSubmit(onSubmit)}
          setPreviousAction={updateCreateProductIndex}
          previousStep={createProductIndex - 1}
          isFirst={createProductIndex < 1 ? true : false}
        />
      </form>
    </Box>
  );
};
