import { Box, Title } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import React, { FC } from "react";
import { GetManyOrdersOfUserDocument, OrderStatus } from "/@/generated/graphql";
import { useQuery } from "@apollo/client";
import { OrderSummaryRow } from "/@/components/order/OrderSummaryRow";

export const NotificationsListPage: FC = () => {
  const { data: getDraftOrdersData } = useQuery(GetManyOrdersOfUserDocument, {
    fetchPolicy: "network-only",
    variables: {
      where: {
        status: {
          equals: OrderStatus.Draft,
        },
      },
    },
  });

  const { classes } = useStyles();

  if (!getDraftOrdersData) {
    return null;
  } else if (getDraftOrdersData.getManyOrdersOfUser.length === 0) {
    return (
      <Box
        style={theme => ({
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          backgroundColor: theme.colors.greyScale[9],
        })}
      >
        <Title
          order={3}
          style={theme => ({
            color: theme.colors.greyScale[2],
          })}
        >
          {"Aucune notification"}
        </Title>
      </Box>
    );
  }
  return (
    <>
      <Box className={classes.container}>
        <Title
          order={2}
          style={theme => ({
            color: theme.colors.black,
          })}
        >
          Vos notifications
        </Title>
        <>
          {getDraftOrdersData.getManyOrdersOfUser
            .slice(0)
            .reverse()
            .map(order => {
              const orderDate = new Date(order.createdAt);
              const expectedDeliveryDate = new Date();
              expectedDeliveryDate.setDate(orderDate.getDate() + 8);
              return (
                <OrderSummaryRow
                  key={order.id}
                  order={order}
                />
              );
            })}
        </>
      </Box>
    </>
  );
};

const useStyles = createStyles(theme => ({
  container: {
    padding: theme.spacing.lg,
  },
  row: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: "5rem",
    margin: `${theme.spacing.md}px 0px`,
    borderRadius: theme.radius.md,
    border: `1px solid ${theme.colors.greyScale[8]}`,
    padding: `0px ${theme.spacing.lg}px`,
    cursor: "pointer",
  },
  stepper: {
    width: "70%",
  },
  stepperText: {
    fontSize: theme.fontSizes.xs,
  },
  stepperLine: {
    backgroundColor: theme.colors.greyScale[8],
  },
  stepperLineActive: {
    backgroundColor: theme.colors.primary[4],
  },
}));
