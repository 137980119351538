import {
  Box,
  Button,
  Loader,
  LoadingOverlay,
  Select,
  Space,
  Text,
  Title,
} from "@mantine/core";
import React, { FC, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { ButtonsSelect } from "/@/components/ButtonsSelect";
import { StepsButtons } from "/@/components/StepsButtons";
import {
  BridgeType,
  GetAnatomyItemChoicesQuery,
  GetScanPostsDataDocument,
  ItemType,
  CreateAnatomyItemDocument,
  DeleteManyAnatomyItemDocument,
  GetAccountProductPreferencesDocument,
  GetOrderByUniqueAttributeQuery,
  TeethShadeType,
  TeethShadeSide,
  MultiShadeInfo,
} from "/@/generated/graphql";
import {
  LayType,
  ProductFromGetOrderByUniqueAttributeQuery,
  yesNoChoices,
} from "../../../../shared/types/products/products.types";
import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { CreateAnatomyItemStep } from "/@/shared/types/orders/createOrder.types";
import client from "/@/apolloClient";
import { useCreateOrderStore } from "/@/shared/stores/orders/create-store.store";
import { useAuthContext } from "../../../../shared/contexts/AuthContext";

import {
  getItemTypeLabel,
  FixedProsthesisShadeType,
  findUserPreference,
  filterShadeByShadeType,
  formatChoicesData,
  getAnatomyItemMaterialChoices,
  getAllPossibleSplintedCrown,
  getAllNonSplintedCrown,
  isBridgeOnInlayCore,
  removeTeethFromBridgeOnInlayCore,
  MultiShadeForm,
} from "@jasper/shared";

import { INLAY_CORE_SCAN_POST } from "@jasper/shared/utils/item.utils";

type AnatomyItemFormData = {
  material: string;
  shade?: string;
  layType?: ItemType;
  bridgeType?: BridgeType;
  isInlayCoreClaveted?: boolean;
  isInlayCoreOnly: boolean;
  isRichmondCrown: boolean;
  inlayCoreScanPostType?: string;
  inlayCoreScanPost?: string;
  inlayCoreMaterial?: string;
  isSplintedCrown?: boolean;
  isMultiShade?: boolean;
};

type CreateOrderAnatomyItemInfoProps = {
  product: NonNullable<ProductFromGetOrderByUniqueAttributeQuery>;
  allProducts: NonNullable<
    GetOrderByUniqueAttributeQuery["getOrderByUniqueAttribute"]["products"]
  >;
  anatomyItemChoicesData: GetAnatomyItemChoicesQuery;
  providedAnatomyItem?: NonNullable<
    ProductFromGetOrderByUniqueAttributeQuery["anatomyItem"]
  >[0];
  setItemStatusTable?: React.Dispatch<React.SetStateAction<string[]>>;
  order: GetOrderByUniqueAttributeQuery["getOrderByUniqueAttribute"];
  hasIndividualItemCaracteristics: boolean;
  setIsLoading: (isLoading: boolean) => void;
};

export const CreateAnatomyItemInfoForm: FC<CreateOrderAnatomyItemInfoProps> = ({
  product,
  anatomyItemChoicesData,
  providedAnatomyItem,
  setItemStatusTable,
  allProducts,
  order,
  hasIndividualItemCaracteristics,
  setIsLoading,
}) => {
  if (!product.anatomyItem || product.anatomyItem.length <= 0) {
    return (
      <div>
        <Text>
          {"Erreur sur l'ajout de produit. Veuillez contacter le support."}
        </Text>
      </div>
    );
  }

  const newAnatomyItem = providedAnatomyItem ?? product.anatomyItem[0];
  const isNotBridgeOnInlayCore =
    !isBridgeOnInlayCore(allProducts, product) ||
    product.productType === ItemType.Bridge;

  const { user } = useAuthContext();

  const { data: getScanPostsData, loading: getScanPostsDataLoading } = useQuery(
    GetScanPostsDataDocument
  );
  const {
    data: accountProductPreferences,
    loading: accountProductPreferencesLoading,
  } = useQuery(GetAccountProductPreferencesDocument);
  const [
    createProductIndex,
    updateCreateProductIndex,
    updateCreateProductStep,
  ] = useCreateOrderStore(state => [
    state.createProductIndex,
    state.updateCreateProductIndex,
    state.updateCreateProductStep,
  ]);

  const {
    control,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<AnatomyItemFormData>({
    defaultValues: {
      material: newAnatomyItem.itemMaterial?.id ?? undefined,
      inlayCoreMaterial: newAnatomyItem.inlayCoreMaterial?.id ?? undefined,
      shade: newAnatomyItem.itemShade?.id ?? undefined,
      inlayCoreScanPost: newAnatomyItem.inlayCoreScanPost ?? undefined,
      layType: product.productType === "LAY" ? ItemType.Inlay : undefined,
      isSplintedCrown: false,
      isInlayCoreClaveted: newAnatomyItem?.inlayCoreHasClavette ?? false,
      isInlayCoreOnly:
        product.productType === ItemType.InlayCore &&
        !(
          (product.anatomyItem ?? []).find(
            (item: AnatomyItem) => item.itemType === ItemType.Crown
          ) && !isNotBridgeOnInlayCore
        ) &&
        (product.anatomyItem ?? []).find(
          (item: AnatomyItem) =>
            item.itemType === ItemType.InlayCore &&
            item?.inlayCoreMaterial &&
            !item.itemShade &&
            item.itemMaterial?.id !== "METAL_NON_PRECIOUS"
        )
          ? true
          : false,
      isRichmondCrown:
        newAnatomyItem.itemType === ItemType.RichmondCrown ? true : false,
      inlayCoreScanPostType:
        newAnatomyItem?.inlayCoreScanPost?.split("_")[0] || undefined,
      isMultiShade:
        newAnatomyItem.teethshadeType === TeethShadeType.MultiShade
          ? true
          : false,
      gingivalShade:
        (newAnatomyItem.multiShadeInfo ?? []).find(
          (multiShadeInfo: MultiShadeInfo) =>
            multiShadeInfo.teethShadeSide === TeethShadeSide.Gingival
        )?.itemShade?.id ?? undefined,
      baseShade:
        (newAnatomyItem.multiShadeInfo ?? []).find(
          (multiShadeInfo: MultiShadeInfo) =>
            multiShadeInfo.teethShadeSide === TeethShadeSide.Base
        )?.itemShade?.id ?? undefined,
      incisalShade:
        (newAnatomyItem.multiShadeInfo ?? []).find(
          (multiShadeInfo: MultiShadeInfo) =>
            multiShadeInfo.teethShadeSide === TeethShadeSide.Incisal
        )?.itemShade?.id ?? undefined,
      shadeType:
        findUserPreference(
          order?.user?.id,
          accountProductPreferences?.getAccountProductPreferences
        )?.shadeType ?? FixedProsthesisShadeType.VITA_CLASSIC,
    },
  });
  const watchMaterial = watch("material");
  const watchIsInlayCoreClaveted = watch("isInlayCoreClaveted");
  const watchIsInlayCoreOnly = watch("isInlayCoreOnly");
  const watchInlayCoreScanPostType = watch("inlayCoreScanPostType");
  const watchIsSplintedCrown = watch("isSplintedCrown");
  const watchIsRichmondCrown = watch("isRichmondCrown");
  const watchIsMultiShade = watch("isMultiShade");
  const watchShadeType = watch("shadeType");
  const watchGingivalShade = watch("gingivalShade");
  const watchBaseShade = watch("baseShade");
  const watchIncisalShade = watch("incisalShade");

  const [shadeFormChoices, setShadeFormChoice] = useState(
    filterShadeByShadeType(
      anatomyItemChoicesData?.getItemShadesWhere ?? [],
      watchShadeType
    )
  );

  const [createAnatomyItem] = useMutation(CreateAnatomyItemDocument);
  const [deleteManyAnatomyItem] = useMutation(DeleteManyAnatomyItemDocument);

  useEffect(() => {
    setShadeFormChoice(
      filterShadeByShadeType(
        anatomyItemChoicesData?.getItemShadesWhere,
        watchShadeType
      )
    );
  }, [watchShadeType]);

  useEffect(() => {
    const shadeType = findUserPreference(
      order?.user?.id,
      accountProductPreferences?.getAccountProductPreferences
    )?.shadeType;
    if (shadeType && shadeType !== watchShadeType) {
      setValue("shadeType", shadeType);
    }
  }, [accountProductPreferences?.getAccountProductPreferences]);

  // Refactoring shade form choices for select component
  const inlayCoreMaterialFormChoices = formatChoicesData(
    anatomyItemChoicesData.getItemMaterialsWhere.filter(
      (m: any) => m.inlayCore === true
    ),
    watchIsInlayCoreClaveted
  );

  const materialFormChoices = anatomyItemChoicesData
    ? getAnatomyItemMaterialChoices(anatomyItemChoicesData, product.productType)
    : [];

  const deduceItemTypeFromOptions = (
    data: AnatomyItemFormData,
    product: ProductFromGetOrderByUniqueAttributeQuery
  ) => {
    if (data.isInlayCoreOnly) {
      return ItemType.InlayCore;
    }
    if (data.isRichmondCrown) {
      return ItemType.RichmondCrown;
    }
    if (product.productType === ItemType.Lay) {
      return data.layType ?? product.productType;
    }
    return product.productType ?? undefined;
  };

  const onSubmit: SubmitHandler<AnatomyItemFormData> = async data => {
    setIsLoading(true);
    const allNonPossibleSplintedCrown: number[] =
      removeTeethFromBridgeOnInlayCore(
        getAllNonSplintedCrown(product.teeth),
        allProducts
      );
    const allSplinteCrown: number[][] = getAllPossibleSplintedCrown(
      product.teeth
    );
    await deleteManyAnatomyItem({
      variables: {
        where: {
          productId: {
            equals: product.id,
          },
          teeth: hasIndividualItemCaracteristics
            ? {
                equals: newAnatomyItem.teeth,
              }
            : undefined,
        },
      },
    });
    const productToUpdate = [];
    if (
      data.isSplintedCrown &&
      !data.isInlayCoreOnly &&
      isNotBridgeOnInlayCore
    ) {
      allSplinteCrown.map(splintedCrown => {
        const splintedCrownTeeth = removeTeethFromBridgeOnInlayCore(
          splintedCrown,
          allProducts
        );
        if (splintedCrownTeeth.length > 0) {
          productToUpdate.push({
            inlayCoreHasClavette: data.isInlayCoreClaveted,
            inlayCoreScanPost:
              data.inlayCoreScanPost ?? watchInlayCoreScanPostType,
            bridgeType: data.bridgeType ?? undefined,
            inlayCoreMaterial: data.inlayCoreMaterial
              ? {
                  connect: {
                    id: data.inlayCoreMaterial,
                  },
                }
              : undefined,
            itemMaterial:
              data.material && !watchIsInlayCoreOnly
                ? {
                    connect: {
                      id: data.material,
                    },
                  }
                : undefined,
            itemShade:
              data.shade && !watchIsMultiShade
                ? {
                    connect: {
                      id: data.shade,
                    },
                  }
                : undefined,
            itemType: ItemType.SplintedCrown,
            teeth: splintedCrownTeeth,
            product: {
              connect: {
                id: product.id,
              },
            },
            teethshadeType: watchIsMultiShade
              ? TeethShadeType.MultiShade
              : TeethShadeType.SingleShade,
            multiShadeInfo: watchIsMultiShade
              ? {
                  createMany: {
                    data: [
                      ...(data.gingivalShade
                        ? [
                            {
                              itemShadeId: data.gingivalShade,
                              teethShadeSide: TeethShadeSide.Gingival,
                            },
                          ]
                        : []),
                      ...(data.baseShade
                        ? [
                            {
                              itemShadeId: data.baseShade,
                              teethShadeSide: TeethShadeSide.Base,
                            },
                          ]
                        : []),
                      ...(data.incisalShade
                        ? [
                            {
                              itemShadeId: data.incisalShade,
                              teethShadeSide: TeethShadeSide.Incisal,
                            },
                          ]
                        : []),
                    ],
                  },
                }
              : undefined,
          });
        }
      });
      if (allNonPossibleSplintedCrown.length > 0 && isNotBridgeOnInlayCore) {
        productToUpdate.push({
          inlayCoreHasClavette: data.isInlayCoreClaveted,
          inlayCoreMaterial: data.inlayCoreMaterial
            ? {
                connect: {
                  id: data.inlayCoreMaterial,
                },
              }
            : undefined,
          itemShade:
            data.shade && !watchIsMultiShade
              ? {
                  connect: {
                    id: data.shade,
                  },
                }
              : undefined,
          inlayCoreScanPost:
            data.inlayCoreScanPost ?? watchInlayCoreScanPostType,
          itemType: ItemType.Crown ?? undefined,
          bridgeType: data.bridgeType ?? undefined,
          teeth: allNonPossibleSplintedCrown,
          itemMaterial:
            data.material && !watchIsInlayCoreOnly
              ? {
                  connect: {
                    id: data.material,
                  },
                }
              : undefined,
          product: {
            connect: {
              id: product.id,
            },
          },
          teethshadeType: watchIsMultiShade
            ? TeethShadeType.MultiShade
            : TeethShadeType.SingleShade,
          multiShadeInfo: watchIsMultiShade
            ? {
                createMany: {
                  data: [
                    ...(data.gingivalShade
                      ? [
                          {
                            itemShadeId: data.gingivalShade,
                            teethShadeSide: TeethShadeSide.Gingival,
                          },
                        ]
                      : []),
                    ...(data.baseShade
                      ? [
                          {
                            itemShadeId: data.baseShade,
                            teethShadeSide: TeethShadeSide.Base,
                          },
                        ]
                      : []),
                    ...(data.incisalShade
                      ? [
                          {
                            itemShadeId: data.incisalShade,
                            teethShadeSide: TeethShadeSide.incisal,
                          },
                        ]
                      : []),
                  ],
                },
              }
            : undefined,
        });
      }
    }
    const itemTypeToUpdate = deduceItemTypeFromOptions(data, product);
    if (product.productType === ItemType.Crown ? !data.isSplintedCrown : true) {
      productToUpdate.push({
        inlayCoreHasClavette: data.isInlayCoreClaveted,
        inlayCoreMaterial: data.inlayCoreMaterial
          ? {
              connect: {
                id: data.inlayCoreMaterial,
              },
            }
          : undefined,
        itemShade:
          data.shade &&
          !watchIsMultiShade &&
          data.material !== "METAL_NON_PRECIOUS"
            ? {
                connect: {
                  id: data.shade,
                },
              }
            : undefined,
        inlayCoreScanPost: data.inlayCoreScanPost ?? watchInlayCoreScanPostType,
        itemType: itemTypeToUpdate ?? undefined,
        bridgeType: data.bridgeType ?? undefined,
        teeth: hasIndividualItemCaracteristics
          ? newAnatomyItem.teeth
          : product.teeth,
        itemMaterial:
          data.material && !watchIsInlayCoreOnly
            ? {
                connect: {
                  id: data.material,
                },
              }
            : undefined,
        product: {
          connect: {
            id: product.id,
          },
        },
        teethshadeType:
          watchIsMultiShade && data.material !== "METAL_NON_PRECIOUS"
            ? TeethShadeType.MultiShade
            : TeethShadeType.SingleShade,
        multiShadeInfo:
          watchIsMultiShade && data.material !== "METAL_NON_PRECIOUS"
            ? {
                createMany: {
                  data: [
                    ...(data.gingivalShade
                      ? [
                          {
                            itemShadeId: data.gingivalShade,
                            teethShadeSide: TeethShadeSide.Gingival,
                          },
                        ]
                      : []),
                    ...(data.baseShade
                      ? [
                          {
                            itemShadeId: data.baseShade,
                            teethShadeSide: TeethShadeSide.Base,
                          },
                        ]
                      : []),
                    ...(data.incisalShade
                      ? [
                          {
                            itemShadeId: data.incisalShade,
                            teethShadeSide: TeethShadeSide.Incisal,
                          },
                        ]
                      : []),
                  ],
                },
              }
            : undefined,
      });
    }
    const crownTeeths = removeTeethFromBridgeOnInlayCore(
      hasIndividualItemCaracteristics ? newAnatomyItem.teeth : product.teeth,
      allProducts
    );
    if (
      product.productType === ItemType.InlayCore &&
      !data.isInlayCoreOnly &&
      !data.isRichmondCrown &&
      !data.isSplintedCrown &&
      isNotBridgeOnInlayCore &&
      crownTeeths.length > 0
    ) {
      productToUpdate.push({
        inlayCoreHasClavette: data.isInlayCoreClaveted,
        inlayCoreScanPost: data.inlayCoreScanPost ?? watchInlayCoreScanPostType,
        bridgeType: data.bridgeType ?? undefined,
        inlayCoreMaterial: data.inlayCoreMaterial
          ? {
              connect: {
                id: data.inlayCoreMaterial,
              },
            }
          : undefined,
        itemMaterial:
          data.material && !watchIsInlayCoreOnly
            ? {
                connect: {
                  id: data.material,
                },
              }
            : undefined,
        itemShade:
          data.shade &&
          !watchIsMultiShade &&
          data.material !== "METAL_NON_PRECIOUS"
            ? {
                connect: {
                  id: data.shade,
                },
              }
            : undefined,
        itemType: ItemType.Crown,
        teeth: crownTeeths,
        product: {
          connect: {
            id: product.id,
          },
        },
        teethshadeType:
          watchIsMultiShade && data.material !== "METAL_NON_PRECIOUS"
            ? TeethShadeType.MultiShade
            : TeethShadeType.SingleShade,
        multiShadeInfo:
          watchIsMultiShade && data.material !== "METAL_NON_PRECIOUS"
            ? {
                createMany: {
                  data: [
                    ...(data.gingivalShade
                      ? [
                          {
                            itemShadeId: data.gingivalShade,
                            teethShadeSide: TeethShadeSide.Gingival,
                          },
                        ]
                      : []),
                    ...(data.baseShade
                      ? [
                          {
                            itemShadeId: data.baseShade,
                            teethShadeSide: TeethShadeSide.Base,
                          },
                        ]
                      : []),
                    ...(data.incisalShade
                      ? [
                          {
                            itemShadeId: data.incisalShade,
                            teethShadeSide: TeethShadeSide.Incisal,
                          },
                        ]
                      : []),
                  ],
                },
              }
            : undefined,
      });
    }
    await productToUpdate.map(async item => {
      await createAnatomyItem({
        variables: {
          args: item,
        },
      });
    });
    try {
      await client.refetchQueries({
        include: ["getOrderByUniqueAttribute"],
      });

      if (setItemStatusTable) {
        setItemStatusTable((prevState: string[]) => {
          return prevState.filter(id => id !== newAnatomyItem.id);
        });
      }

      if (!product.hasIndividualItemCaracteristics) {
        await client.refetchQueries({
          include: ["getOrderByUniqueAttribute"],
        });
        setIsLoading(false);
        updateCreateProductStep(CreateAnatomyItemStep.Preferences);
      }
    } catch (error) {
      if (error instanceof ApolloError) {
        setIsLoading(false);
        console.log("error", error);
      }
    }
  };

  if (getScanPostsDataLoading || accountProductPreferencesLoading)
    return (
      <LoadingOverlay
        visible={getScanPostsDataLoading || accountProductPreferencesLoading}
        zIndex={1000}
        overlayProps={{
          radius: "sm",
          blur: 2,
        }}
      >
        <Loader />
      </LoadingOverlay>
    );
  if (
    !anatomyItemChoicesData ||
    (!getScanPostsData && !getScanPostsDataLoading)
  ) {
    return (
      <div>
        <Text>
          {
            "Erreur sur l'ajout d'item dans votre commande. Veuillez contacter le support."
          }
        </Text>
      </div>
    );
  }

  return (
    <Box>
      {!product.hasIndividualItemCaracteristics && (
        <>
          <Title order={2}>
            {`Matériau et teinte - ${getItemTypeLabel(product.productType)}`}{" "}
          </Title>
          <Space h="md" />
        </>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        {product.productType === ItemType.Lay && (
          <>
            <Controller
              name="layType"
              rules={{ required: true }}
              control={control}
              render={({ field }) => (
                <ButtonsSelect
                  data={LayType}
                  value={field.value}
                  onChange={value => field.onChange(value)}
                />
              )}
            />
            {errors?.layType?.type === "required" && (
              <Text
                size="sm"
                style={{ color: "red" }}
              >
                Veuillez choisir un type de produit
              </Text>
            )}
          </>
        )}
        {(product.productType === ItemType.InlayCore ||
          product.productType === ItemType.PostAndCoreRetainedCrown) && (
          <>
            {product.productType === ItemType.InlayCore && (
              <>
                <Text
                  size="sm"
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  {"Inlay-Core Claveté?"}
                </Text>
                <Controller
                  name="isInlayCoreClaveted"
                  rules={{
                    validate: value => value !== undefined,
                  }}
                  control={control}
                  render={({ field }) => (
                    <ButtonsSelect
                      data={yesNoChoices}
                      value={field.value}
                      onChange={value => field.onChange(value)}
                    />
                  )}
                />
                {errors?.isInlayCoreClaveted?.type === "required" && (
                  <Text
                    size="sm"
                    style={{
                      color: "red",
                    }}
                  >
                    Veuillez choisir si votre Inlay-Core est claveté
                  </Text>
                )}
                <Space h="md" />
                {isNotBridgeOnInlayCore && (
                  <>
                    <Text
                      size="sm"
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {"Inlay-Core seulement (sans couronne)?"}
                    </Text>
                    <Controller
                      name="isInlayCoreOnly"
                      rules={{
                        validate: value => value !== undefined,
                      }}
                      control={control}
                      render={({ field }) => (
                        <ButtonsSelect
                          data={yesNoChoices}
                          value={field.value}
                          onChange={value => field.onChange(value)}
                        />
                      )}
                    />
                    {errors?.isInlayCoreOnly?.type === "required" && (
                      <Text
                        size="sm"
                        style={{
                          color: "red",
                        }}
                      >
                        {
                          "Veuillez choisir si il s'agit d'un Inlay-Core uniquement sans couronne"
                        }
                      </Text>
                    )}
                    <Space h="md" />
                  </>
                )}
                {!watchIsInlayCoreOnly &&
                  !watchIsSplintedCrown &&
                  isNotBridgeOnInlayCore && (
                    <>
                      <Text
                        size="sm"
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        {"Fabrication d’une couronne Richmond?"}
                      </Text>
                      <Controller
                        name="isRichmondCrown"
                        rules={{
                          validate: value => value !== undefined,
                        }}
                        control={control}
                        render={({ field }) => (
                          <ButtonsSelect
                            data={yesNoChoices}
                            value={field.value}
                            onChange={value => field.onChange(value)}
                          />
                        )}
                      />
                      {errors?.isRichmondCrown?.type === "required" && (
                        <Text
                          size="sm"
                          style={{
                            color: "red",
                          }}
                        >
                          {"Veuillez choisir si il s'agit d'un Richmond Crown"}
                        </Text>
                      )}
                      <Space h="md" />
                    </>
                  )}
              </>
            )}
            <Text
              size="sm"
              style={{
                fontWeight: "bold",
              }}
            >
              {"Choisissez votre type de Scan Post"}
            </Text>
            <Controller
              name="inlayCoreScanPostType"
              rules={{ required: true }}
              control={control}
              render={({ field }) => (
                <ButtonsSelect
                  data={
                    getScanPostsData?.getScanPostsData.types ??
                    newAnatomyItem.inlayCoreScanPost
                  }
                  value={field.value}
                  onChange={value => field.onChange(value)}
                />
              )}
            />
            {errors?.inlayCoreScanPostType?.type === "required" && (
              <Text
                size="sm"
                style={{ color: "red" }}
              >
                Veuillez choisir le type scan post utilisé
              </Text>
            )}
            <Space h="md" />
            {watchInlayCoreScanPostType !==
              INLAY_CORE_SCAN_POST.ANATOMICAL.value && (
              <>
                <Text
                  size="sm"
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  {"Choisissez le scan post utilisé"}
                </Text>
                <Text
                  style={{
                    fontStyle: "italic",
                  }}
                  size="xs"
                >
                  Scan post court: 12mm de longueur, scan post long: 16mm de
                  longueur
                </Text>
                <Controller
                  name="inlayCoreScanPost"
                  rules={{
                    required: true,
                  }}
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      data={
                        watchInlayCoreScanPostType
                          ? (
                              getScanPostsData?.getScanPostsData.scanPosts as {
                                type: string;
                                value: string;
                                label: string;
                              }[]
                            ).filter(
                              scanPost =>
                                scanPost.type === watchInlayCoreScanPostType
                            )
                          : getScanPostsData?.getScanPostsData.scanPosts
                      }
                      placeholder="Choisissez le scan post utilisé"
                      data-testid="select-inlay-core-scan-post"
                    />
                  )}
                />
                {errors?.inlayCoreScanPost?.type === "required" && (
                  <Text
                    size="sm"
                    style={{
                      color: "red",
                    }}
                  >
                    Veuillez choisir le scan post utilisé
                  </Text>
                )}
                <Space h="md" />
              </>
            )}
            <Text
              size="sm"
              style={{
                fontWeight: "bold",
              }}
            >{`Matériau ${
              newAnatomyItem.itemType === ItemType.PostAndCoreRetainedCrown
                ? "d'infrastructure de Couronne Richmond"
                : "d'Inlay-Core"
            }`}</Text>
            <Controller
              name="inlayCoreMaterial"
              rules={{ required: true }}
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  data={inlayCoreMaterialFormChoices}
                  placeholder="Choisissez un matériau"
                  data-testid="select-inlay-core-material"
                />
              )}
            />
            {errors?.material?.type === "required" && (
              <Text
                size="sm"
                style={{ color: "red" }}
              >
                Veuillez choisir un matériau pour votre Inlay-Core /
                infrastructure de couronne Richmond
              </Text>
            )}
          </>
        )}
        {(product.productType === ItemType.InlayCore ||
          product.productType === ItemType.Crown) &&
          !product.hasIndividualItemCaracteristics &&
          getAllPossibleSplintedCrown(product.teeth).length > 0 &&
          !watchIsInlayCoreOnly &&
          !watchIsRichmondCrown &&
          isNotBridgeOnInlayCore && (
            <>
              <Space h="md" />
              <Text
                size="sm"
                style={{
                  fontWeight: "bold",
                }}
              >
                {"Faut-il solidariser les couronnes ?"}
              </Text>
              <Controller
                name="isSplintedCrown"
                rules={{
                  required: false,
                }}
                control={control}
                render={({ field }) => (
                  <ButtonsSelect
                    data={yesNoChoices}
                    value={field.value}
                    onChange={value => field.onChange(value)}
                  />
                )}
              />
              <Space h="md" />
            </>
          )}
        {!watchIsInlayCoreOnly && isNotBridgeOnInlayCore && (
          <>
            <Space h="md" />
            <Text
              size="sm"
              style={{
                fontWeight: "bold",
              }}
            >{`Matériau${
              newAnatomyItem.itemType === ItemType.InlayCore ||
              newAnatomyItem.itemType === ItemType.PostAndCoreRetainedCrown
                ? " de la couronne"
                : ""
            }`}</Text>
            <Controller
              name="material"
              rules={{
                required:
                  newAnatomyItem.productType === ItemType.InlayCore
                    ? watchIsInlayCoreOnly
                    : true,
              }}
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  data={materialFormChoices}
                  placeholder="Choisissez un matériau"
                  data-testid="select-crown-material"
                />
              )}
            />
            {errors?.material?.type === "required" && (
              <Text
                size="sm"
                style={{
                  color: "red",
                }}
              >
                Veuillez choisir un matériau
              </Text>
            )}
            <Space h="md" />
          </>
        )}
        {(!watchMaterial || watchMaterial !== "METAL_NON_PRECIOUS") &&
          !watchIsInlayCoreOnly &&
          isNotBridgeOnInlayCore && (
            <>
              <>
                <Text
                  size="sm"
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  {"Type de teintier"}
                </Text>
                <Controller
                  name="shadeType"
                  rules={{
                    required: true,
                  }}
                  control={control}
                  render={({ field }) => (
                    <ButtonsSelect
                      data={Object.keys(FixedProsthesisShadeType).map(
                        shadeType => ({
                          value: shadeType,
                          label: shadeType.replaceAll("_", " "),
                        })
                      )}
                      value={field.value}
                      onChange={value => field.onChange(value)}
                    />
                  )}
                />
                {errors?.shadeType?.type === "required" && (
                  <Text
                    size="sm"
                    style={{
                      color: "red",
                    }}
                  >
                    Veuillez choisir un type de teintier
                  </Text>
                )}
                <Space h="md" />
              </>
              {product.productType !== ItemType.Lay &&
                product.productType !== ItemType.Inlay &&
                product.productType !== ItemType.Onlay &&
                product.productType !== ItemType.Overlay &&
                user?.isMultiShadeEnabled && (
                  <>
                    <Text
                      size="sm"
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {"Affiner l'esthétique avec un dégradé de teinte ?"}
                    </Text>
                    <Controller
                      name="isMultiShade"
                      rules={{
                        required: false,
                      }}
                      control={control}
                      render={({ field }) => (
                        <ButtonsSelect
                          data={yesNoChoices}
                          value={field.value}
                          onChange={value => field.onChange(value)}
                        />
                      )}
                    />
                    <Space h="md" />
                  </>
                )}
              {watchIsMultiShade ? (
                <MultiShadeForm
                  control={control}
                  shadeFormChoices={shadeFormChoices}
                  errors={errors}
                  watchBaseShade={watchBaseShade}
                  watchIncisalShade={watchIncisalShade}
                  watchGingivalShade={watchGingivalShade}
                  watchIsMultiShade={watchIsMultiShade}
                />
              ) : (
                <>
                  <Text
                    size="sm"
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    {"Teinte"}
                  </Text>
                  <Controller
                    name="shade"
                    rules={{
                      required:
                        watchIsMultiShade ||
                        watchMaterial === "METAL_NON_PRECIOUS"
                          ? false
                          : true,
                    }}
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        data={shadeFormChoices}
                        placeholder="Choisissez une teinte"
                        data-testid="select-crown-shade"
                      />
                    )}
                  />
                  {errors?.shade?.type === "required" && (
                    <Text
                      size="sm"
                      style={{
                        color: "red",
                      }}
                    >
                      Veuillez choisir une teinte
                    </Text>
                  )}
                </>
              )}
            </>
          )}
        <Space h="xl" />
        {product.hasIndividualItemCaracteristics ? (
          <Button onClick={handleSubmit(onSubmit)}>Valider</Button>
        ) : (
          <StepsButtons
            handleSubmit={handleSubmit(onSubmit)}
            setPreviousAction={updateCreateProductIndex}
            previousStep={createProductIndex - 1}
            isFirst={createProductIndex > 0 ? false : true}
          />
        )}
      </form>
    </Box>
  );
};
