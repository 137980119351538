import React, { useCallback, createContext, useState } from "react";
import { useContext } from "react";
import { ReactNode } from "react";
import { api } from "../../services/api/api";
import { User } from "/@/generated/graphql";
import * as Sentry from "@sentry/react";
import Cookies from "js-cookie";

import { getDomain } from "@jasper/shared";

interface UserAndAuth extends User {
  accessToken: string;
}

type AuthState = {
  user: UserAndAuth | null;
  isLoggedIn: boolean;
};
type ContextValue = {
  user: UserAndAuth | null;
  isLoggedIn: boolean;
  logIn: (user: UserAndAuth) => void;
  logOut: () => void;
};

type AuthProviderProps = {
  children: ReactNode;
};

const AuthContext = createContext<ContextValue | undefined>(undefined);
const initialState: AuthState = {
  user: null,
  isLoggedIn: false,
};

function AuthProvider({ children }: AuthProviderProps) {
  const [user, setUser] = useState<UserAndAuth | null>(initialState.user);
  const [isLoggedIn, setIsLoggedIn] = useState(initialState.isLoggedIn);

  const logIn = useCallback((user: UserAndAuth) => {
    Sentry.setUser({
      id: user.id,
      email: user.email,
    });
    setUser(user);
    setIsLoggedIn(true);
    const domain = getDomain(
      import.meta.env.MODE,
      import.meta.env.VITE_API_URL
    );
    Cookies.set("email", user.email, {
      domain: domain,
    });
    Cookies.set("userGroupId", user.userGroupId, { domain: domain });
    Cookies.set("id", user.id, {
      domain: domain,
    });
    Cookies.set("role", user.role, {
      domain: domain,
    });
    Cookies.set("type", user.type, {
      domain: domain,
    });
    if (import.meta.env.VITE_API_URL) {
      Cookies.set("Authentication", user.accessToken, {
        domain: domain,
      });
      Cookies.set("Refresh", user.refreshToken, {
        domain: domain,
      });
    }
  }, []);

  const statelogOut = useCallback(() => {
    setUser(null);
    setIsLoggedIn(false);
  }, []);

  const logOut = async () => {
    await api.get("auth/logout").catch(err => {
      console.error(err);
      null;
    });

    const domain = getDomain(import.meta.env.MODE);
    Cookies.remove("id", {
      domain: domain,
    });
    Cookies.remove("email", {
      domain: domain,
    });
    Cookies.remove("role", {
      domain: domain,
    });
    Cookies.remove("type", {
      domain: domain,
    });
    Cookies.remove("Refresh", {
      domain: domain,
    });
    Cookies.remove("Authentication", {
      domain: domain,
    });
    Cookies.remove("userGroupId", {
      domain: domain,
    });
    statelogOut();
  };

  const authContextValue = {
    user,
    isLoggedIn,
    logIn,
    logOut,
  };

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
}

function useAuthContext() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuthContext not used within the AuthProvider");
  }
  return context;
}

export { AuthProvider, useAuthContext };
