import {
  Box,
  MultiSelect,
  Select,
  Space,
  Text,
  TextInput,
  Title,
  Button,
} from "@mantine/core";
import React, { FC, useState } from "react";
import {
  GetManyOrdersOfUserDocument,
  GetManyOrdersOfUserQuery,
  OrderStatus,
} from "/@/generated/graphql";
import { useAuthContext } from "/@/shared/contexts/AuthContext";
import { useQuery } from "@apollo/client";
import { OrderSummaryRow } from "/@/components/order/OrderSummaryRow";
import { ConfirmedOrderStatusLabels } from "/@/shared/types/orders/order.types";
import { useDebounce } from "use-debounce";

const _TAKE_ = 5;

export const OrdersListPage: FC = () => {
  const [skip, setSkip] = useState<number>(0);
  const [showLoadMoreButton, setShowLoadMoreButton] = useState<boolean>(false);
  const [displayedOrders, setDisplayedOrders] = useState<
    Array<NonNullable<GetManyOrdersOfUserQuery["getManyOrdersOfUser"]>[0]>
  >([]);
  const [selectedDentist, setSelectedDentist] = useState<string | null>(null);
  const [selectedOrderStatuses, setSelectedOrderStatuses] = useState<string[]>(
    []
  );
  const [searchInput, setSearchInput] = useState<string>("");
  const [searchInputDebounce] = useDebounce(searchInput, 500);

  const { user } = useAuthContext();
  const { data, loading } = useQuery(GetManyOrdersOfUserDocument, {
    fetchPolicy: "network-only",
    variables: {
      take: _TAKE_,
      skip: skip,
      where: {
        userId: selectedDentist
          ? {
              equals: selectedDentist,
            }
          : undefined,
        status:
          selectedOrderStatuses.length === 0
            ? {
                not: {
                  equals: OrderStatus.Draft,
                },
              }
            : {
                in: selectedOrderStatuses,
              },
        AND: [
          {
            OR: [
              ...searchInputDebounce
                .trim()
                .split(" ")
                .reduce((acc, word: string) => {
                  acc.push(
                    ...[
                      {
                        patient: {
                          is: {
                            firstName: {
                              contains: word,
                              mode: "insensitive",
                            },
                          },
                        },
                      },
                      {
                        patient: {
                          is: {
                            lastName: {
                              contains: word,
                              mode: "insensitive",
                            },
                          },
                        },
                      },
                    ]
                  );
                  return acc;
                }, []),
            ],
          },
        ],
      },
      orderBy: [
        {
          confirmedAt: {
            nulls: "last",
            sort: "desc",
          },
        },
        {
          updatedAt: "desc",
        },
      ],
    },
    onCompleted: data => {
      if (
        (data?.getManyOrdersOfUser ?? []).length > 0 &&
        data?.getManyOrdersOfUser.length % _TAKE_ === 0
      ) {
        setShowLoadMoreButton(true);
      } else {
        setShowLoadMoreButton(false);
      }
      setDisplayedOrders([...(data?.getManyOrdersOfUser ?? [])]);
    },
  });

  if (
    (data?.getManyOrdersOfUser ?? []).length === 0 &&
    displayedOrders.length === 0 &&
    !searchInput.trim() &&
    !selectedDentist &&
    selectedOrderStatuses.length === 0 &&
    !loading
  ) {
    return (
      <Box
        style={theme => ({
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          backgroundColor: theme.colors.greyScale[9],
        })}
      >
        <Title
          order={3}
          style={theme => ({
            color: theme.colors.greyScale[2],
          })}
        >
          {"Vous n'avez pas encore passé commande"}
        </Title>
        <Text
          style={theme => ({
            color: theme.colors.greyScale[2],
          })}
        >
          {"Veuillez passer une commande via votre caméra intra-orale 3Shape"}
        </Text>
        <Text
          mb="10%"
          style={theme => ({
            color: theme.colors.greyScale[2],
          })}
        >
          {"ou via le formulaire de commande en haut à droite de l'écran"}
        </Text>
      </Box>
    );
  }

  return (
    <>
      <Box
        p="lg"
        style={{
          height: "calc(100vh - 100px)",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
        }}
      >
        <div>
          <Title
            order={2}
            style={theme => ({
              color: theme.colors.black,
            })}
          >
            Vos Commandes
          </Title>
          <Space h="sm" />
          <Box
            style={{
              display: "flex",
            }}
          >
            {user?.userGroup?.id && (
              <Select
                mr="md"
                style={{
                  maxWidth: "40%",
                  minWidth: "30%",
                }}
                label="Afficher les commandes de"
                defaultValue={""}
                data={[
                  {
                    value: "",
                    label: "Tous",
                  },
                  ...(user?.userGroup?.groupMembers ?? [])
                    .filter(dentist => !dentist.isManager)
                    .map(dentist => ({
                      value: dentist.id,
                      label: `Dr. ${dentist.firstName} ${dentist.lastName}`,
                    })),
                ]}
                onChange={value => {
                  setDisplayedOrders([]);
                  setSkip(0);
                  setSelectedDentist(value || null);
                }}
              />
            )}
            <MultiSelect
              mr="md"
              style={{
                maxWidth: "40%",
                minWidth: "30%",
              }}
              clearable
              label="Filtrer par statut"
              placeholder="Aucun"
              data={ConfirmedOrderStatusLabels}
              onChange={value => {
                setDisplayedOrders([]);
                setSkip(0);
                setSelectedOrderStatuses(value);
              }}
            />
            <TextInput
              style={{ flexGrow: 1 }}
              placeholder="Tous"
              label="Chercher un patient"
              value={searchInput}
              onChange={event => {
                if (searchInput !== event.currentTarget.value.trim()) {
                  setDisplayedOrders([]);
                  setSkip(0);
                }
                setSearchInput(event.currentTarget.value);
              }}
            />
          </Box>
          <Space h="md" />
          <>
            {displayedOrders.length > 0 &&
              displayedOrders.map(order => {
                return (
                  <OrderSummaryRow
                    key={order.id}
                    order={order}
                  />
                );
              })}
          </>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {displayedOrders.length === 0 &&
          searchInput &&
          searchInputDebounce !== searchInput ? (
            <Text>Chargement...</Text>
          ) : (
            <>
              {!loading && skip !== 0 ? (
                <Button onClick={() => setSkip(skip - _TAKE_)}>
                  Précédent
                </Button>
              ) : (
                <div></div>
              )}
              {!loading && showLoadMoreButton ? (
                <Button onClick={() => setSkip(skip + _TAKE_)}>Suivant</Button>
              ) : (
                <div></div>
              )}
            </>
          )}
        </div>
      </Box>
    </>
  );
};
