import React, { ReactNode, useRef } from "react";
import "./button-file-input.css";
import { Button, MantineProvider } from "@mantine/core";

import { handleImageUpload } from "../../utils/file.utils";

export interface ButtonFileInputProps {
  onFilesAdd: (files: File[]) => void;
  buttonText: ReactNode;
  accept: string;
}

export const ButtonFileInput = ({ ...props }: ButtonFileInputProps) => {
  const fileInput = useRef<any>(null);

  return (
    <MantineProvider>
      <Button
        style={{ textAlign: "center", backgroundColor: "#3C61E3" }}
        onClick={() => {
          fileInput.current.click();
        }}
      >
        {props.buttonText}
      </Button>
      <input
        ref={fileInput}
        onChange={async files => {
          const fileList = await Promise.all(
            [...(files.target.files ?? [])].map(async file => {
              const compressedFile = await handleImageUpload(file);
              return compressedFile ?? file;
            })
          );
          props.onFilesAdd(fileList);
        }}
        style={{ display: "none" }}
        multiple
        type="file"
        accept={props.accept}
        onClick={() => console.info("Button clicked")}
      />
    </MantineProvider>
  );
};
