import React, { useState } from "react";
import {
  Stepper,
  Button,
  Group,
  Modal,
  Container,
  Title,
  Space,
  Text,
  Loader,
  LoadingOverlay,
} from "@mantine/core";
import { useForm } from "react-hook-form";

import {
  CreateProductFromScanOnly,
  OrderRecap,
  STYLE,
  getDeliveryDateAsString,
} from "@jasper/shared";

const ScanOnlyWorkflow = ({
  order,
  updateOrder,
  navigate,
  onSubmit,
  getDeliveryDate,
  user,
  saveOrderComment,
  updateDeliveryDate,
  refetchOrder,
  setIsShowAddProductModal,
}) => {
  const _NUMBER_OF_STEPS_ = 2;
  const rhf = useForm();
  const [isLoading, setIsLoading] = useState(false);

  const [isShowConfirmModal, setIsShowConfirmModal] = useState<boolean>(false);
  const [estimatedDeliveryDateString, setEstimatedDeliveryDateString] =
    useState<string>(null);
  const [active, setActive] = useState(0);
  const nextStep = () =>
    setActive(current => (current < 2 ? current + 1 : current));
  const prevStep = () =>
    setActive(current => (current > 0 ? current - 1 : current));

  return (
    <>
      <LoadingOverlay
        visible={isLoading}
        zIndex={1000}
        overlayProps={{
          radius: "sm",
          blur: 2,
        }}
      >
        <Loader />
      </LoadingOverlay>
      <Modal
        opened={isShowConfirmModal}
        onClose={() => {
          setIsShowConfirmModal(false);
          navigate("/");
        }}
        size="xl"
        withCloseButton={false}
        centered
        overlayProps={{
          opacity: 0.6,
          blur: 17,
        }}
      >
        <Container
          p="lg"
          style={{
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Title
            order={2}
            style={theme => ({
              color: theme.colors.primary[3],
            })}
          >
            Commande passée avec succès !
          </Title>
          <Space h="lg" />
          <Title
            order={2}
            style={{ color: STYLE.secondary }}
          >
            {estimatedDeliveryDateString && estimatedDeliveryDateString !== ""
              ? `Date de livraison estimée: ${estimatedDeliveryDateString ?? "Communiquée prochainement sur la page de la commande"}`
              : ""}
          </Title>
          <Space h="md" />
          <Text style={{ textAlign: "center" }}>
            Vous pouvez suivre votre commande depuis votre dashboard, ou en
            cliquant ci-dessous
          </Text>
          <Space h="xl" />
          <Button
            onClick={async () => {
              const req = await getDeliveryDate({
                variables: {
                  orderId: order?.id,
                },
              });
              const deliveryDate =
                order?.deliveryDate ??
                req.data?.getOrderEstimatedDeliveryDate?.deliveryDate;
              onSubmit(deliveryDate);
            }}
            data-testid="button-show-my-order"
          >
            Voir ma commande
          </Button>
        </Container>
      </Modal>
      <div style={{ padding: "1.5rem" }}>
        <Stepper
          active={active}
          onStepClick={setActive}
        >
          <Stepper.Step label="Informations">
            <CreateProductFromScanOnly rhf={rhf} />
          </Stepper.Step>
          <Stepper.Step label="Recap">
            <OrderRecap
              order={order}
              getDeliveryDate={getDeliveryDate}
              user={user}
              saveOrderComment={saveOrderComment}
              updateDeliveryDate={updateDeliveryDate}
              refetchOrder={refetchOrder}
              setIsShowAddProductModal={setIsShowAddProductModal}
            />
          </Stepper.Step>
        </Stepper>

        <Group
          justify="space-between"
          mt="xl"
        >
          <div>
            {active !== 0 && (
              <Button
                variant="outline"
                size="md"
                onClick={prevStep}
              >
                Précédent
              </Button>
            )}
          </div>
          <Button
            size="md"
            onClick={async () => {
              if (active === _NUMBER_OF_STEPS_ - 1) {
                setIsLoading(true);
                const req = await getDeliveryDate({
                  variables: {
                    orderId: order?.id,
                  },
                });
                const deliveryDate =
                  order?.deliveryDate ??
                  req.data?.getOrderEstimatedDeliveryDate?.deliveryDate;
                setEstimatedDeliveryDateString(
                  getDeliveryDateAsString(deliveryDate)
                );
                setIsLoading(false);
                setIsShowConfirmModal(true);
              } else {
                rhf.handleSubmit(async values => {
                  setIsLoading(true);
                  await updateOrder(values);
                  setIsLoading(false);
                  nextStep();
                })();
              }
            }}
          >
            {active === _NUMBER_OF_STEPS_ - 1 ? "Valider" : "Suivant"}
          </Button>
        </Group>
      </div>
    </>
  );
};

export default ScanOnlyWorkflow;
