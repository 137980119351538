import { AppShell } from "@mantine/core";
import React from "react";
import { LayoutAppBar } from "./LayoutAppBar";
import { LayoutDrawer } from "./LayoutDrawer";

export const LoggedInLayout = ({ children }: { children: JSX.Element }) => {
  return (
    <AppShell
      layout="alt"
      header={{ height: 60 }}
      navbar={{
        width: 225,
        breakpoint: "xs",
      }}
      padding={0}
    >
      <LayoutAppBar />
      <LayoutDrawer />
      <AppShell.Main>{children}</AppShell.Main>
    </AppShell>
  );
};
