import {
  Box,
  Grid,
  Space,
  Text,
  Title,
  TextInput,
  LoadingOverlay,
  Loader,
} from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import React, { FC, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ButtonsSelect } from "/@/components/ButtonsSelect";
import { StepsButtons } from "/@/components/StepsButtons";
import { CreateOrderDocument, ItemType } from "/@/generated/graphql";
import DentalArchesSvg from "/@/pages/orders/DentalArchesSvg";
import {
  LowerArchTeeth,
  UpperArchTeeth,
} from "../../../shared/types/products/products.types";
import {
  OrderFormItemTypeChoices,
  isUpperArchTeeth,
} from "/@/shared/utils/products/products.utils";
import { useMutation } from "@apollo/client";
import { useAuthContext } from "/@/shared/contexts/AuthContext";
import { isRemovableItemType } from "@jasper/shared";

type CreateOrderFormData = {
  productType: ItemType;
  teeth: string;
  ponticTeeth: string;
};

export const CreateOrderFormPage: FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
    register,
  } = useForm<CreateOrderFormData>({
    defaultValues: {
      ponticTeeth: "",
    },
  });
  const { user } = useAuthContext();
  const watchItemType = watch("productType");
  const { classes } = useStyles();
  const navigate = useNavigate();

  const [teeth, setTeeth] = useState<number[]>([]);
  const [isFormError, setIsFormError] = useState(false);

  const [createOrder] = useMutation(CreateOrderDocument);

  useEffect(() => {
    if (watchItemType) {
      setTeeth([]);
    }
  }, [watchItemType]);

  const onSubmit: SubmitHandler<CreateOrderFormData> = data => {
    setIsLoading(true);
    if (!data.productType || teeth.length <= 0) {
      setIsLoading(false);
      setIsFormError(true);
      return;
    } else if (isFormError) {
      setIsLoading(false);
      setIsFormError(false);
    }
    const ponticTeethNumber = (data?.ponticTeeth ?? "")
      .split(",")
      .map(tString => parseInt(tString));
    const teethString = teeth
      .filter(t => !(ponticTeethNumber ?? []).includes(t))
      .join(",");
    const productInfo = `${data.productType} ${teethString}`;
    createOrder({
      variables: {
        args: {
          productInfo: productInfo,
          ponticTeeth: data.ponticTeeth,
          isPhysicalPrint: user?.allowPhysicalPrint,
          createdWithWebapp: true,
        },
      },
    }).then(res => {
      navigate("/create-order", {
        state: {
          id: res.data?.createOrder?.id,
        },
      });
    });
    setIsLoading(false);
  };

  return (
    <Box
      style={theme => ({
        height: "100%",
        display: "flex",
        flexDirection: "column",
        padding: theme.spacing.xl,
      })}
    >
      <LoadingOverlay
        visible={isLoading}
        zIndex={1000}
        overlayProps={{
          radius: "sm",
          blur: 2,
        }}
      >
        <Loader />
      </LoadingOverlay>
      <Title
        order={2}
        style={theme => ({
          color: theme.colors.primary[4],
        })}
      >
        {"Créer une commande"}
      </Title>
      <Space h="xl" />
      <Title order={3}>{"Que souhaitez-vous commander ?"}</Title>
      <Space h="xl" />
      <Grid
        gutter="xl"
        style={{ flexGrow: 1 }}
      >
        <Grid.Col span={6}>
          <Box className={classes.inputContainer}>
            <Title order={5}>{"Type de produit"}</Title>
            <Space h="xl" />
            <Controller
              name="productType"
              control={control}
              render={({ field }) => (
                <ButtonsSelect
                  data={OrderFormItemTypeChoices}
                  value={field.value}
                  onChange={value => field.onChange(value)}
                />
              )}
            />
            {errors?.productType?.type === "required" && (
              <Text
                size="sm"
                style={{
                  color: "red",
                }}
              >
                Veuillez choisir un type de produit
              </Text>
            )}
          </Box>
        </Grid.Col>
        <Grid.Col
          span={6}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Box className={classes.inputContainer}>
            <Title order={5}>{"Sélectionnez les dents à traiter"}</Title>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Space h="xl" />
              <DentalArchesSvg
                teeth={teeth}
                width={300}
                height={400}
                onClick={
                  isRemovableItemType(watchItemType)
                    ? (tooth: number) => {
                        if (isUpperArchTeeth(tooth)) {
                          teeth.includes(tooth)
                            ? setTeeth([
                                ...teeth.filter(
                                  t => !UpperArchTeeth.includes(t)
                                ),
                              ])
                            : setTeeth([...teeth, ...UpperArchTeeth]);
                        } else {
                          teeth.includes(tooth)
                            ? setTeeth(
                                teeth.filter(t => !LowerArchTeeth.includes(t))
                              )
                            : setTeeth([...teeth, ...LowerArchTeeth]);
                        }
                      }
                    : (tooth: number) =>
                        teeth.includes(tooth)
                          ? setTeeth([...teeth].filter(t => t !== tooth))
                          : setTeeth([...teeth, tooth])
                }
              />
            </Box>
          </Box>
        </Grid.Col>
        {watchItemType === ItemType.BridgeImplant && (
          <Grid.Col span={6}>
            <Box className={classes.inputContainer}>
              <Title order={5}>{"Dent du pontique"}</Title>
              <Space h="xl" />
              <TextInput
                {...register("ponticTeeth", { required: false })}
                label="Dents du pontique"
                placeholder="11,12"
                size="md"
              />
              {errors?.productType?.type === "required" && (
                <Text
                  size="sm"
                  style={{
                    color: "red",
                  }}
                >
                  Veuillez choisir un type de produit
                </Text>
              )}
            </Box>
          </Grid.Col>
        )}
      </Grid>
      {isFormError && (
        <Text
          size="sm"
          style={{ color: "red" }}
        >
          Veuillez choisir un type de produit et les dents concernées
        </Text>
      )}
      <Space h="xl" />
      <StepsButtons
        handleSubmit={handleSubmit(onSubmit)}
        setPreviousAction={() => null}
      />
      <Space h="xl" />
    </Box>
  );
};

const useStyles = createStyles(theme => ({
  inputContainer: {
    borderRadius: "10px",
    outline: `0.5px solid ${theme.colors.greyScale[9]}`,
    padding: theme.spacing.md,
    height: "100%",
    minHeight: "100%",
    minWidth: "100%",
    backgroundColor: theme.colors.greyScale[9],
  },
}));
