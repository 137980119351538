import { MantineThemeOverride, colorsTuple } from "@mantine/core";

/* ------------------------- New theme with Mantine ------------------------- */

export const appTheme = {
  colors: {
    primary: [
      "#0018AB",
      "#0030BD",
      "#0F3BC8",
      "#2046D5",
      "#264FE0", // main color index 4
      "#3158E2",
      "#3C61E3",
      "#4769E5",
      "#5172E6",
      "#5C7BE8",
    ],
    primaryDark: [
      "#00119B",
      "#0028AD", // main color index 1
      "#1434B8",
      "#223EC4",
      "#2746CF",
      "#4F63D8",
      "#717FDF",
      "#9BA3E8",
      "#C4C7F1",
      "#E7E9FA",
    ],
    primaryLight: [
      "#5b6dd0",
      "#6881e6",
      "#708cf3",
      "#7b99ff",
      "#83a4ff",
      "#97b6ff",
      "#aec8ff",
      "#c7d9ff",
      "#dee7ff",
      "#f3f6fe", // same as slider index 9
    ],
    secondary: [
      "#004e87",
      "#006da7",
      "#007eba",
      "#0090cd",
      "#009ddb",
      "#26abe0", // main color index 5
      "#4cb9e4",
      "#7dcdec",
      "#b0e1f4",
      "#e0f3fb",
    ],
    greyScale: [
      "#293540",
      "#3b4958",
      "#4a5c6d",
      "#5a7084",
      "#687f96",
      "#7e92a6",
      "#95a6b7",
      "#b2c0cd",
      "#cfd9e3",
      "#ebf0f6",
    ],
  },
};

const themeColors: Record<string, any> = {
  primary: colorsTuple(appTheme.colors.primary),
  secondary: colorsTuple(appTheme.colors.secondary),
  primaryDark: colorsTuple(appTheme.colors.primaryDark),
  primaryLight: colorsTuple(appTheme.colors.primaryLight),
  greyScale: colorsTuple(appTheme.colors.greyScale),
};

export const theme: MantineThemeOverride = {
  colors: themeColors,
  primaryColor: "primary",
  fontFamily:
    "DM Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji",
  headings: {
    fontFamily:
      "DM Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji",
  },
};
