import React, { FC, useEffect, useRef, useState } from "react";
import { getDomain, useFiles } from "@jasper/shared";
import { useParams } from "react-router-dom";
import { Box } from "@mantine/core";
import Logo from "/@/assets/images/logo.svg";
import { FileType } from "/@/generated/graphql";
import { FileType as FT, FileList } from "@jasper/shared";
import Cookies from "js-cookie";
import posthog from "posthog-js";
import { Carousel } from "@jasper/shared/stories/Carousel/Carousel";
import { File as SharedFile } from "@jasper/shared/types/interfaces";
import { io } from "socket.io-client";
import { getApiUrl } from "/@/services/api/api";
import { ButtonFileInput } from "@jasper/shared/stories/ButtonFileInput/ButtonFileInput";

interface RouteParams {
  token: string | undefined;
  id: string | undefined;
  name: string | undefined;
  filetype: string | undefined;
}

export const UploadFilePage: FC = () => {
  const [imageList, setImageList] = useState<SharedFile[]>([]);
  const [files, setFiles] = useState([]);
  const param = useParams<keyof RouteParams>();
  const imgCount = useRef(0);
  if (param.token !== undefined) {
    Cookies.set("Authentication", param.token, {
      expires: 1,
      domain: getDomain(import.meta.env.MODE),
    });
  }

  if (
    param.id === undefined ||
    param.name === undefined ||
    param.token === undefined
  ) {
    return;
  }

  const {
    filesOfOrder,
    uploadFileToS3,
    getPresignedDownloadUrl,
    deleteOneFile,
    getFileUrl,
    refetchFilesOfOrder,
  } = useFiles(param.id);

  const refreshImageList = async (data: any) => {
    return await Promise.all(
      data.map(async (obj: SharedFile) => {
        const path = await getFileUrl(obj.key);
        return {
          ...obj,
          path: path,
        };
      })
    );
  };

  const sortFiles = (files: SharedFile[]) => {
    return files.sort((a: SharedFile, b: SharedFile) => {
      return parseInt(b.key.split("_")[1]) - parseInt(a.key.split("_")[1]);
    });
  };

  useEffect(() => {
    posthog.capture("user_in_fileupload_page");
  }, []);
  useEffect(() => {
    const f = filesOfOrder?.getFilesOfOrder.filter(
      (file: { fileType: FileType }) =>
        file.fileType === FileType.ClientFile ||
        file.fileType === FileType.OrderAddonFile
    );
    imgCount.current = (f ?? []).length ?? 0;
    setFiles(f);
    if (f) {
      refreshImageList(f).then(a => setImageList(sortFiles(a)));
    }
  }, [filesOfOrder?.getFilesOfOrder]);

  useEffect(() => {
    setImageList([]);
  }, [param.id]);

  useEffect(() => {
    const socket = io(getApiUrl(), {
      query: {
        type: "orderFile",
        order_id: param.id,
      },
    });

    socket.connect();
    socket.on("clientOrderFile", () => {
      refetchFilesOfOrder();
    });
    return () => {
      socket.disconnect();
      socket.off("connect");
      socket.off("clientOrderFile");
      setImageList([]);
      setFiles([]);
    };
  }, []);

  return (
    <>
      <div
        style={{
          width: "100%",
          minHeight: "100vh",
          display: "flex",
          backgroundColor: "#5172E6",
          padding: "20px 0",
        }}
      >
        <div
          style={{
            width: "80%",
            minHeight: "60%",
            margin: "auto",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "white",
            borderRadius: "10px",
            padding: "20px",
          }}
        >
          <img
            src={Logo}
            style={{
              height: "auto",
              margin: "20px auto",
            }}
          />
          <p>Nom du patient : {param.name}</p>
          <ButtonFileInput
            onFilesAdd={async files => {
              try {
                files.map(async (file, index) => {
                  try {
                    const name = file.name.split(".");
                    const newFile = new File(
                      [file],
                      `photo_${imageList.length + (1 + index)}.${name[name.length - 1]}`,
                      {
                        type: file.type,
                      }
                    );

                    const fileType = param.filetype
                      ? (param.filetype as FT)
                      : FT.CLIENT_FILE;

                    await uploadFileToS3([newFile], fileType).catch(error => {
                      console.error("S3 upload error:", error);
                      throw new Error("Erreur lors du téléchargement vers S3");
                    });

                    await new Promise(resolve => setTimeout(resolve, 100));
                  } catch (innerError) {
                    console.error(
                      "Error processing file:",
                      file.name,
                      innerError
                    );
                    throw innerError;
                  }
                });
              } catch (error) {
                console.error("Upload process error:", error);
                return false;
              }
            }}
            buttonText={
              <span>
                Prendre une photo ou
                <br />
                choisir dans la photothèque
              </span>
            }
            accept="image/*"
          />
          <div
            style={{
              padding: "20px",
            }}
          >
            <Carousel imageList={imageList} />
          </div>
          {files && files.length > 0 && (
            <Box>
              <FileList
                files={files}
                deleteOneFile={deleteOneFile}
                downloadOneFile={getPresignedDownloadUrl}
                deleteFilter={[FileType.ClientFile, FileType.OrderAddonFile]}
              />
            </Box>
          )}
        </div>
      </div>
    </>
  );
};
