import {
  ApolloClient,
  from,
  HttpLink,
  InMemoryCache,
  Observable,
} from "@apollo/client";
import { refreshApi } from "./services/api/api";
import { onError } from "@apollo/client/link/error";

const getHttpLink = () => {
  if (import.meta.env.VITE_API_URL) {
    return `${import.meta.env.VITE_API_URL}/graphql`;
  }
  switch (import.meta.env.MODE) {
    case "test":
      return "https://test.api.jasperdental.fr/graphql";

    case "staging":
      return "https://staging.api.jasperdental.fr/graphql";

    case "production":
      return "https://api.jasperdental.fr/graphql";

    default:
      // TO UNCOMMENT WHEN WE WANT TO TEST WEBAPP IN ANOTHER DEVICE
      //return (window.location.hostname === 'localhost')? "http://localhost:5050/graphql" : "http://10.7.56.205:5050/graphql";
      // THE IP IS TO BE CHANGED ALSO DEPENDING ON YOUR DEVICE
      return "http://localhost:5050/graphql";
  }
};

const httpLink = new HttpLink({
  uri: getHttpLink(),
  credentials: "include",
});

const refreshTokenLink = onError(({ graphQLErrors, forward, operation }) => {
  let hasToRefresh = false;
  if (graphQLErrors) {
    for (let i = 0; i < graphQLErrors.length; i++) {
      if ("originalError" in graphQLErrors[i].extensions) {
        const statusCode = (
          graphQLErrors[i].extensions.originalError as {
            statusCode: number;
            message: string;
          }
        ).statusCode;
        //console.log(typeof statusCode);
        if (statusCode === 401) {
          hasToRefresh = true;
        }
      }
    }
  }
  if (hasToRefresh) {
    return new Observable(observer => {
      refreshApi
        .get("auth/refresh-token")
        .then(() => {
          // storeRefreshTokenOnDesktop();
          const subscriber = {
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          };

          // Retry last failed request
          //console.log("forwarding");
          forward(operation).subscribe(subscriber);
        })
        .catch(err => {
          window.location.reload();
          observer.error(err);
        });
    });
  }
});

const client = new ApolloClient({
  link: from([refreshTokenLink, httpLink]),
  cache: new InMemoryCache(),
});

export default client;
