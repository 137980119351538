import { Box, Space, Text, Title } from "@mantine/core";
import React, { FC, SetStateAction, Dispatch } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { ButtonsSelect } from "/@/components/ButtonsSelect";
import { StepsButtons } from "/@/components/StepsButtons";
import {
  ItemType,
  DeleteManyRemovableItemDocument,
  CreateRemovableItemDocument,
} from "/@/generated/graphql";
import {
  ProductFromGetOrderByUniqueAttributeQuery,
  SplintHardnessChoices,
  SplintTypeChoices,
  yesNoChoices,
} from "../../../../shared/types/products/products.types";
import {
  getAllUpperTeeths,
  getAllLowerTeeths,
} from "../../../../shared/utils/products/products.utils";
import { useMutation } from "@apollo/client";
import { useCreateOrderStore } from "/@/shared/stores/orders/create-store.store";
import client from "/@/apolloClient";

import { getItemTypeLabel } from "@jasper/shared";

type SplintInfoFormData = {
  splintType: ItemType;
  splintMaterial?: string;
  isBothJaws: boolean;
};

type CreateRemovableItemSplintInfoProps = {
  product: ProductFromGetOrderByUniqueAttributeQuery;
  setIsBothJaws: Dispatch<SetStateAction<boolean>>;
  setIsLoading: (isLoading: boolean) => void;
};
export const CreateRemovableItemSplintInfo: FC<
  CreateRemovableItemSplintInfoProps
> = ({ product, setIsBothJaws, setIsLoading }) => {
  const newRemovableItem = product.removableItem[0];
  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<SplintInfoFormData>({
    defaultValues: {
      isBothJaws: (product.removableItem ?? []).length > 1 ? true : false,
      splintType:
        newRemovableItem?.itemType === ItemType.NightGuard ||
        newRemovableItem?.itemType === ItemType.BleachingTray ||
        newRemovableItem?.itemType === ItemType.Retainer
          ? newRemovableItem?.itemType
          : undefined,
      splintMaterial: newRemovableItem?.itemMaterial?.id,
    },
  });

  const watchSplintType = watch("splintType");

  const [previousSplintType, setPreviousSplintType] =
    React.useState<string>("");

  const [createRemovableItem] = useMutation(CreateRemovableItemDocument);
  const [deleteManyRemovableItem] = useMutation(
    DeleteManyRemovableItemDocument
  );

  const [createProductIndex, updateCreateProductIndex] = useCreateOrderStore(
    state => [state.createProductIndex, state.updateCreateProductIndex]
  );

  if (!product.removableItem || product.removableItem.length <= 0) {
    return (
      <div>
        <Text>
          {"Erreur sur l'ajout de produit. Veuillez contacter le support."}
        </Text>
      </div>
    );
  }

  const onSubmit: SubmitHandler<SplintInfoFormData> = async data => {
    setIsLoading(true);
    await deleteManyRemovableItem({
      variables: {
        where: {
          productId: {
            equals: product.id,
          },
        },
      },
    });
    if (data.isBothJaws) {
      await createRemovableItem({
        variables: {
          args: {
            product: {
              connect: {
                id: product.id,
              },
            },
            itemType: data.splintType,
            itemMaterial: data.splintMaterial
              ? {
                  connect: {
                    id: data.splintMaterial,
                  },
                }
              : undefined,
            teeth: getAllUpperTeeths(),
            teethToManufacture: {
              set: getAllUpperTeeths(),
            },
          },
        },
      });
      await createRemovableItem({
        variables: {
          args: {
            product: {
              connect: {
                id: product.id,
              },
            },
            itemType: data.splintType,
            itemMaterial: data.splintMaterial
              ? {
                  connect: {
                    id: data.splintMaterial,
                  },
                }
              : undefined,
            teeth: getAllLowerTeeths(),
            teethToManufacture: {
              set: getAllLowerTeeths(),
            },
          },
        },
      });
    } else {
      await createRemovableItem({
        variables: {
          args: {
            product: {
              connect: {
                id: product.id,
              },
            },
            itemType: data.splintType,
            itemMaterial: data.splintMaterial
              ? {
                  connect: {
                    id: data.splintMaterial,
                  },
                }
              : undefined,
            teeth: product.teeth,
            teethToManufacture: {
              set: product.teeth,
            },
          },
        },
      });
    }
    await client.refetchQueries({
      include: ["getOrderByUniqueAttribute"],
    });
    await updateCreateProductIndex(createProductIndex + 1);
    setIsLoading(false);
  };

  React.useMemo(() => {
    if (
      watchSplintType === ItemType.BleachingTray &&
      !previousSplintType &&
      newRemovableItem.teethToManufacture.length === 0
    ) {
      setValue("isBothJaws", true);
      setIsBothJaws(true);
    }
    if (watchSplintType !== ItemType.NightGuard) {
      setValue("splintMaterial", undefined);
    }
    if (watchSplintType === ItemType.NightGuard) {
      setValue("splintMaterial", "NIGHT_GUARD_MEDIUM_MATERIAL");
    }
    setPreviousSplintType(watchSplintType);
  }, [watchSplintType]);

  return (
    <Box>
      <Title
        order={2}
        style={theme => ({
          color: theme.colors.primary[3],
        })}
      >{`Caractéristiques - ${getItemTypeLabel(product.productType)?.toLowerCase()}`}</Title>
      <Space h="md" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Text
          size="sm"
          style={{
            fontWeight: "bolder",
          }}
        >
          {"Type de gouttière"}
        </Text>
        <Space h="xs" />
        <Controller
          name="splintType"
          rules={{ required: true }}
          control={control}
          render={({ field }) => (
            <ButtonsSelect
              data={SplintTypeChoices}
              numberOfColumns={3}
              value={field.value}
              onChange={value => field.onChange(value)}
            />
          )}
        />
        {errors?.splintType?.type === "required" && (
          <>
            <Space h="sm" />
            <Text
              size="sm"
              style={{ color: "red" }}
            >
              Veuillez choisir un type de gouttière
            </Text>
          </>
        )}
        {watchSplintType && (
          <>
            <Space h="lg" />
            <Text
              size="sm"
              style={{
                fontWeight: "bold",
              }}
            >
              {"Souhaitez-vous une gouttiere bimaxillaire?"}
            </Text>
            <Space h="xs" />
            <Controller
              name="isBothJaws"
              rules={{
                validate: value => value !== undefined,
              }}
              control={control}
              render={({ field }) => (
                <ButtonsSelect
                  data={yesNoChoices}
                  value={field.value}
                  onChange={(value: boolean) => {
                    setIsBothJaws(value);
                    return field.onChange(value);
                  }}
                />
              )}
            />
            {errors?.isBothJaws?.type === "required" && (
              <Text
                size="sm"
                style={{ color: "red" }}
              >
                Veuillez choisir si la gouttière est bimaxillaire
              </Text>
            )}
          </>
        )}
        {watchSplintType === ItemType.NightGuard && (
          <>
            <Space h="lg" />
            <Text
              size="sm"
              style={{
                fontWeight: "bold",
              }}
            >
              {"Type"}
            </Text>
            <Space h="xs" />
            <Controller
              name="splintMaterial"
              rules={{ required: true }}
              control={control}
              render={({ field }) => (
                <ButtonsSelect
                  data={SplintHardnessChoices.filter(
                    () => watchSplintType === ItemType.NightGuard
                  )}
                  numberOfColumns={3}
                  value={field.value}
                  onChange={value => field.onChange(value)}
                />
              )}
            />
            {errors?.splintMaterial?.type === "required" && (
              <Text
                size="sm"
                style={{ color: "red" }}
              >
                Veuillez choisir votre type de gouttière
              </Text>
            )}
          </>
        )}
        <Space h="xl" />
        <StepsButtons
          handleSubmit={handleSubmit(onSubmit)}
          setPreviousAction={updateCreateProductIndex}
          previousStep={createProductIndex - 1}
          isFirst={createProductIndex > 0 ? false : true}
        />
      </form>
    </Box>
  );
};
