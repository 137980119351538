import React, { FC } from "react";
import { Box, Title, Select, Space, Text } from "@mantine/core";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  GetUserProductPreferencesQuery,
  ItemType,
  UpdateProductDocument,
} from "/@/generated/graphql";
import { CreateAnatomyItemStep } from "../../../../shared/types/orders/createOrder.types";
import { StepsButtons } from "/@/components/StepsButtons";
import {
  AnteriorPosteriorPonticDesignLabels,
  InterproximalContactDesignLabels,
  NoOcclusalClearanceLabels,
  OcclusalContactTightnessLabels,
  OcclusalStainingLabels,
  ProximalContactTightnessLabels,
} from "../../../../shared/types/product-preferences/fixedProsthesisPreferences.types";
import { getFixedProsthesisPreferences } from "../../../../shared/utils/productPreferences/fixedProsthesisPreferences.utils";
import { useMutation } from "@apollo/client";
import { ProductFromGetOrderByUniqueAttributeQuery } from "/@/shared/types/products/products.types";
import { useCreateOrderStore } from "/@/shared/stores/orders/create-store.store";

import { getItemTypeLabel } from "@jasper/shared";

type OrderCreationFixedProsthesisPreferencesData = any;

type CreateOrderAnatomyItemPreferencesProps = {
  product: ProductFromGetOrderByUniqueAttributeQuery;
  userProductPreferences: GetUserProductPreferencesQuery;
  setIsLoading: (isLoading: boolean) => void;
};

export const CreateOrderAnatomyItemPreferences: FC<
  CreateOrderAnatomyItemPreferencesProps
> = ({ product, userProductPreferences, setIsLoading }) => {
  const [updateProduct] = useMutation(UpdateProductDocument);
  const productType = product.productType;
  const [updateCreateProductStep, incrementCreateProductIndex] =
    useCreateOrderStore(state => [
      state.updateCreateProductStep,
      state.incrementCreateProductIndex,
    ]);

  // console.log("userpref", userProductPreferences);
  const itemPreferences = {
    ...getFixedProsthesisPreferences(
      product.productType,
      userProductPreferences
    ),
  };
  // console.log("itempref", itemPreferences);

  const { control, handleSubmit } =
    useForm<OrderCreationFixedProsthesisPreferencesData>({
      defaultValues: itemPreferences ?? {},
      // {
      //   interproximalContactDesign: Object.values(
      //     InterproximalContactDesign,
      //   ).includes(
      //     itemPreferences?.interproximalContactDesign as InterproximalContactDesign,
      //   )
      //     ? (itemPreferences?.interproximalContactDesign as InterproximalContactDesign)
      //     : undefined,
      //   noOcclusalClearance: itemPreferences?.noOcclusalClearance,
      //   occlusalContactTightness: itemPreferences?.occlusalContactTightness,
      //   occlusalStaining: itemPreferences?.occlusalStaining,
      //   proximalContactTightness: itemPreferences?.proximalContactTightness,
      // },
    });
  const onSubmit: SubmitHandler<
    OrderCreationFixedProsthesisPreferencesData
  > = async data => {
    setIsLoading(true);
    const preferencesToSend = {
      ...itemPreferences,
      ...data,
    };
    if (productType !== ItemType.Bridge && preferencesToSend) {
      Object.keys(itemPreferences).forEach(key => {
        if (key === "anteriorPosteriorPonticDesign" && preferencesToSend[key]) {
          delete preferencesToSend[key];
        }
      });
    }
    try {
      await updateProduct({
        variables: {
          where: {
            id: product.id,
          },
          data: {
            itemPreferences: preferencesToSend,
          },
        },
      });
      setIsLoading(false);
      incrementCreateProductIndex();
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  return (
    <Box>
      <Title
        order={2}
      >{`Préférences - ${getItemTypeLabel(productType)}`}</Title>
      <Space h="md" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="occlusalContactTightness"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              data={OcclusalContactTightnessLabels}
              label="Occlusion"
            />
          )}
        />
        <Space h="sm" />
        <Controller
          name="proximalContactTightness"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              data={ProximalContactTightnessLabels}
              label="Contact proximal"
            />
          )}
        />
        <Text
          size="xs"
          style={{
            fontStyle: "italic",
          }}
        >
          {"Quelle intensité de contact proximal ?"}
        </Text>
        <Space h="sm" />
        <Controller
          name="occlusalStaining"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              data={OcclusalStainingLabels}
              label="Coloration occlusale"
            />
          )}
        />
        <Text
          size="xs"
          style={{
            fontStyle: "italic",
          }}
        >
          {"Quelles sont vos préférences de coloration occlusale ?"}
        </Text>
        <Space h="sm" />
        <Controller
          name="noOcclusalClearance"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              data={NoOcclusalClearanceLabels}
              label="Que doit faire Jasper en cas d'espace occlusal insuffisant ?"
            />
          )}
        />
        <Space h="sm" />
        <Controller
          name="interproximalContactDesign"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              data={InterproximalContactDesignLabels}
              label="Étendue du point de contact inter dentaire"
            />
          )}
        />
        {productType === ItemType.Bridge && (
          <>
            <Space h="sm" />
            <Controller
              name="anteriorPosteriorPonticDesign"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  data={AnteriorPosteriorPonticDesignLabels}
                  label="Design du pontique antérieur et postérieur"
                />
              )}
            />
            <Text
              size="xs"
              style={{
                fontStyle: "italic",
              }}
            >
              {
                "Quel design du pontique antérieur et postérieur souhaitez-vous ?"
              }
            </Text>
            <Space h="sm" />
          </>
        )}
        <Space h="md" />
        <StepsButtons
          setPreviousAction={number => updateCreateProductStep(number)}
          previousStep={CreateAnatomyItemStep.Item}
          handleSubmit={handleSubmit(onSubmit)}
        />
      </form>
    </Box>
  );
};
