import { Container, Space, Text, Title, Box } from "@mantine/core";
import React, { FC } from "react";
import DentalArchesSvg from "/@/pages/orders/DentalArchesSvg";
import { ProductFromGetOrderByUniqueAttributeQuery } from "/@/shared/types/products/products.types";
import { GetOrderByUniqueAttributeQuery } from "/@/generated/graphql";
import { ItemsDescription } from "/@/components/order/ItemsDescription";

import { getProductTeeth, getAllTeeths } from "@jasper/shared";

type CreateProductSummarySideBarProps = {
  product: ProductFromGetOrderByUniqueAttributeQuery;
  patient: GetOrderByUniqueAttributeQuery["getOrderByUniqueAttribute"]["patient"];
  order?: GetOrderByUniqueAttributeQuery["getOrderByUniqueAttribute"];
  isBothJaws?: boolean;
};

export const CreateProductSummarySideBar: FC<
  CreateProductSummarySideBarProps
> = ({ product, patient, order, isBothJaws }) => {
  return (
    <Container
      style={theme => ({
        backgroundColor: theme.colors.primaryLight[9],
      })}
    >
      <Title
        order={3}
        // weight={500}
      >
        Résumé
      </Title>
      <Space h="sm" />
      <Container
        style={theme => ({
          borderRadius: "10px",
          backgroundColor: theme.colors.primaryLight[6],
          padding: theme.spacing.sm,
        })}
      >
        <Text>
          {patient
            ? `Patient ${patient.firstName} ${patient.lastName}`
            : "non attribué"}
        </Text>
      </Container>
      <DentalArchesSvg
        teeth={isBothJaws ? getAllTeeths() : getProductTeeth(product)}
        width={300}
        height={400}
      />
      <Box>
        {(order?.products ?? []).map(item => (
          <ItemsDescription
            key={item.id}
            product={item}
          />
        ))}
      </Box>
    </Container>
  );
};
