import React from "react";
import { Indicator, Text, MantineProvider } from "@mantine/core";
import { Calendar } from "@mantine/dates";
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import { isDayIncludeInBankHolidays } from "../../utils/order.utils";

interface CustomDateSelectionProps {
  selectDate: (date: Date) => void;
  optimalDeliveryDate: Date;
  minimalRushDeliveryDate: Date;
  selectedDate: Date | null;
  bankHolidays?: { beginDate: any; endDate: any }[];
}

const CustomDateSelection = ({ ...props }: CustomDateSelectionProps) => {
  const ensureDate = (possibleDate: any): Date => {
    if (possibleDate instanceof Date) {
      return new Date(possibleDate.getTime());
    }
    return new Date(possibleDate);
  };

  const optimalDeliveryDate = ensureDate(props.optimalDeliveryDate);
  const minimalRushDeliveryDate = ensureDate(props.minimalRushDeliveryDate);
  const selectedDate = props.selectedDate
    ? ensureDate(props.selectedDate)
    : null;

  optimalDeliveryDate.setHours(0, 0, 0, 0);

  return (
    <MantineProvider>
      <div style={{ width: "min-content" }}>
        <Calendar
          hideOutsideDates={true}
          minDate={minimalRushDeliveryDate}
          locale="fr"
          numberOfColumns={2}
          excludeDate={date => {
            const dateCopy = new Date(date.getTime());
            dateCopy.setHours(0, 0, 0, 0);

            return (
              dateCopy.getDay() === 6 ||
              dateCopy.getDay() === 0 || // disable saturday and sunday as there is no deliveries on these days. days before minimum are also disabled
              isDayIncludeInBankHolidays(dateCopy, props.bankHolidays)
            );
          }}
          defaultValue={optimalDeliveryDate.toString()}
          defaultChecked={true}
          renderDay={date => {
            const day = date.getDate();
            const dateCopy = new Date(date.getTime());
            dateCopy.setHours(0, 0, 0, 0);

            const isOptimalDate =
              dateCopy.getTime() === optimalDeliveryDate.getTime();
            const isSelectedDate =
              selectedDate && dateCopy.getTime() === selectedDate.getTime();

            return (
              <Indicator
                size={6}
                color="blue"
                offset={-2}
                disabled={!isOptimalDate}
              >
                <div
                  style={
                    isSelectedDate || (!selectedDate && isOptimalDate)
                      ? {
                          backgroundColor: "blue",
                          color: "white",
                          padding: "3px 5px",
                          borderRadius: "5px",
                        }
                      : {}
                  }
                >
                  {day}
                </div>
              </Indicator>
            );
          }}
          getDayProps={date => ({
            onClick: () => props.selectDate(date),
          })}
        />
      </div>
    </MantineProvider>
  );
};

export default CustomDateSelection;
