import React from "react";
import type { FC, JSX } from "react";
import { HashRouter, Navigate, Route, Routes } from "react-router-dom";
import { useAuthContext } from "../shared/contexts/AuthContext";
import { LoginPage } from "/@/pages/login/LoginPage";
import { Dashboard } from "/@/pages/dashboard/Dashboard";
import { CreateOrderFileInterceptors } from "/@/services/ipc/createOrderFile.interceptor";
import { useIpcContext } from "/@/shared/contexts/IpcContext";
import { LoggedInLayout } from "/@/components/layout/LoggedInLayout";
import { OrderPage } from "/@/pages/orders/OrderPage";
import { CreateOrderPage } from "/@/pages/orders/create/CreateOrderPage";
import { CreateOrderFormPage } from "/@/pages/orders/create/CreateOrderFormPage";
import { OrdersListPage } from "/@/pages/orders/OrdersListPage";
import { NotificationsListPage } from "/@/pages/notifications/NotificationsListPage";
import { SettingsPage } from "/@/pages/settings/SettingsPage";
import { UploadFilePage } from "/@/pages/orders/UploadFilePage";
import { UploadFileHelpPage } from "/@/pages/orders/UploadFileHelpPage";
import { io, Socket } from "socket.io-client";
import { useNavigate } from "react-router-dom";
import { getApiUrl } from "/@/services/api/api";

export const RoutesDispatcher: FC = () => {
  return (
    <HashRouter>
      <Routes>
        <Route
          path="/order-help-upload-file/:id/:name/:token"
          element={<UploadFileHelpPage />}
        />
        <Route
          path="/order-upload-file/:id/:name/:token/:filetype"
          element={<UploadFilePage />}
        />
        <Route
          path="/login"
          element={
            <CreateOrderRedirect>
              <LoginRedirect>
                <LoginPage />
              </LoginRedirect>
            </CreateOrderRedirect>
          }
        />
        <Route
          path="/create-order"
          element={
            <LoginRequired>
              <CreateOrderPage />
            </LoginRequired>
          }
        />
        <Route
          path="/create-order-form"
          element={
            <LoginRequired>
              <CreateOrderFormPage />
            </LoginRequired>
          }
        />
        <Route
          path="/orders"
          element={
            <LoginRequired>
              <CreateOrderRedirect>
                <OrdersListPage />
              </CreateOrderRedirect>
            </LoginRequired>
          }
        />
        <Route
          path="/notifications"
          element={
            <LoginRequired>
              <CreateOrderRedirect>
                <NotificationsListPage />
              </CreateOrderRedirect>
            </LoginRequired>
          }
        />
        <Route
          path="/order/:id"
          element={
            <LoginRequired>
              <CreateOrderRedirect>
                <OrderPage />
              </CreateOrderRedirect>
            </LoginRequired>
          }
        />
        <Route
          path="/settings"
          element={
            <LoginRequired>
              <CreateOrderRedirect>
                <SettingsPage />
              </CreateOrderRedirect>
            </LoginRequired>
          }
        />
        <Route
          path="/"
          element={
            <LoginRequired>
              <CreateOrderRedirect>
                <Dashboard />
              </CreateOrderRedirect>
            </LoginRequired>
          }
        />
      </Routes>
    </HashRouter>
  );
};

const LoginRequired = ({ children }: { children: JSX.Element }) => {
  const { isLoggedIn, user } = useAuthContext();
  const navigate = useNavigate();
  const refSocket = React.useRef<Socket | null>(null);

  const connectToWebsocket = () => {
    refSocket.current?.on("new_order", (args: { orderId?: string }) => {
      if (args && args?.orderId) {
        navigate("/create-order", {
          state: {
            id: args?.orderId,
          },
        });
      } else {
        console.error("Err argument websocket", args);
      }
    });

    refSocket.current?.on("connect_error", error => {
      console.log(
        `Connection failed RoutesDispatcher.tsx (webapp) #${error.message}`
      );
    });

    refSocket.current?.io.on("reconnect_attempt", attempt => {
      console.log(
        `Reconnection attempt in RoutesDispatcher.tsx (webapp) #${attempt}`
      );
    });

    refSocket.current?.io.on("reconnect", attempt => {
      console.log(
        `Successfully reconnected in RoutesDispatcher.tsx (webapp) after ${attempt} attempt(s)`
      );
    });

    refSocket.current?.on("disconnect", reason => {
      console.info(
        "disconnect in RoutesDispatcher.tsx (webapp)",
        reason,
        user.id
      );
      refSocket.current?.connect();
    });
  };

  React.useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        if (
          (user?.id &&
            user?.id !== undefined &&
            isLoggedIn &&
            refSocket.current === null) ||
          refSocket.current?.disconnected
        ) {
          if (refSocket.current === null) {
            refSocket.current = io(getApiUrl(), {
              query: {
                user_id: user?.id,
                type: "order",
              },
            });
          }
          connectToWebsocket();
        }
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  React.useEffect(() => {
    if (user?.id && isLoggedIn) {
      refSocket.current = io(getApiUrl(), {
        query: {
          user_id: user?.id,
          type: "order",
        },
      });

      if (refSocket.current) {
        connectToWebsocket();
      }
    }
  }, [isLoggedIn, user]);

  return isLoggedIn ? (
    // isCreatingOrder ? (
    //   <Navigate to="/test" />
    // ) : (
    // <CreateOrderFileInterceptors>{children}</CreateOrderFileInterceptors>
    <LoggedInLayout>{children}</LoggedInLayout>
  ) : (
    // )/>
    <Navigate to="/login" />
  );
};

const CreateOrderRedirect = ({ children }: { children: JSX.Element }) => {
  const { creatingOrderId } = useIpcContext();
  const { isLoggedIn } = useAuthContext();
  return isLoggedIn &&
    creatingOrderId !== null &&
    typeof creatingOrderId === "string" ? (
    <Navigate
      to="/create-order"
      state={{ id: creatingOrderId }}
    />
  ) : (
    <CreateOrderFileInterceptors>{children}</CreateOrderFileInterceptors>
  );
};

const LoginRedirect = ({ children }: { children: JSX.Element }) => {
  const { isLoggedIn } = useAuthContext();
  return isLoggedIn ? <Navigate to="/" /> : children;
};
