import React, { FC } from "react";
import { Box, Title, Select, Space, Text } from "@mantine/core";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  GetUserProductPreferencesQuery,
  ItemType,
  UpdateProductDocument,
} from "/@/generated/graphql";
import { StepsButtons } from "/@/components/StepsButtons";
import {
  AnteriorPosteriorPonticDesignLabels,
  InterproximalContactDesignLabels,
  NoOcclusalClearanceLabels,
  OcclusalContactTightnessLabels,
  OcclusalStainingLabels,
  ProximalContactTightnessLabels,
} from "../../../../shared/types/product-preferences/fixedProsthesisPreferences.types";
import { getFixedProsthesisPreferences } from "../../../../shared/utils/productPreferences/fixedProsthesisPreferences.utils";
import { useMutation } from "@apollo/client";
import { ProductFromGetOrderByUniqueAttributeQuery } from "/@/shared/types/products/products.types";
import { CreateImplantItemStep } from "/@/shared/types/orders/createOrder.types";
import { useCreateOrderStore } from "/@/shared/stores/orders/create-store.store";

import { getItemTypeLabel } from "@jasper/shared";

type OrderCreationFixedProsthesisPreferencesData = any;

type CreateOrderPatientInfoProps = {
  product: NonNullable<ProductFromGetOrderByUniqueAttributeQuery>;
  userProductPreferences: GetUserProductPreferencesQuery;
  setIsLoading: (isLoading: boolean) => void;
};

export const CreateOrderImplantItemPreferences: FC<
  CreateOrderPatientInfoProps
> = ({ product, userProductPreferences, setIsLoading }) => {
  const [
    createProductIndex,
    updateCreateProductIndex,
    updateCreateProductStep,
  ] = useCreateOrderStore(state => [
    state.createProductIndex,
    state.updateCreateProductIndex,
    state.updateCreateProductStep,
  ]);
  const [updateProduct] = useMutation(UpdateProductDocument);
  const productType = product.productType;

  const itemPreferences = getFixedProsthesisPreferences(
    product.productType,
    userProductPreferences
  );

  const { control, handleSubmit } =
    useForm<OrderCreationFixedProsthesisPreferencesData>({
      defaultValues: itemPreferences ?? {},
    });
  const onSubmit: SubmitHandler<
    OrderCreationFixedProsthesisPreferencesData
  > = async data => {
    setIsLoading(true);
    try {
      await updateProduct({
        variables: {
          where: {
            id: product.id,
          },
          data: {
            itemPreferences: {
              set: {
                ...itemPreferences,
                ...data,
              },
            },
          },
        },
      });
      setIsLoading(false);
      updateCreateProductIndex(createProductIndex + 1);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  return (
    <Box>
      <Title order={2}>
        {`Sélectionnez vos préférences de ${getItemTypeLabel(productType).toLowerCase()} pour votre commande`}
      </Title>
      <Space h="xl" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="occlusalContactTightness"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              data={OcclusalContactTightnessLabels}
              label="Intensité du contact occlusal"
            />
          )}
        />
        <Space h="sm" />
        <Controller
          name="proximalContactTightness"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              data={ProximalContactTightnessLabels}
              label="Intensité du contact proximal"
            />
          )}
        />
        <Text
          size="xs"
          style={{
            fontStyle: "italic",
          }}
        >
          {"Quelle intensité de contact proximal ?"}
        </Text>
        <Space h="sm" />
        <Controller
          name="occlusalStaining"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              data={OcclusalStainingLabels}
              label="Coloration occlusale"
            />
          )}
        />
        <Text
          size="xs"
          style={{
            fontStyle: "italic",
          }}
        >
          {"Quelles sont vos préférences de coloration occlusale ?"}
        </Text>
        <Space h="sm" />
        <Controller
          name="noOcclusalClearance"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              data={NoOcclusalClearanceLabels}
              label="Que doit faire Jasper en cas d'espace occlusal insuffisant ?"
            />
          )}
        />
        <Space h="sm" />
        <Controller
          name="interproximalContactDesign"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              data={InterproximalContactDesignLabels}
              label="Étendue du point de contact inter dentaire"
            />
          )}
        />
        {productType === ItemType.Bridge && (
          <>
            <Space h="sm" />
            <Controller
              name="anteriorPosteriorPonticDesign"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  data={AnteriorPosteriorPonticDesignLabels}
                  label="Design des pontiques antérieurs et postérieurs"
                />
              )}
            />
            <Text
              size="xs"
              style={{
                fontStyle: "italic",
              }}
            >
              {
                "Quel design souhaitez-vous pour les pontiques antérieurs et postérieurs?"
              }
            </Text>
            <Space h="sm" />
          </>
        )}
        <Space h="xl" />
        <StepsButtons
          handleSubmit={handleSubmit(onSubmit)}
          setPreviousAction={updateCreateProductStep}
          previousStep={CreateImplantItemStep.Item}
        />
      </form>
    </Box>
  );
};
