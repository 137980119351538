import React, { useRef, useState, useEffect } from "react";
import {
  Title,
  Grid,
  Container,
  Box,
  Space,
  Flex,
  Textarea,
  Text,
  Button,
  Modal,
  LoadingOverlay,
  Loader,
} from "@mantine/core";
import { Dropzone } from "@mantine/dropzone";
import { IconCloudUpload } from "@tabler/icons-react";
import { notifications } from "@mantine/notifications";
import * as QRCode from "qrcode";
import { io } from "socket.io-client";

import {
  STYLE,
  getPatientSexLabel,
  FileType,
  ItemsDescription,
  checkHasGenericImplant,
  getDeliveryDateAsString,
  CustomDateSelection,
  useFiles,
  getApiUrl,
  FileList,
  CommentType,
  File,
  Order,
} from "@jasper/shared";
import { Patient, User } from "types/interfaces";

interface CreateOrderRecapProps {
  order: Order & {
    address: {
      streetNumber: string;
      streetName: string;
      city: string;
      postcode: string;
    };
  } & User & { patient: Patient };
  refetchOrder: () => void;
  getDeliveryDate: (object: { variables: { orderId: string } }) => Promise<{
    data: {
      getOrderEstimatedDeliveryDate: {
        deliveryDate: Date;
        minimalRushDeliveryDate: Date;
        bankHolidays: {
          beginDate: Date;
          endDate: Date;
        }[];
      };
    };
  }>;
  user: User & { accessToken: string; customDeliveryDateEnabled: boolean };
  saveOrderComment: (comment: string) => void;
  updateDeliveryDate: (data: {
    variables: {
      orderId: string;
      newDeliveryDate: Date;
      shouldUpdateAbutmentType: false;
    };
  }) => Promise<{ data: { updateOrderDeliveryDate: boolean } }>;
  setIsShowAddProductModal: (value: boolean) => void;
}

const OrderRecap = ({
  order,
  refetchOrder,
  getDeliveryDate,
  user,
  saveOrderComment,
  updateDeliveryDate,
  setIsShowAddProductModal,
}: CreateOrderRecapProps) => {
  const [estimatedDeliveryDateString, setEstimatedDeliveryDateString] =
    useState<string>(null);
  const [deliveryDateObject, setDeliveryDateObject] = useState<
    | {
        __typename?: "OrderEstimatedDeliveryDate";
        deliveryDate: any;
        minimalRushDeliveryDate: any;
        bankHolidays: Array<{
          __typename?: "BankHoliday";
          beginDate: any;
          endDate: any;
        }>;
      }
    | undefined
  >();
  const [isModalCustomDeliveryDateOpened, setIsModalCustomDeliveryDateOpened] =
    useState(false);
  const [newDeliveryDateSelected, setNewDeliveryDateSelected] =
    useState<Date | null>(null);
  const [imageList, setImageList] = useState<File[]>([]);
  const [orderComment, setOrderComment] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const {
    getPresignedDownloadUrl,
    deleteOneFile,
    uploadFileToS3,
    filesOfOrder,
    refetchFilesOfOrder,
    getFileUrl,
  } = useFiles(order.id);

  const getOrderComment = () => {
    const existingOrderComment = (order?.orderComment ?? []).find(
      comment => (comment.type as unknown as CommentType) === CommentType.ORDER
    );
    return existingOrderComment?.comment ?? "";
  };

  const patientBirthday = order.patient.birthdate
    ? new Date(order.patient.birthdate.toString()).toLocaleDateString("fr-FR")
    : "Date de naissance non renseignée";

  const canvas = useRef(null);

  if (canvas.current) {
    const qrLink = `${window.location.origin}/#/order-upload-file/${order.id}/${order.patient?.lastName}/${user?.accessToken}/${FileType.ORDER_ADDON_FILE}`;
    QRCode.toCanvas(canvas.current, qrLink.replace(" ", "%20"), error => {
      if (error) console.error(error);
    });
  }

  const refreshImageList = async (data: any) => {
    return await Promise.all(
      data.map(async (obj: any) => {
        const path = (await getFileUrl(obj.key)) ?? null;
        return {
          ...obj,
          path: path,
        };
      })
    );
  };

  useEffect(() => {
    const computeDeliveryDate = async () => {
      const req = await getDeliveryDate({
        variables: {
          orderId: order?.id,
        },
      });
      setDeliveryDateObject(req.data?.getOrderEstimatedDeliveryDate);
      setEstimatedDeliveryDateString(
        getDeliveryDateAsString(
          req.data?.getOrderEstimatedDeliveryDate?.deliveryDate
        )
      );
    };
    computeDeliveryDate();
  }, [false]);

  useEffect(() => {
    const socket = io(getApiUrl(), {
      query: {
        type: "orderFile",
        order_id: order.id,
      },
    });
    socket.connect();
    socket.on("clientOrderFile", () => {
      refetchFilesOfOrder();
    });
    socket.on("disconnect", reason => {
      console.info("disconnect", reason, user?.id);
      socket.connect();
    });

    const handleVisibilityChange = () => {
      if (!document.hidden) {
        refetchOrder();
      }
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      socket.disconnect();
      socket.off("connect");
      socket.off("disconnect");
      socket.off("clientOrderFile");
    };
  }, []);

  useEffect(() => {
    setOrderComment(getOrderComment());
  }, [order]);

  useEffect(() => {
    if (filesOfOrder?.getFilesOfOrder) {
      refreshImageList(filesOfOrder?.getFilesOfOrder).then(a =>
        setImageList(a)
      );
    }
  }, [filesOfOrder?.getFilesOfOrder]);

  const onSubmitUpdateDeliveryDate = async (newDeliveryDate: Date) => {
    setIsLoading(true);
    const toSaveDeliveryDate = new Date(newDeliveryDate);
    const toSaveDeliveryDateUTC = new Date(newDeliveryDate);
    if (toSaveDeliveryDate) {
      toSaveDeliveryDate.setHours(12);
      toSaveDeliveryDateUTC.setDate(toSaveDeliveryDateUTC.getDate() - 1); // Webapp and Shared are in differents timezone
    }
    const req = await updateDeliveryDate({
      variables: {
        orderId: order.id,
        newDeliveryDate: toSaveDeliveryDateUTC,
        shouldUpdateAbutmentType: false,
      },
    });
    setIsModalCustomDeliveryDateOpened(false);
    if (req?.data?.updateOrderDeliveryDate) {
      await refetchOrder();
      setEstimatedDeliveryDateString(
        getDeliveryDateAsString(new Date(toSaveDeliveryDate))
      );
      notifications.show({
        color: "green",
        title: "Date de livraison modifiée",
        message: "",
      });
      setNewDeliveryDateSelected(newDeliveryDate);
      setIsLoading(false);
    } else {
      notifications.show({
        color: "red",
        title: "Erreur lors de la demande de modification",
        message: "",
      });
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <LoadingOverlay
        visible={true}
        zIndex={1000}
        overlayProps={{ radius: "sm", blur: 2 }}
      >
        <Loader />
      </LoadingOverlay>
    );
  }

  return (
    <>
      <Modal
        opened={isModalCustomDeliveryDateOpened}
        size="auto"
        onClose={() => setIsModalCustomDeliveryDateOpened(false)}
      >
        <CustomDateSelection
          selectDate={newDeliveryDate =>
            onSubmitUpdateDeliveryDate(newDeliveryDate)
          }
          optimalDeliveryDate={new Date(deliveryDateObject?.deliveryDate)}
          bankHolidays={deliveryDateObject?.bankHolidays}
          minimalRushDeliveryDate={deliveryDateObject?.minimalRushDeliveryDate}
          selectedDate={newDeliveryDateSelected}
        />
      </Modal>
      <Title style={{ paddingBottom: "0.5rem" }}>
        Complétez et validez votre commande
      </Title>
      <Title
        order={3}
        style={{ color: STYLE.primary, paddingBottom: "1rem" }}
      >
        Date de livraison estimée :{" "}
        {(estimatedDeliveryDateString as unknown as Date)?.toLocaleString(
          "fr-FR",
          {
            year: "numeric",
            month: "long",
            day: "numeric",
            weekday: "long",
          }
        ) ?? "En cours de calcul. Elle vous sera communiquée prochainement"}
        {user?.customDeliveryDateEnabled && (
          <Button
            style={{ marginLeft: "2rem" }}
            onClick={async () => {
              await saveOrderComment(orderComment);
              setIsModalCustomDeliveryDateOpened(true);
            }}
          >
            Changer la date de livraison
          </Button>
        )}
      </Title>
      <Grid gutter="md">
        <Grid.Col span={6}>
          <Container
            p="xl"
            style={theme => ({
              borderRadius: "10px",
              backgroundColor: "#f3f6fe",
              height: "100%",
            })}
          >
            <Grid gutter="md">
              <Grid.Col span={6}>
                <Box>
                  <Title order={4}>Praticien</Title>
                  <Text>
                    {order.user.firstName} {order.user.lastName}
                  </Text>
                  <Text>
                    {order.address.streetNumber} {order.address.streetName}{" "}
                  </Text>
                  <Text>
                    {order.address.city} {order.address.postcode}
                  </Text>
                </Box>
                <Space h="xl" />
                <Box>
                  <Title order={4}>Patient</Title>
                  <Text>
                    {order.patient.firstName} {order.patient.lastName}
                  </Text>
                  <Text>{patientBirthday}</Text>
                  <Text>{getPatientSexLabel(order.patient.sex)}</Text>
                </Box>
              </Grid.Col>
              <Grid.Col
                span={6}
                style={{ margin: "auto 0", textAlign: "center", width: "100%" }}
              >
                <div
                  style={{
                    width: "fit-content",
                    float: "right",
                    marginBottom: "10px",
                  }}
                >
                  <p style={{ textAlign: "center", margin: "0" }}>
                    Ajoutez des photos
                    <br />
                    via ce QR Code
                  </p>
                  <canvas
                    style={{
                      maxWidth: "170px",
                      maxHeight: "170px",
                      margin: "auto",
                    }}
                    ref={canvas}
                  />
                  <p style={{ textAlign: "center", margin: "5px" }}>OU</p>
                  <Dropzone
                    accept={{
                      "image/*": [],
                      "application/dicom": [".DCM", ".dcm"],
                      "model/stl": [".stl", ".STL"],
                    }}
                    onDrop={async files => {
                      await uploadFileToS3([...files], FileType.ORDER_FILE);
                      refetchOrder();
                    }}
                    style={{
                      border: "2px dashed gray",
                      borderRadius: "5px",
                      padding: "5px",
                      cursor: "pointer",
                    }}
                  >
                    <Flex
                      justify="center"
                      align="center"
                    >
                      <IconCloudUpload />
                      <Box ml="sm">
                        <span
                          style={{
                            fontWeight: "bold",
                            textWrap: "wrap",
                            fontSize: "14px",
                          }}
                        >
                          {" "}
                          {"Ajouter un fichier à votre commande"}
                        </span>
                      </Box>
                    </Flex>
                  </Dropzone>
                </div>
              </Grid.Col>
            </Grid>
            <Box>
              {imageList && imageList.length > 0 && (
                <Box
                  mt={10}
                  mb={10}
                >
                  <FileList
                    files={[
                      ...imageList.filter(
                        file =>
                          !file.key.includes(".3OXZ") &&
                          !file.key.includes("-traçabilité-praticien.pdf") &&
                          !file.key.includes("-traçabilité-patient.pdf")
                      ),
                    ]}
                    deleteOneFile={deleteOneFile}
                    downloadOneFile={getPresignedDownloadUrl}
                  />
                </Box>
              )}
              <Textarea
                value={orderComment}
                color="primary"
                placeholder="Un commentaire sur la commande ? (livraison, délais, etc.)"
                onChange={event => setOrderComment(event.currentTarget.value)}
              />
            </Box>
            <Box></Box>
          </Container>
        </Grid.Col>
        <Grid.Col span={6}>
          <Container
            p="xl"
            style={theme => ({
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              borderRadius: "10px",
              backgroundColor: "#f3f6fe",
              height: "100%",
            })}
          >
            <Box
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div>
                {(order?.products ?? []).map(product => (
                  <ItemsDescription
                    key={product.id}
                    product={product}
                    shouldTranslate={false}
                  />
                ))}
              </div>
              {checkHasGenericImplant(order) && (
                <div>
                  <Text style={{ color: STYLE.primary, fontWeight: "bold" }}>
                    En choisissant des délais de fabrication raccourcis,
                  </Text>
                  <Text
                    style={{
                      marginBottom: "1rem",
                      color: STYLE.primary,
                      fontWeight: "bold",
                    }}
                  >
                    vous acceptez de remplacer le pilier original fabriquant par
                    un pilier personnalisé.
                  </Text>
                </div>
              )}
            </Box>
            <Button
              h={60}
              variant="white"
              color={STYLE.primary}
              onClick={async () => {
                await saveOrderComment(orderComment);
                setIsShowAddProductModal(true);
              }}
            >
              {"Ajouter un produit (ex: gouttière, ...)"}
            </Button>
          </Container>
        </Grid.Col>
      </Grid>
    </>
  );
};

export default OrderRecap;
